import React, { FunctionComponent, useState } from "react"
import { Query } from "react-apollo"
import {
  IBurnedStoryPointsResult,
  BURNED_STORY_POINTS_QUERY,
} from "../../../../api/graphql/query/burned-story-points"
import Spinner from "@atlaskit/spinner"
import { useTranslation } from "react-i18next"
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core"
import { BurnedStoryPointsTable } from "./components/BurnedStoryPoints/BurnedStoryPointsTable"

const FILTER_HEIGHT = "50px"
const DEFAULT_VALUE = 3

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
  },
  filter: {
    height: FILTER_HEIGHT,
  },
  amountOfSprints: {
    minWidth: "200px",
  },
  table: {
    height: `calc(100% - ${FILTER_HEIGHT})`,
    minHeight: `calc(100% - ${FILTER_HEIGHT})`,
    paddingTop: theme.spacing(2),
  },
}))

interface IBurnedStoryPointsProps {}

export const BurnedStoryPoints: FunctionComponent<IBurnedStoryPointsProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [amountOfSprints, setAmountOfSprints] = useState(DEFAULT_VALUE)
  const handleAmountOfSprintsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAmountOfSprints(event.target.value as number)
  }
  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} className={classes.filter}>
        <FormControl>
          <InputLabel id="amount-of-sprints-label">
            {t("page.dashboard.burned_story_points.filter.amount_of_sprints")}
          </InputLabel>
          <Select
            labelId="amount-of-sprints-label"
            value={amountOfSprints}
            onChange={handleAmountOfSprintsChange}
            className={classes.amountOfSprints}
          >
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={12}>12</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} className={classes.table}>
        <Query query={BURNED_STORY_POINTS_QUERY} variables={{ amountOfSprints: amountOfSprints }}>
          {({ data, loading, error }: IBurnedStoryPointsResult) => {
            return (
              <>
                {loading && (
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    style={{ height: "100%" }}
                  >
                    <Spinner />
                    <span style={{ marginLeft: "1em" }}>{t("page.dashboard.burned_story_points.loading.message")}</span>
                  </Grid>
                )}
                {error && (
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    style={{ height: "100%" }}
                  >
                    <span>{t("page.dashboard.burned_story_points.error.message")}</span>
                  </Grid>
                )}
                {!loading && !error && (
                  <>
                    {data?.burnedStoryPoints && (
                      <BurnedStoryPointsTable data={data.burnedStoryPoints} />
                    )}
                  </>
                )}
              </>
            )
          }}
        </Query>
      </Grid>
    </Grid>
  )
}
