import i18nImpl from "i18next";
import { initReactI18next } from "react-i18next"
import LngDetector from "i18next-browser-languagedetector";
import { de } from "../translations/de"
import { en } from "../translations/en";

export const I18N_LOCAL_STORAGE = 'DCARE_I18N_LNG'

i18nImpl
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .init({
    resources: {
      de: {
        translation: de
      },
      en: {
        translation: en
      }
    },
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],
      lookupLocalStorage: I18N_LOCAL_STORAGE
    }
  });


export default i18nImpl;
export const i18n = i18nImpl;
