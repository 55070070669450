import React, { FunctionComponent } from "react"
import { EditCustomerMetadataFormBody } from "./components/EditCustomerMetadataFormBody"
import { EditCustomerMetadataFormFooter } from "./components/EditCustomerMetadataFormFooter"
import { EditCustomerMetadataFormHeader } from "./components/EditCustomerMetadataFormHeader"

export const EditCustomerMetadataForm: FunctionComponent = () => {
  return (
    <>
      <EditCustomerMetadataFormHeader />
      <EditCustomerMetadataFormBody />
      <EditCustomerMetadataFormFooter />
    </>
  )
}
