import moment from "moment";
import _ from "lodash";

export const getLastSprintNames = (count: number): string[] => {
  const now = moment();

  // wednesday
  const isoDayOfRetro = 3;
  // length of a sprint in weeks
  const sprintLength = 2;
  // does a new sprint begin this week?
  // sprint begin    -> odd calendar week
  // no sprint begin -> even calendar week
  const weekIncludesSprintBegin = now.week() % 2 !== 0;
  const dayIsBeforeRetro = now.isoWeekday() < isoDayOfRetro;

  // start of the current sprint
  const sprintStart = now
    .clone()
    .day(isoDayOfRetro)
    .subtract(weekIncludesSprintBegin ? 0 : 1, "week");

  const sub =
    weekIncludesSprintBegin && dayIsBeforeRetro
      ? 2 * sprintLength
      : sprintLength;

  const startDate = sprintStart.clone().subtract(sub, "week");

  const sprintNames: string[] = []
  for (let i = 0; i < count; i++) {
    const currentDate = startDate.clone().subtract(i * sprintLength, "week");

    const year = currentDate.year();
    const week = currentDate.week();
    const sprintNr = Math.ceil(week / 2);

    const paddedSprintNr = _.padStart(`${sprintNr}`, 2, "0");
    sprintNames.push(`${year}/${paddedSprintNr}`);
  }

  return sprintNames;
}