import { gql } from "apollo-boost";
import { Query, QueryResult } from "react-apollo";
import { GetJiraProjectsWithMetadata } from "./types/GetJiraProjectsWithMetadata";

export default gql`
    query GetJiraProjectsWithMetadata {
        getJiraProjectsWithMetadata {
            key
            name
            customerMetadata {
                jiraProjectKey
                projectName
                companyName
                companyType
                salutation
                lastname
                firstname
                streetName
                streetNumber
                postalCode
                city
                country
                dashboardName
                vatId
                dashboardColor
                hasSupportContract
            }
        }
    }
`

export interface IJiraProjectsWithMetadataResult extends QueryResult<GetJiraProjectsWithMetadata>{}
export class GetJiraProjectsWithMetadataQuery extends Query {}