import * as React from "react"
import { Redirect } from "react-router"
import { IBaseProps } from "../../../utils/BaseProps"
import { withTranslation } from "react-i18next"

interface IWelcomeProps extends IBaseProps {

}

interface IWelcomeState {

}

class Welcome extends React.Component<IWelcomeProps, IWelcomeState> {

  constructor(props: IWelcomeProps) {
    super(props);

    this.state = {}
  }

  public render() {
    return (
      <div><Redirect to="/projects"/></div>
    )
  }
}

export default withTranslation()(Welcome)
