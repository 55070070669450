import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { CustomerMetadataDashboard } from "../../../../context/CustomersContext";

const useStyles = makeStyles((theme: Theme) => ({
  squareBox: {
    float: "left",
    height: "20px",
    width: "20px",
    clear: "both",
    marginRight: 4
  }
}));

interface ICustomerDashboardCellProps {
  dashboardData: CustomerMetadataDashboard;
}

export const CustomerDashboardCell: FunctionComponent<ICustomerDashboardCellProps> = (props) => {
  const classes = useStyles();
  const {dashboardData} = props;

  return (
    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
      <Grid item xs>
        <Typography variant="body2">{dashboardData.name}</Typography>
      </Grid>

      <Grid item xs>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center">
          <span className={classes.squareBox} style={{backgroundColor: dashboardData.color}}/>
          <Typography variant="body2">{dashboardData.color}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}