import { Menu, TextField, TextFieldProps, useTheme } from "@material-ui/core"
import { useFormikContext } from "formik"
import { isString } from "lodash"
import React, { ChangeEvent, FC, useCallback, useState } from "react"
import { ChromePicker, ColorResult } from "react-color"

type IFormikTextFieldProps = TextFieldProps & {
  valueName: string
  clearable?: boolean
  onChange?: (e: ChangeEvent<any>) => void
  hide?: boolean
}

export const FormikColorPicker: FC<IFormikTextFieldProps> = ({
  valueName,
  clearable,
  onChange,
  hide,
  ...rest
}) => {
  const { getFieldProps, getFieldMeta, handleChange, handleBlur, getFieldHelpers } =
    useFormikContext()
  const field = getFieldProps(valueName)
  const fieldMeta = getFieldMeta(valueName)
  const fieldHelpers = getFieldHelpers(valueName)

  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleChangeProxy = (e: ChangeEvent<any>) => {
    handleChange(e)
    onChange && onChange(e)
  }

  const handleClosePicker = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  return (
    <>
      <TextField
        {...rest}
        fullWidth
        name={valueName}
        value={field.value ?? ""}
        error={fieldMeta.touched && isString(fieldMeta.error)}
        helperText={
          fieldMeta.touched && isString(fieldMeta.error) && fieldMeta.error
            ? fieldMeta.touched && isString(fieldMeta.error) && fieldMeta.error
            : rest.helperText
        }
        onClick={e => {
          setAnchorEl(e.currentTarget)
        }}
        onChange={handleChangeProxy}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: (
            <div
              style={{
                backgroundColor: field.value,
                height: "20px",
                width: "20px",
                borderRadius: "2px",
                marginRight: theme.spacing(1),
                boxShadow: theme.shadows[2],
              }}
            />
          ),
        }}
      />
      <Menu
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClosePicker}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        MenuListProps={{
          style: {
            paddingTop: "0",
            paddingBottom: "0",
          },
        }}
      >
        <ChromePicker
          color={field.value}
          disableAlpha
          onChange={(color: ColorResult) => {
            fieldHelpers.setValue(color.hex)
          }}
        />
      </Menu>
    </>
  )
}
