import React, { FunctionComponent, useState, useCallback } from 'react';
import store from "store"
import i18n from "i18next";

import Tooltip from "@atlaskit/tooltip"
import IssuesIcon from "@atlaskit/icon/glyph/issues"
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left"
import SwitcherIcon from "@atlaskit/icon/glyph/switcher"
import StatusIcon from "@atlaskit/icon/glyph/status"
import SignOutIcon from "@atlaskit/icon/glyph/sign-out"
import GraphBarIcon from "@atlaskit/icon/glyph/graph-bar"
import DashboardIcon from "@atlaskit/icon/glyph/dashboard"
import ContactMailIcon from '@material-ui/icons/ContactMail';

import { Link } from "react-router-dom"
import { AuthConsumer } from "../../context/AuthContext"
import { ROUTE_DICTIONARY } from "../../routes"
import { useTranslation } from "react-i18next";

// import SettingsIcon from "@atlaskit/icon/glyph/settings"
import Navigation, {
  AkContainerNavigationNested,
  AkCreateDrawer,
  AkNavigationItem,
  presetThemes,
} from "@atlaskit/navigation"

const BackIcon = (
  <Tooltip position="right" content="Schließen">
    <ArrowLeftIcon label="Close" size="medium" />
  </Tooltip>
)
const wrapInTooltip = (isCollaped: boolean, icon: React.ReactNode, title: string) => {
  return isCollaped ? (
    <Tooltip position="right" content={title}>
      {icon}
    </Tooltip>
  ) : (
    icon
  )
}
interface ICustomLinkProps {
  className: string | undefined
  href: string
}
const CustomLink: FunctionComponent<ICustomLinkProps> = ({className, href, children}) => (
    <Link className={className} to={href}>
      {children}
    </Link>
  );

interface ICreateDrawerProps {
  isOpen?: boolean;
  close?: () => void
  hasAbility?: (ability: string) => boolean
  isJiraUser: () => boolean
  t: i18n.TFunction;
}

const CreateDrawer :FunctionComponent<ICreateDrawerProps> = (props) => (
  <AkCreateDrawer
    backIcon={BackIcon}
    isOpen={props.isOpen}
    key="settings"
    onBackButton={props.close}
    primaryIcon={<SwitcherIcon label="Denovo Care" size="large" />}
  >
    <AkNavigationItem
      key={ROUTE_DICTIONARY.ERP.handle}
      text={props.t("drawer.erp")}
      linkComponent={CustomLink}
      href={ROUTE_DICTIONARY.ERP.path}
    />
    <AkNavigationItem
      key={ROUTE_DICTIONARY.STATUS.handle}
      text={props.t("drawer.version")}
      linkComponent={CustomLink}
      href={ROUTE_DICTIONARY.STATUS.path}
    />
    <AkNavigationItem
      key={ROUTE_DICTIONARY.LOGOUT.handle}
      text={props.t("drawer.logout")}
      linkComponent={CustomLink}
      href={ROUTE_DICTIONARY.LOGOUT.path}
    />
  </AkCreateDrawer>
)


interface IPageNavigationProps {}

export const PageNavigation: FunctionComponent<IPageNavigationProps> = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const {t} = useTranslation();

  const resize = useCallback((resizeState: any) => {
    const isCollapsedNavigation = !resizeState.isOpen
    store.set("is_collapsed_navigation", isCollapsedNavigation)
    setCollapsed(isCollapsedNavigation)
  }, [setCollapsed])


  const colors = presetThemes.settings

  return (
    <AuthConsumer>
      {({ hasAbility, isCustomer, isJiraUser }) =>
        hasAbility && (
          <Navigation
            key="navigation"
            drawers={[<CreateDrawer
              key="drawer"
              isOpen={openDrawer}
              close={() => setOpenDrawer(false)}
              hasAbility={hasAbility}
              isJiraUser={isJiraUser}
              t={t}/>
            ]}
            globalTheme={colors}
            containerTheme={colors}
            isOpen={!collapsed}
            onResize={resize}
            globalPrimaryIcon={<SwitcherIcon label="Denovo Care" size="large" />}
            globalPrimaryItemHref={ROUTE_DICTIONARY.HOME.path}
            // globalCreateIcon={<GlobalSetting openDrawer={this.openDrawer} />}
          >
            <AkContainerNavigationNested
              stack={[
                [
                  !isCustomer() && (
                    <AkNavigationItem
                      text={t("navigation.dashboard")}
                      key={"dashboard"}
                      icon={wrapInTooltip(
                        collapsed,
                        <DashboardIcon label={"Dashboard"} size="medium" />,
                        "Dashboard"
                      )}
                      linkComponent={CustomLink}
                      href={ROUTE_DICTIONARY.DASHBOARD.path}
                    />
                  ),
                  hasAbility("erp") ? (
                    <AkNavigationItem
                      text={isCustomer() ? t("navigation.res") : t("navigation.erp")}
                      key={isCustomer() ? "RES" : "ERP"}
                      icon={wrapInTooltip(
                        collapsed,
                        <GraphBarIcon
                          label={isCustomer() ? "Ressourcenplanung" : "ERP"}
                          size="medium"
                        />,
                        isCustomer() ? "Ressourcenplanung anzeigen" : "ERP anzeigen"
                      )}
                      linkComponent={CustomLink}
                      href={(isCustomer() ? ROUTE_DICTIONARY.RESOURCES : ROUTE_DICTIONARY.ERP).path}
                    />
                  ) : null,
                  <AkNavigationItem
                    text={t("navigation.projects")}
                    key="projects"
                    icon={wrapInTooltip(
                      collapsed,
                      <IssuesIcon label="Projekte" size="medium" />,
                      "Projektübersicht anzeigen"
                    )}
                    linkComponent={CustomLink}
                    href={ROUTE_DICTIONARY.PROJECTS.path}
                  />,
                  isJiraUser() ? (
                  <AkNavigationItem
                    text={t("navigation.customers")}
                    key="customers"
                    icon={wrapInTooltip(
                      collapsed,
                      <ContactMailIcon aria-label="Customers" fontSize="medium" />,
                      "Customers"
                    )}
                    linkComponent={CustomLink}
                    href={ROUTE_DICTIONARY.CUSTOMERS.path}
                  />) : null,
                  <AkNavigationItem
                    text={t("navigation.version")}
                    key="version"
                    icon={wrapInTooltip(
                      collapsed,
                      <StatusIcon label="Version" size="medium" />,
                      "Version & Systemstatus anzeigen"
                    )}
                    linkComponent={CustomLink}
                    href={ROUTE_DICTIONARY.STATUS.path}
                  />,
                  <AkNavigationItem
                    text={t("navigation.logout")}
                    key="logout"
                    icon={wrapInTooltip(
                      collapsed,
                      <SignOutIcon label="Abmelden" size="medium" />,
                      "Aus denovo care abmelden"
                    )}
                    linkComponent={CustomLink}
                    href={ROUTE_DICTIONARY.LOGOUT.path}
                  />,
                ]
              ]}
            />
          </Navigation>
        )
      }
    </AuthConsumer>
  )
}
