import React from 'react';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/de';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { PageLayout } from './components/layout/PageLayout';
import { AuthProvider } from './context/AuthContext';
import { FilterProvider } from './context/FilterContext';
import 'tachyons';
import './index.css';

import i18n from './utils/i18n';
import store from 'store';
import { authLink, errorLink, queryOrMutationLink } from './utils/links';
import ApolloClient, { DefaultOptions } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import runtimeEnv from "@mars/heroku-js-runtime-env"
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks';
import 'react-toastify/dist/ReactToastify.css';
import './utils/string.extensions';
import { getParameterByName } from './utils/url';
import routes from './routes';
import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './theme/theme';
import { GraphQLProvider } from './api/backend/context/graphql-context';
import { SnackbarProvider } from './context/SnackbarContext';
import { config } from './utils/config';
import { forceHttps } from "@protomodule/instruments"


moment.locale('de');
(window.location.hostname !== 'localhost') && forceHttps();

const endpointUrl = config.REACT_APP_GRAPHQL_URL;
const links = [
  authLink,
  errorLink,
  queryOrMutationLink(endpointUrl)
];

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  //@ts-ignore
  link: ApolloLink.from(links),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});

const token = getParameterByName('token');
if (token) {
  store.set('access_token', token);
  window.location.href = window.location.pathname;
}

ReactDOM.render(
  <AuthProvider token={store.get('access_token')} endpoint={endpointUrl}>
    <FilterProvider>
      <ApolloProvider client={client}>
        <ApolloHookProvider client={client}>
          <MuiThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <SnackbarProvider>
                <GraphQLProvider>
                  <Router>
                    <PageLayout>
                      <Switch>
                        {routes.map((route) => <Route key={route.handle} {...route}/>)}
                      </Switch>
                    </PageLayout>
                  </Router>
                </GraphQLProvider>
              </SnackbarProvider>
            </I18nextProvider>
          </MuiThemeProvider>
        </ApolloHookProvider>
      </ApolloProvider>
    </FilterProvider>
  </AuthProvider>,
  document.getElementById('root'),
);
