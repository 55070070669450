import React, { FC } from "react"
import Button from "@atlaskit/button"
import { ModalFooter } from "@atlaskit/modal-dialog"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import { useJiraSupport } from "./context/JiraSupportContext"

export const JiraSupportFormFooter: FC = () => {
  const { t } = useTranslation()
  const { loading, closeModal } = useJiraSupport()
  const formik = useFormikContext()

  return (
    <ModalFooter>
      <Button appearance="danger" onClick={closeModal}>
        {t("page.customer.modal.close")}
      </Button>
      <Button isLoading={loading} appearance="primary" onClick={() => formik.submitForm()}>
        {t("page.sprints.support.modal.submit_btn")}
      </Button>
    </ModalFooter>
  )
}
