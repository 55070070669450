import React, { FC } from "react";
import { StatusContextProvider } from "./context/StatusContext";
import { StatusContent } from "./StatusContent";

const StatusPage: FC = () => (
  <StatusContextProvider>
    <StatusContent/>
  </StatusContextProvider>
)

export default StatusPage;
