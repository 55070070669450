import React, { useCallback } from "react"
import { SprintItem } from "./SprintItem"
import { useTranslation } from "react-i18next"
import { Dictionary } from "lodash"
import { Sprint } from "../../api/backend/graphql/generated"

export interface SprintListItem {
  projectKey: string
  sprints: Sprint[]
}

interface SprintListProps {
  history: any
  items: SprintListItem[]
  refs: Dictionary<React.RefObject<HTMLDivElement>>
  linkToProject?: boolean
  enableQuickActions?: boolean
  highlightCurrentSprint?: boolean
  openedSprints?: string[]
  onSprintIsOpenChange?: (sprintName: string, isOpen: boolean) => void
}

export const SprintList = (props: SprintListProps) => {
  const { t } = useTranslation()
  const {
    history,
    linkToProject,
    enableQuickActions,
    items,
    refs,
    highlightCurrentSprint = false,
    openedSprints = [],
    onSprintIsOpenChange = () => null,
  } = props

  const handleSprintToggle = useCallback(
    (sprintName: string) => (isOpen: boolean) => {
      onSprintIsOpenChange(sprintName, isOpen)
    },
    [onSprintIsOpenChange]
  )

  return (
    <>
      {items.map(item => {
        return item.sprints.map((sprint: Sprint) => {
          const key = item.projectKey.concat(sprint.name)
          return (
            <SprintItem
              key={key}
              innerRef={refs && refs[`${sprint.name}${item.projectKey}`]}
              sprint={sprint}
              projectKey={item.projectKey}
              projectName={item.projectKey}
              enableQuickActions={enableQuickActions}
              linkToProject={linkToProject}
              history={history}
              highlightCurrentSprint={highlightCurrentSprint}
              initialIsOpen={openedSprints.includes(key)}
              onIsOpenChange={handleSprintToggle(key)}
            />
          )
        })
      })}
      {items.length <= 0 && <div>{t("page.sprints.list.no_sprints")}</div>}
    </>
  )
}
