import { gql } from "apollo-boost"

export const issuesForSprint = gql`
  query Issues($projectKey: String!, $sprintName: String, $isBacklog: Boolean) {
    issues(projectKey: $projectKey, sprintName: $sprintName, isBacklog: $isBacklog) {
      id
      key
      type {
        name
      }
      summary
      SP
      SPEstimate
      status {
        name
      }
      priority {
        name
      }
      estimation
      denovo_care_visibility
      description
      labels
      assignee {
        name
      }
      sprint {
        name
      }
    }
  }
`
