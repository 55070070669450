import { useLazyQuery, useMutation } from "@apollo/react-hooks"
import { GridRowsProp } from "@mui/x-data-grid"
import { ApolloError } from "apollo-boost"
import _ from "lodash"
import React, { createContext, FunctionComponent, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CreateCustomerMetadata } from "../api/graphql/graphql-global-types"
import CREATE_OR_EDIT_CUSTOMER_METADATA from "../api/graphql/mutation/create-or-edit-customer-metadata"
import GET_JIRA_PROJECTS_WITH_METADATA from "../api/graphql/query/get-jira-projects-with-metadata"
import {
  GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata,
  GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata_customerMetadata,
} from "../api/graphql/query/types/GetJiraProjectsWithMetadata"

interface ICustomersContextProviderProps {}

export interface ICustomersContext {
  showModal: boolean
  isEdit: boolean
  setIsEdit: (value: boolean) => void
  isLoading: boolean
  setIsLoading: (value: boolean) => void
  createOrEditLoading: boolean
  rows: GridRowsProp[]
  customerMetadata: CreateCustomerMetadata
  setCustomerMetadata: (value: CreateCustomerMetadata) => void
  resetFields: () => void
  getProjects: () => void
  jiraProjectsLoading: boolean
  jiraProjectsError: ApolloError | undefined
  createOrEditCustomerMetadata: (createCustomerMetadata: CreateCustomerMetadata) => void
  openModal: (
    jiraKey: string,
    projectName: string,
    updateCustomerData?: GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata_customerMetadata
  ) => void
  closeModal: () => void
}

export enum Salutation {
  MR = "mr",
  MRS = "mrs",
}

export interface CustomerMetadataDashboard {
  name: string
  color: string
}

const initialCustomerMetadata: CreateCustomerMetadata = {
  salutation: Salutation.MR,
  vatId: "",
  streetNumber: "",
  companyType: "",
  streetName: "",
  projectName: "",
  postalCode: "",
  firstname: "",
  lastname: "",
  companyName: "",
  dashboardColor: "#000",
  dashboardName: "",
  country: "",
  city: "",
  jiraProjectKey: "",
  hasSupportContract: false,
}
export enum SuccessMessage {
  EDITED = "edited",
  CREATED = "created",
}

export enum ErrorMessage {
  EDIT = "edit",
  CREATE = "create",
}

export const CustomersContext = createContext<ICustomersContext>({} as ICustomersContext)

export const CustomersContextProvider: FunctionComponent<
  ICustomersContextProviderProps
> = props => {
  const { t } = useTranslation()
  const { children } = props

  const [showModal, setShowModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<GridRowsProp[]>([])
  const [customerMetadata, setCustomerMetadata] =
    useState<CreateCustomerMetadata>(initialCustomerMetadata)

  const [getProjects, { loading: jiraProjectsLoading, error: jiraProjectsError }] = useLazyQuery(
    GET_JIRA_PROJECTS_WITH_METADATA,
    {
      fetchPolicy: "no-cache",
      onCompleted: data => {
        setRows(
          data.getJiraProjectsWithMetadata.map(
            (jiraProjectWithMetadata: GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata) => {
              return {
                id: jiraProjectWithMetadata.key,
                projectName:
                  jiraProjectWithMetadata.customerMetadata?.projectName ||
                  jiraProjectWithMetadata.name,
                customerData: jiraProjectWithMetadata.customerMetadata,
                dashboard: {
                  name: jiraProjectWithMetadata.customerMetadata?.dashboardName || "",
                  color: jiraProjectWithMetadata.customerMetadata?.dashboardColor || "",
                },
              }
            }
          )
        )
      },
    }
  )

  const updateFields = useCallback(
    (
      editCustomerData: GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata_customerMetadata
    ) => {
      const customerDataToEdit = _.omit(editCustomerData, ["__typename"])
      setIsEdit(true)
      setCustomerMetadata((customerData: CreateCustomerMetadata) => ({
        ...customerData,
        ...customerDataToEdit,
        hasSupportContract: _.isNull(customerDataToEdit.hasSupportContract)
          ? customerData.hasSupportContract
          : customerDataToEdit.hasSupportContract,
      }))
    },
    [setIsEdit, setCustomerMetadata]
  )

  const resetFields = useCallback(
    () => setCustomerMetadata(initialCustomerMetadata),
    [setCustomerMetadata]
  )

  const [createOrEdit, { loading: createOrEditLoading }] = useMutation(
    CREATE_OR_EDIT_CUSTOMER_METADATA,
    {
      onCompleted: () => {
        resetFields()
        toast.success(
          t("page.customer.modal.success_message", {
            key: isEdit ? SuccessMessage.EDITED : SuccessMessage.CREATED,
          })
        )
        setShowModal(false)
        getProjects()
      },
      onError: (error: ApolloError) => {
        toast.error(
          t("page.customer.modal.error_message", {
            key: isEdit ? ErrorMessage.EDIT : ErrorMessage.CREATE,
          })
        )
      },
    }
  )

  const createOrEditCustomerMetadata = useCallback(
    (createCustomerMetadata: CreateCustomerMetadata) => {
      createOrEdit({
        variables: {
          createCustomerMetadata,
        },
      })
    },
    [createOrEdit]
  )

  const openModal = useCallback(
    (
      jiraProjectKey: string,
      projectName: string,
      editCustomerData?: GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata_customerMetadata
    ) => {
      setCustomerMetadata((customerData: CreateCustomerMetadata) => ({
        ...customerData,
        jiraProjectKey: jiraProjectKey,
        projectName: projectName,
      }))

      if (editCustomerData) {
        updateFields(editCustomerData)
      }

      setShowModal(true)
    },
    [setCustomerMetadata, setShowModal, updateFields]
  )

  const closeModal = useCallback(() => {
    setShowModal(false)
    resetFields()
  }, [setShowModal, resetFields])

  return (
    <CustomersContext.Provider
      value={{
        showModal,
        openModal,
        closeModal,
        isEdit,
        setIsEdit,
        isLoading,
        setIsLoading,
        createOrEditLoading,
        rows,
        customerMetadata,
        setCustomerMetadata,
        getProjects,
        jiraProjectsLoading,
        jiraProjectsError,
        createOrEditCustomerMetadata,
        resetFields,
      }}
    >
      {children}
    </CustomersContext.Provider>
  )
}
