import React, { FC } from 'react';
import { SprintsContextProvider } from './context/SprintsContext';
import SprintsContent from './SprintsContent';
import { IBaseProps } from '../../../../utils/BaseProps';

interface ISprintsProps extends IBaseProps {
  history: any;
}

const Sprints: FC<ISprintsProps> = ({ history }) => (
  <SprintsContextProvider>
    <SprintsContent history={history}/>
  </SprintsContextProvider>
);

export default Sprints;
