import React, { FC } from "react"
import { Box, Grid, MenuItem, useTheme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { FormikTextField } from "../../../../../../formik/formik-text-field"
import { JiraPriorityType } from "../../../../../../../api/backend/graphql/generated"
import { properCase } from "../../../../../../../utils/properCase"
import { FormikFileSelect } from "../../../../../../formik/file-select/formik-file-select"
import { ModalBody } from "@atlaskit/modal-dialog"

export const JiraSupportFormBody: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <ModalBody>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        wrap="nowrap"
        style={{ height: "100%", paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
      >
        <Grid item container spacing={2}>
          <Grid item xs>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.sprints.support.modal.title")}
              valueName={"title"}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs>
            <FormikTextField
              fullWidth
              multiline
              maxRows={4}
              variant="outlined"
              label={t("page.sprints.support.modal.description")}
              valueName={"description"}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs>
            <FormikTextField
              fullWidth
              select
              variant="outlined"
              label={t("page.sprints.support.modal.priority")}
              valueName={"priority"}
            >
              <MenuItem value={JiraPriorityType.HIGH}>{properCase(JiraPriorityType.HIGH)}</MenuItem>
              <MenuItem value={JiraPriorityType.MEDIUM}>
                {properCase(JiraPriorityType.MEDIUM)}
              </MenuItem>
              <MenuItem value={JiraPriorityType.LOW}>{properCase(JiraPriorityType.LOW)}</MenuItem>
            </FormikTextField>
          </Grid>
        </Grid>
        <Grid item xs container spacing={2}>
          <Box width={"100%"} pt={2}>
            <FormikFileSelect
              label={t("page.sprints.support.modal.attachments")}
              valueName={"attachments"}
              maxFiles={10}
            ></FormikFileSelect>
          </Box>
        </Grid>
      </Grid>
    </ModalBody>
  )
}
