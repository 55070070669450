import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useLoginFormValidation = () => {
    const { t } = useTranslation();
    return yup.object({
        email: yup.string().email(t('login.form.errors.email')).required(t('login.form.errors.required')),
        password: yup.string().required(t('login.form.errors.required')),
    });
};
