import { ResponsiveBar } from "@nivo/bar"
import { line } from "d3-shape"
import React, { Fragment } from "react"
import "./chart.css"
import { Tooltip } from "react-svg-tooltip"

const lineColor = "rgba(41, 56, 86, 1)"
const Line = params => {
  const lineGenerator = line()
    .x(bar => bar.x)
    .y(bar => params.yScale(76))

  return (
    <Fragment>
      <path
        d={lineGenerator([{ x: 0 }, { x: params.width }])}
        fill="none"
        stroke={lineColor}
        strokeWidth={1}
        strokeDasharray="5px"
        style={{ pointerEvents: "none" }}
      />
    </Fragment>
  )
}

const line2Color = "rgba(255, 0, 0, 0.5)"
const Line2 = params => {
  const lineGenerator = line()
    .x(bar => bar.x)
    .y(bar => params.yScale(64))

  return (
    <Fragment>
      <path
        d={lineGenerator([{ x: 0 }, { x: params.width }])}
        fill="none"
        stroke={line2Color}
        strokeWidth={1}
        style={{ pointerEvents: "none" }}
      />
    </Fragment>
  )
}

const AbsentIndicator = ({ data, bars, xScale, yScale }) => {
  let width = 0
  if (bars.length > 0) {
    width = bars[0].width
  }

  const getColorForSum = sum => {
    if (sum <= 0) return "transparent"
    else if (sum < 5) return "lightgreen"
    else if (sum < 10) return "orange"
    else return "red"
  }

  const getColorForDays = days => {
    if (days <= 0) return "transparent"
    else if (days < 3) return "lightgreen"
    else if (days < 6) return "orange"
    else return "red"
  }

  const rectRef = React.createRef()
  const fontSize = 13
  const tooltipXOffset = 15
  const textPadding = 10
  const horizontalLinePadding = 10
  const lineHeight = 20

  return (
    <Fragment>
      {data.map(sprint => {
        // width of background-rectangle must be computed manually
        // width is determined by the longest string times the fontsize plus padding
        // this can also be used to position text on the background by offsetting accordingly
        const tooltipWidth =
          sprint.absent.reduce(
            (prev, curr) => Math.max(prev, curr.name.concat(curr.days).length),
            0
          ) *
            fontSize +
          textPadding
        const horizontalLineYPosition = (sprint.absent.length - 1) * lineHeight + 7
        return (
          <Fragment key={sprint.sprint}>
            <rect
              ref={rectRef}
              x={xScale(sprint.sprint) + width + 1}
              y={yScale(sprint.days)}
              width={5}
              height={yScale(0) - yScale(sprint.days)}
              fill={getColorForSum(sprint.days)}
            />
            <Tooltip triggerRef={rectRef}>
              <rect
                x={tooltipXOffset}
                y={-15}
                width={tooltipWidth}
                height={(sprint.absent.length + 1) * lineHeight}
                rx={2}
                ry={2}
                style={{ filter: "drop-shadow(0 1px 1px #ccc)" }}
                fill="white"
              />
              {[...sprint.absent]
                .sort((a, b) => b.days - a.days)
                .map(({ name, days }, index) => (
                  <text
                    opacity={0.9}
                    key={name}
                    textAnchor={"middle"}
                    x={tooltipWidth / 2 + tooltipXOffset}
                    y={index * lineHeight}
                    fontWeight={700}
                    fontSize={fontSize}
                    fill={getColorForDays(days)}
                  >
                    {name}: {days}
                  </text>
                ))}
              <line
                x1={tooltipXOffset + horizontalLinePadding}
                y1={horizontalLineYPosition}
                x2={tooltipXOffset + tooltipWidth - horizontalLinePadding}
                y2={horizontalLineYPosition}
                stroke="rgb(52, 69, 99)"
              />
              <text
                textAnchor={"middle"}
                x={tooltipWidth / 2 + tooltipXOffset}
                y={sprint.absent.length * lineHeight}
                fontWeight={900}
                fontSize={fontSize}
                fill={getColorForSum(sprint.days)}
              >
                {sprint.days}
              </text>
            </Tooltip>
          </Fragment>
        )
      })}
    </Fragment>
  )
}

class Chart extends React.Component {
  render() {
    return (
      <div className="chart">
        <ResponsiveBar
          {...this.props}
          layers={this.getLayers()}
          innerPadding={1}
          borderRadius={3}
          enableLabel={true}
          data={this.props.data}
          keys={this.props.keys}
          indexBy="sprint"
          padding={0.2}
          colors={this.props.colors}
          tooltip={({ id, value, color }) => (
            <strong style={{ color }}>
              {id}: {value.toFixed(0)} {this.props.unitAbbreviation}
            </strong>
          )}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisRight={
            this.props.showRightAxis === undefined || this.props.showRightAxis
              ? { tickSize: 5, tickPadding: 5, tickRotation: 0 }
              : undefined
          }
          onClick={this.props.handleProjectClick}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: value => {
              const values = value.split("_")
              return (
                <Fragment>
                  <tspan
                    x="0"
                    dy="0.2em"
                    onClick={() => this.props.handleSprintSelection(values[0] || null)}
                    className={"sprint-name"}
                  >
                    {values[0] || "-"}
                  </tspan>
                  <tspan x="0" dy="1.4em" fontSize="90%">
                    {values[1] || "-"}
                  </tspan>
                  <tspan x="0" dy="1.3em" fontSize="95%" fontWeight="bold">
                    {values[2] || "-"}{" "}
                    {values[2] && (
                      <tspan fill="#8F9FA6" fontWeight="normal">
                        SP
                      </tspan>
                    )}
                  </tspan>
                </Fragment>
              )
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: this.props.unit,
            legendPosition: "middle",
            legendOffset: -40,
          }}
          label={d => (
            <Fragment>
              <tspan x="3px" textAnchor="start" dy="-0.5em">
                {`${d.value.toFixed(0)} ${this.props.unitAbbreviation}`}
              </tspan>
              <tspan x="3px" textAnchor="start" dy="1em">
                {d.data[`${d.id}Key`]}
              </tspan>
            </Fragment>
          )}
          labelSkipWidth={0}
          labelSkipHeight={0}
          labelTextColor={"#FFFFFF"}
          legends={[
            {
              dataFrom: "keys",
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: 140,
              translateY: 0,
              itemsSpacing: 18,
              itemWidth: 100,
              itemHeight: 10,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 17,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        ></ResponsiveBar>{" "}
      </div>
    )
  }

  getLayers = () => {
    // order of elements matters
    const layers = ["grid", "axes"]

    if (this.props.showTargetLines) {
      layers.push(Line2, Line)
    }
    layers.push("bars", "markers")
    if (this.props.showLegend) {
      layers.push("legends")
    }
    if (this.props.showAbsentIndicator) {
      layers.push(AbsentIndicator)
    }
    return layers
  }
}

export default Chart
