/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Country {
  AUSTRIA = "AUSTRIA",
  HELLAS = "HELLAS",
}

export enum DepartmentEnum {
  BACKOFFICE = "BACKOFFICE",
  DESIGN = "DESIGN",
  DEVELOPMENT = "DEVELOPMENT",
  DEVOPS = "DEVOPS",
  MARKETING_BUSINESS = "MARKETING_BUSINESS",
  MARKETING_BUSINESS_2 = "MARKETING_BUSINESS_2",
  PRODUCT_OWNERS = "PRODUCT_OWNERS",
}

export enum LanguageCode {
  DE = "DE",
  EN = "EN",
}

export interface CreateCustomerMetadata {
  jiraProjectKey: string;
  projectName: string;
  companyName: string;
  companyType: string;
  salutation: string;
  lastname: string;
  firstname: string;
  streetName: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: string;
  vatId: string;
  dashboardName: string;
  dashboardColor: string;
  hasSupportContract: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
