import store from "store"
import { HttpLink } from "apollo-link-http"
import { onError } from "apollo-link-error"
import {toast} from "react-toastify";
import { setContext } from 'apollo-link-context';

export const authLink = setContext((_, { headers }) => {
  const accessToken = store.get("access_token")

  return {
    headers: {
      ...headers,
      Authorization: accessToken && accessToken.length > 0 && accessToken !== "logout" ? `Bearer ${accessToken}` : "",
    },
  }
})

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  /*
  onError receives a callback in the event a GraphQL or network error occurs.
  This example is a bit contrived, but in the real world, you could connect
  a logging service to the errorLink or perform a specific action in response
  to an error.
  */
  if (graphQLErrors)
    graphQLErrors.map(({ message, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`, networkError)
    toast.error("Es gibt Probleme mit der Netzwerkverbindung")
  }
});

export const queryOrMutationLink = (uri: any, config = {}) =>
  // createPersistedQueryLink({ useGETForHashedQueries: true }).concat(
    new HttpLink({
      uri,
      ...config,
      credentials: 'same-origin',
    })
  // )
