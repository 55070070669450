import Button from "@atlaskit/button"
import CrossIcon from "@atlaskit/icon/glyph/cross"
import { ModalHeader } from "@atlaskit/modal-dialog"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { CustomersContext } from "../../../../../../../context/CustomersContext"
import { ModalHeaderSpacer } from "../../../../../../styled/ModalHeaderSpacer"
import { StoryKey } from "../../../../../../styled/StoryKey"

export const EditCustomerMetadataFormHeader: FunctionComponent = () => {
  const { t } = useTranslation()

  const { customerMetadata, closeModal, isEdit } = useContext(CustomersContext)

  const { jiraProjectKey } = customerMetadata

  return (
    <ModalHeader>
      <StoryKey>{jiraProjectKey}</StoryKey>
      <ModalHeaderSpacer />
      {isEdit ? t("page.customer.modal.edit") : t("page.customer.modal.create")}
      {t("page.customer.modal.customer_metadata")}
      <ModalHeaderSpacer />
      <Button
        onClick={closeModal}
        appearance="subtle"
        iconBefore={<CrossIcon label={t("page.customer.modal.close")} />}
      />
    </ModalHeader>
  )
}
