import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useSnackbar } from "../../../../context/SnackbarContext";
import { useVersionQuery } from "../../../../api/backend/graphql/generated";
import { ApolloError } from "@apollo/client";

export interface IVersionResponse {
  version: string;
  ahead: string;
  commit: string;
  short: string;
  branch: string;
  docker_tag: string;
  latest_tag: string;
  timestamp: string;
}

interface IStatusContextProps {
  loading: boolean;
  versionFrontend: string;
  commitFrontend: string;
  aheadFrontend: string;
  branchFrontend: string;
  tag: string;
  commit: string;
  branch: string;
  ahead: number | null;
  env: string;
}

export const StatusContext = createContext<IStatusContextProps>({} as IStatusContextProps)

interface IStatusContextProviderProps extends PropsWithChildren<any> {
}

export const StatusContextProvider: FC<IStatusContextProviderProps> = ({children}) => {
  const {showSnackbar} = useSnackbar();

  const [versionFrontend, setVersionFrontend] = useState<string>('');
  const [commitFrontend, setCommitFrontend] = useState<string>('');
  const [aheadFrontend, setAheadFrontend] = useState<string>('');
  const [branchFrontend, setBranchFrontend] = useState<string>('');

  const {data, loading} = useVersionQuery({
    fetchPolicy: 'cache-first',
    onError: (error: ApolloError) => {
      showSnackbar({
        message: error.message,
        severity: 'error'
      });
    }
  });
  let tag: string = '';
  let commit: string = '';
  let branch: string = '';
  let ahead: number | null = null;
  let env: string = '';
  const version = data?.version;
  if (version) {
    tag = version.tag;
    commit = version.commit;
    branch = version.branch;
    ahead = version.ahead;
    env = version.env;
  }

  useEffect(() => {
    fetch('/version')
      .then((response) => response.json())
      .then((data: IVersionResponse) => {
        setVersionFrontend(data.version);
        setCommitFrontend(data.commit);
        setAheadFrontend(data.ahead);
        setBranchFrontend(data.branch);
      })
      .catch(e => {
        showSnackbar({
          message: e.message,
          severity: 'error'
        })
      })
  }, [showSnackbar]);

  return (
    <StatusContext.Provider
      value={ {
        loading,
        tag,
        commit,
        branch,
        ahead,
        env,
        versionFrontend,
        commitFrontend,
        aheadFrontend,
        branchFrontend
      } }
    >
      { children }
    </StatusContext.Provider>
  )
}

export const useStatus = () => useContext(StatusContext);
