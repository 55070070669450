import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IssueComment } from "../../api/graphql/query/issue";
import { Comment } from "./Comment";

interface ICommentsProps {
  comments: IssueComment[];
  handleSubmit: (comment: string) => Promise<any>;
}

export const Comments: FC<ICommentsProps> = (props) => {
  const { t } = useTranslation();
  const { comments, handleSubmit } = props;

  return (
    <Grid container style={{ marginBottom: 20 }}>
      <Grid item xs={12}>
        <h3>{t("issue.comment.comments")}</h3>
        <Grid container item xs={12}>
          <Comment editMode={true} handleSubmit={handleSubmit} />
        </Grid>
        <Grid container item xs={12}>
          {comments
            // sort descending by date
            .sort((a, b) => moment(b.date).diff(a.date))
            .map((comment) => (
              <Grid item xs={12} key={comment.id}>
                <Comment
                  editMode={false}
                  comment={comment}
                  handleSubmit={handleSubmit}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
