import { FormikProvider, useFormik, useFormikContext } from "formik"
import React, { createContext, FC, useContext } from "react"
import { CreateCustomerMetadata } from "../../../../../../../api/graphql/graphql-global-types"
import { CustomersContext } from "../../../../../../../context/CustomersContext"
import { useCustomerMetadataValidationSchema } from "./hooks/use-customer-metadata-validation-schema"

export interface ISnackbarMessage {
  message: string
}

interface EditCustomerMetadataContextType {}

export const EditCustomerMetadataContext = createContext<EditCustomerMetadataContextType>(
  {} as EditCustomerMetadataContextType
)

interface EditCustomerMetadataContextProviderProps {
  initialValues: CreateCustomerMetadata
}

export const EditCustomerMetadataContextProvider: FC<
  EditCustomerMetadataContextProviderProps
> = props => {
  const { initialValues, children } = props

  const { createOrEditCustomerMetadata } = useContext(CustomersContext)

  const validationSchema = useCustomerMetadataValidationSchema()
  const formik = useFormik<CreateCustomerMetadata>({
    initialValues,
    onSubmit: data => {
      return createOrEditCustomerMetadata(data)
    },
    validationSchema,
  })

  return (
    <EditCustomerMetadataContext.Provider value={{}}>
      <FormikProvider value={formik}>{children}</FormikProvider>
    </EditCustomerMetadataContext.Provider>
  )
}

export const useEditCustomerMetadataContext = () => useContext(EditCustomerMetadataContext)
export const useEditCustomerMetadataForm = () => useFormikContext<CreateCustomerMetadata>()
