import React, { FunctionComponent } from "react"
import { Redirect } from "react-router"
import Page, { Grid, GridColumn } from "@atlaskit/page"
import { AuthConsumer } from "../../context/AuthContext"
import { ToastContainer } from "react-toastify"
import { ROUTE_DICTIONARY } from "../../routes"
import { PageNavigation } from "./PageNavigation"

interface IBaseGridProps {
  isAuthenticated: boolean
  isFullwidth: boolean
}

const BaseGrid: FunctionComponent<IBaseGridProps> = props => {
  const { isAuthenticated, isFullwidth, children } = props
  if (isAuthenticated) {
    if (isFullwidth) {
      return (
        <div style={{ paddingLeft: "1em", paddingRight: "1em", paddingBottom: "2em" }}>
          {children}
        </div>
      )
    }

    return (
      <Grid>
        <GridColumn medium={12}>{children}</GridColumn>
      </Grid>
    )
  }

  return <>{children}</>
}

export const PageLayout: FunctionComponent = props => {
  const { children } = props
  const fullWidthRoutes = [
    ROUTE_DICTIONARY.ERP.path,
    ROUTE_DICTIONARY.DASHBOARD.path,
    ROUTE_DICTIONARY.RESOURCES.path,
  ]

  return (
    <AuthConsumer>
      {({ isAuthenticated }) => {
        const shouldRedirect =
          window.location.pathname !== ROUTE_DICTIONARY.LOGIN.path &&
          window.location.pathname !== ROUTE_DICTIONARY.STATUS.path &&
          !isAuthenticated()
        return (
          isAuthenticated && (
            <Page navigation={isAuthenticated() ? <PageNavigation /> : null}>
              <ToastContainer />
              {shouldRedirect && <Redirect to={ROUTE_DICTIONARY.LOGIN.path} />}
              {!shouldRedirect && (
                <BaseGrid
                  isAuthenticated={isAuthenticated()}
                  isFullwidth={
                    !!fullWidthRoutes.find(item => window.location.pathname.startsWith(item))
                  }
                >
                  {children}
                </BaseGrid>
              )}
            </Page>
          )
        )
      }}
    </AuthConsumer>
  )
}
