import React, { FunctionComponent, useCallback, useContext } from "react";
import { Button } from "@atlaskit/button/components/Button";
import { CustomersContext } from "../../../../context/CustomersContext";
import {
  GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata_customerMetadata
} from "../../../../api/graphql/query/types/GetJiraProjectsWithMetadata";
import { useTranslation } from "react-i18next";

interface IEditButtonProps {
  jiraKey: string;
  projectName: string;
  customerData?: GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata_customerMetadata;
}

export const EditButton: FunctionComponent<IEditButtonProps> = (props) => {
  const {t} = useTranslation();
  const {jiraKey, projectName, customerData} = props;
  const {isLoading, openModal} = useContext(CustomersContext);

  const handleClick = useCallback(() => {
    if (customerData) {
      openModal(jiraKey, projectName, customerData);
      return
    }

    openModal(jiraKey, projectName);
  }, [jiraKey, projectName, customerData, openModal]);

  return (
    <Button
      isDisabled={false}
      isLoading={isLoading}
      isSelected={false}
      spacing="default"
      type='button'
      shouldFitContainer={true}
      autoFocus={false}
      onClick={handleClick}
    >
      {t("page.customer.table.button.edit")}
    </Button>
  )
}