import React, { FC } from "react";
import store from "store"
import PageHeader from "@atlaskit/page-header";
import { useTranslation } from "react-i18next";
import HeaderInfo from "../../styled/HeaderInfo";
import { useStatus } from "./context/StatusContext";
import TableTree from "@atlaskit/table-tree";
import { AuthConsumer, IAuthContextState } from "../../../context/AuthContext";

type Content = { value: string; description: string };

type Item = {
  id: string;
  content: Content;
  hasChildren: boolean;
  children?: Item[];
};
export const StatusContent: FC = () => {
  const {t} = useTranslation();
  const {
    tag,
    commit,
    commitFrontend,
    branchFrontend,
    branch,
    versionFrontend,
    aheadFrontend,
    ahead,
    env
  } = useStatus();

  const Description = (props: Content) => <span>{ props.description }</span>
  const Value = (props: Content) => <span>{ props.value }</span>

  const environment = () => {
    const hostname = window.location.hostname
    if (hostname.endsWith(".herokuapp.com")) {
      switch (hostname.split("-").shift()) {
        case "sta":
          return "Staging"
        case "dev":
          return "Development"
        case "prd":
          return "Produktiv - System"
      }
    }

    if (hostname.endsWith(".denovo.at")) {
      switch (hostname.split(".").shift()) {
        case "care":
          return "Produktiv - System"
      }
    }

    return "Local"
  }

  const items = (auth: IAuthContextState): Item[] => {
    return [
      {
        id: "version",
        content: {
          description: t("system.status.page.version.description"),
          value: `Web: ${ versionFrontend }   →   API: ${ tag }`
        },
        hasChildren: true,
        children: [
          {
            id: "version.webclient",
            content: {
              description: t("system.status.page.version.webclient"),
              value: versionFrontend
            },
            hasChildren: true,
            children: [
              {
                id: "version.webclient.commit",
                content: {
                  description: t("system.status.page.version.commit"),
                  value: commitFrontend
                },
                hasChildren: false,
              },
              {
                id: "version.webclient.ahead",
                content: {
                  description: t("system.status.page.version.ahead"),
                  value: aheadFrontend
                },
                hasChildren: false,
              },
              {
                id: "version.webclient.branch",
                content: {
                  description: t("system.status.page.version.branch"),
                  value: branchFrontend
                },
                hasChildren: false,
              },
            ]
          },
          {
            id: "version.api",
            content: {
              description: "API",
              value: tag
            },
            hasChildren: true,
            children: [
              {
                id: "version.api.commit",
                content: {
                  description: t("system.status.page.version.commit"),
                  value: commit
                },
                hasChildren: false,
              },
              {
                id: "version.api.branch",
                content: {
                  description: t("system.status.page.version.branch"),
                  value: branch
                },
                hasChildren: false,
              },
              {
                id: "version.api.ahead",
                content: {
                  description: t("system.status.page.version.ahead"),
                  value: ahead?.toString() || ''
                },
                hasChildren: false,
              },
              {
                id: "version.api.environment",
                content: {
                  description: t("system.status.page.version.environment"),
                  value: env
                },
                hasChildren: false,
              },
            ]
          },
        ]
      },
      {
        id: "endpoints",
        content: {
          description: t("system.status.page.endpoints.description"),
          value: environment(),
        },
        hasChildren: true,
        children: [
          {
            id: "endpoints.api",
            content: {
              description: t("system.status.page.endpoints.api"),
              value: store.get("GRAPHQL_ENDPOINT"),
            },
            hasChildren: false,
          },
          {
            id: "endpoints.subscriptions",
            content: {
              description: t("system.status.page.endpoints.subs"),
              value: store.get("SUBSCRIPTION_ENDPOINT"),
            },
            hasChildren: false,
          },
        ]
      },
      {
        id: "auth",
        content: {
          description: t("system.status.page.auth.description"),
          value: auth.isAuthenticated() ? t("system.status.page.auth.logged") : t("system.status.page.auth.not_logged"),
        },
        hasChildren: true,
        children: [
          {
            id: "auth.user",
            content: {
              description: t("system.status.page.auth.user"),
              value: auth.name,
            },
            hasChildren: false,
          },
          {
            id: "auth.abilities",
            content: {
              description: t("system.status.page.auth.abilities"),
              value: auth.getAbilities().length > 0
                ? auth.getAbilities().join(", ")
                : <em>t("system.status.page.auth.none")</em>
            },
            hasChildren: false,
          }
        ]
      }
    ]
  }

  return (
    <>
      <PageHeader>
        { t("system.status.page.title") }
        <HeaderInfo>{ t("global.title") }</HeaderInfo>
      </PageHeader>

      <AuthConsumer>
        { (auth) => (
          <TableTree
            headers={ [t("system.status.page.column.description.title"), t("system.status.page.column.value.title")] }
            columns={ [Description, Value] }
            columnWidths={ ['30%'] }
            items={ items(auth) }
          />
        ) }
      </AuthConsumer>
    </>
  )
}
