import * as React from "react"
import __ from "lodash"
import store from "store"
import moment from "moment"
import { Query, QueryResult } from "react-apollo"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { Mutation } from "react-apollo"
import Spinner from "@atlaskit/spinner"
import TableTree from "@atlaskit/table-tree"
import Button from "@atlaskit/button"
import { Grid, GridColumn } from "@atlaskit/page"
import { Checkbox } from "@atlaskit/checkbox"
import Select from "react-select"
import "rc-slider/assets/index.css"
import { IBaseProps } from "../../../utils/BaseProps"
import SPRINTS_AND_ISSUES_QUERY from "../../../api/graphql/query/sprints-and-issues"
import { SprintsAndIssues_issues } from "../../../api/graphql/query/types/SprintsAndIssues"
import ROADMAP_DOWNLOAD_MUTATION from "../../../api/graphql/mutation/roadmap-download"
import { Roadmap_sprints } from "../../../api/graphql/query/types/Roadmap"
import { RoadmapDownload } from "../../../api/graphql/mutation/types/RoadmapDownload";
import { currentSprint, listSprints, addSprints } from "../../../utils/sprint-math"

interface SprintSelection {
  label: any
  value: string
}

interface IRoadmapProps extends IBaseProps {
  history: any
}

interface IRoadmapState {
  isGeneratingSprintReview: boolean
  firstSprintKey: string | undefined
  lastSprintKey: string | undefined
  riskLeeway: number
  includeBacklog: Array<string>
  currentSprint: string
  selectedStartSprint?: SprintSelection
  selectedEndSprint?: SprintSelection
  selectedReviewSprints: SprintSelection[]
  selectedBacklogSprints: String[]
}

const Title = styled.div`
  color: rgb(52, 69, 99);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
`

const Subtitle = styled.div`
  color: #8C96A5;
  font-weight: normal;
  font-size: 14px;
`

const RoadmapHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`

const BacklogSprintCell = (props: any) => <span>{props.name || props.id}</span>
const BacklogIssueCell = (props: any) => <span>{props.summary || ""}</span>
const BacklogEstimationCell = (props: any) => {
  if (props.sum) {
    return <b>{parseInt(props.sum)} SP</b>
  }
  return <span>{(props.estimation !== null && props.estimation !== undefined) ? `${props.estimation} SP` : ""}</span>
}
const BacklogSelectionCell = (selectedSprints: String[], selectionChange: (selectedSprints: String[]) => void) => (props: any) => {
  if (props.name) {
    return <Checkbox
      isChecked={selectedSprints.includes(props.name)}
      onChange={(event: any) => {
        if (event.target.checked && !selectedSprints.includes(props.name)) {
          selectionChange([ ...selectedSprints, props.name ])
          return
        }
        if (!event.target.checked && selectedSprints.includes(props.name)) {
          selectionChange(selectedSprints.filter(name => name !== props.name))
          return
        }
      }}
    />
  }
  return <span></span>
}

class Roadmap extends React.Component<IRoadmapProps, IRoadmapState> {

  constructor(props: IRoadmapProps) {
    super(props)
    let includeBacklog: Array<string> = []

    try {
      includeBacklog = JSON.parse(store.get(`${__.get(props, "match.params.key", "default")}_include_backlog`.toLowerCase()))
    } catch (error) {
      console.log(error)
    }

    this.state = {
      isGeneratingSprintReview: false,
      firstSprintKey: store.get(`${__.get(props, "match.params.key", "default")}_roadmap_first_sprint`.toLowerCase()) || undefined,
      lastSprintKey: store.get(`${__.get(props, "match.params.key", "default")}_roadmap_last_sprint`.toLowerCase()) || undefined,
      riskLeeway: 0.4,
      includeBacklog,
      currentSprint: currentSprint(),
      selectedReviewSprints: [{
        label: addSprints(currentSprint(), -1),
        value: addSprints(currentSprint(), -1)
      }],
      selectedBacklogSprints: []
    }
  }

  handleDetailClose = () => {
    this.props.history.push(`/project/${__.get(this.props, "match.params.key")}/roadmap`)
  }

  handleDetailOpen = (issueKey: string) => () => {
    this.props.history.push(`/project/${__.get(this.props, "match.params.key")}/roadmap/${issueKey}`)
  }

  handleSprintChange = (selectableSprints: Array<string>) => (range: Array<number>) => {
    this.setState({
      currentSprint: selectableSprints[range[0]]
    })
  }

  handleStartSprintChange = (value: any) => {
    this.setState({ selectedStartSprint: value })
  }

  handleEndSprintChange = (value: any) => {
    this.setState({ selectedEndSprint: value })
  }

  handleSprintRange = (selectableSprints: Array<string>) => (range: Array<number>) => {
    const firstSprintKey = selectableSprints[range[0] || 0]
    const lastSprintKey = selectableSprints[range[1] || (range[1] === 0 ? 0 : selectableSprints.length)]
    
    this.setState({ firstSprintKey, lastSprintKey })

    store.set(`${__.get(this.props, "match.params.key", "default")}_roadmap_first_sprint`.toLowerCase(), firstSprintKey)
    store.set(`${__.get(this.props, "match.params.key", "default")}_roadmap_last_sprint`.toLowerCase(), lastSprintKey)
  }

  handleReviewSprintChange = (selectedReviewSprints: any) => {
    this.setState({ selectedReviewSprints })
  }

  handleSprintBacklog = (event: any) => {
    const includeBacklog = __.uniq(event.target.checked
      ? [...(this.state.includeBacklog || []), event.target.value]
      : this.state.includeBacklog.filter(value => value !== event.target.value)
    )

    this.setState({ includeBacklog })
    store.set(`${__.get(this.props, "match.params.key", "default")}_include_backlog`.toLowerCase(), JSON.stringify(includeBacklog))
  }

  generateRoadmapDownloadHandler = (mutation: Function, projectKey: String, from: SprintSelection, to: SprintSelection, reviewSprints: SprintSelection[], backlogSprints: String[]) => () => {
    mutation({
      variables: {
        projectKey,
        from: from.value,
        to: to.value,
        reviewSprints: reviewSprints.map(selection => selection.value),
        backlogSprints
      }
    })
  }

  handleRoadmapComplete = (response: RoadmapDownload) => {
    setTimeout(
      async () => {
        if (response.roadmap) {
          const url = `data:${response.roadmap.contentType};base64,${response.roadmap.base64data}`
          const internal = await fetch(url)
          const blob = await internal.blob()

          const a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = response.roadmap.filename || `Roadmap-${moment().format("YYYYMMDD")}.docx`
          a.click()
          URL.revokeObjectURL(a.href)
        }
      },
      100
    )
  }

  handleRoadmapError = () => {

  }

  public render() {
    const { t } = this.props
    const projectKey = __.get(this.props, "match.params.key")
    return (
      <>
        <Query query={SPRINTS_AND_ISSUES_QUERY} variables={{ projectKey }} fetchPolicy="cache-first">
        {({ data, loading, error, refetch }: QueryResult) => {
          console.log("data", data)

          type Match = Array<string> | null
          type SprintInfo = { match: Match, key: string | undefined, info: Roadmap_sprints, issues?: SprintsAndIssues_issues[], estimation?: Number }
          const plannedSprints = __.get(data, "sprints", [])
            .map((sprint: Roadmap_sprints) => ({
              info: sprint,
              key: (sprint.name.match(/([1-9]{1}[0-9]{3}\/[0-9]{2})/ig) || []).shift(),
              match: sprint.name.match(/([1-9]{1}[0-9]{3}\/[0-9]{2})/ig) }
            ))

          const sortedSprints = __.sortBy(
              plannedSprints
                .filter((sprint: SprintInfo) => sprint.key),
              (sprint: SprintInfo) => sprint.key
            )

          const sprintKeys = listSprints(
            sortedSprints[0] && sortedSprints[0].key,
            sortedSprints[sortedSprints.length - 1] && sortedSprints[sortedSprints.length - 1].key
          )

          const sprints = sprintKeys.map(sprint => ({
            label: sprint,
            value: sprint
          }))

          const selectedFromSprint = this.state.selectedStartSprint || sprints[0]
          const selectedToSprint = this.state.selectedEndSprint || sprints[sprints.length - 1]

          const selectedSprintList = listSprints(selectedFromSprint.value, selectedToSprint.value)
          const selectedReviewSprints = (this.state.selectedReviewSprints || []).filter(sprint => selectedSprintList.includes(sprint.value))

          const backlogSprints = plannedSprints
            .filter((sprint: SprintInfo) => !sprint.key)
            .map((sprint: SprintInfo) => {
              sprint.issues = (data.issues || []).filter((issue: SprintsAndIssues_issues) => __.get(issue, "sprint.name") === sprint.info.name)
              sprint.estimation = (sprint.issues || []).reduce((acc, issue) => {
                return acc + (issue.estimation || 0)
              }, 0)
              return sprint
            })

          const backlogSprintsTableItems = backlogSprints.map((sprint: SprintInfo, index: Number) => {
            return {
              id: `${sprint.info.name}:${index}`,
              content: {
                ...sprint.info,
                sum: sprint.estimation
              },
              hasChildren: sprint.issues && sprint.issues.length > 0,
              children: (sprint.issues || []).map((issue: SprintsAndIssues_issues, index: Number) => {
                return {
                  id: `${issue.key}:${index}`,
                  content: issue
                }
              })
            }
          })

          return (<>
            <RoadmapHeader>
              <Title>{t("page.roadmap.header.title")}</Title>
              <Subtitle>
                  {loading &&
                    <React.Fragment>
                      <span style={{ paddingRight: "5px" }}><Spinner size="small" /></span> {t("page.roadmap.loading.message")}
                    </React.Fragment>
                  }
                  {!loading && data && __.get(data, "project.name")}
              </Subtitle>
            </RoadmapHeader>
            {!loading && data && <>

              <Grid>
                <GridColumn medium={12}><h2>{t("page.roadmap.table.heading")}</h2></GridColumn>
                <GridColumn medium={3}>{t("page.roadmap.table.from")}</GridColumn>
                <GridColumn medium={6}>{t("page.roadmap.table.review")}</GridColumn>
                <GridColumn medium={3}>{t("page.roadmap.table.to")}</GridColumn>
              </Grid>
              <Grid>
                <GridColumn medium={3}>
                  <Select
                    isRtl
                    isSearchable
                    options={sprints}
                    value={selectedFromSprint}
                    onChange={this.handleStartSprintChange}
                  />
                </GridColumn>
                <GridColumn medium={6}>
                  <Select
                    isMulti
                    isSearchable
                    options={selectedSprintList.map(sprint => ({
                      value: sprint,
                      label: sprint,
                    }))}
                    value={selectedReviewSprints}
                    onChange={this.handleReviewSprintChange}
                    placeholder={t("page.roadmap.table.select_placeholder")}
                  />
                </GridColumn>
                <GridColumn medium={3}>
                  <Select
                    isSearchable
                    options={[...sprints].reverse()}
                    value={selectedToSprint}
                    onChange={this.handleEndSprintChange}
                  />
                </GridColumn>
              </Grid>

              <br/><br/>    
              
              <Grid>
                <GridColumn medium={12}><h2>{t("page.roadmap.table.backlog")}</h2></GridColumn>
                <GridColumn medium={12}>
                  <TableTree
                    headers={[
                      <Checkbox
                        isChecked={backlogSprints
                          .map((sprint: SprintInfo) => sprint.info.name)
                          .reduce((acc: Boolean, name: String) => acc && this.state.selectedBacklogSprints.includes(name), true)}
                        onChange={(evt: any) => {
                          if (evt.target.checked) {
                            this.setState({
                              selectedBacklogSprints: backlogSprints.map((sprint: SprintInfo) => sprint.info.name)
                            })
                          }
                          else {
                            this.setState({
                              selectedBacklogSprints: []
                            })
                          }
                        }}
                      />, t("page.roadmap.table.sprint_issue"), t("page.roadmap.table.description"), t("page.roadmap.table.estimation")]}
                    columns={[
                      BacklogSelectionCell(this.state.selectedBacklogSprints, (selectedSprints: String[]) => this.setState({ selectedBacklogSprints: selectedSprints })),
                      BacklogSprintCell,
                      BacklogIssueCell,
                      BacklogEstimationCell
                    ]}
                    columnWidths={["5%", "30%", "50%", "15%"]}
                    items={backlogSprintsTableItems}
                  />
                  {(!backlogSprints || backlogSprints.length < 1) && t("page.roadmap.table.no_sprints")}
                </GridColumn>
              </Grid>

              <br/>

              <Mutation mutation={ROADMAP_DOWNLOAD_MUTATION} onCompleted={this.handleRoadmapComplete} onError={this.handleRoadmapError}>
                {(roadmapDownload: Function, { loading }: { loading: boolean }) => (
                  <Button
                    appearance="primary"
                    onClick={this.generateRoadmapDownloadHandler(roadmapDownload, projectKey, selectedFromSprint, selectedToSprint, selectedReviewSprints, this.state.selectedBacklogSprints)}
                    isLoading={loading}
                    isDisabled={loading}
                  >
                    {t("page.roadmap.button")}
                  </Button>
                )}
              </Mutation>
            </>}
          </>)}
        }
        </Query>
      </>
    )
  }
}

export default withTranslation()(Roadmap)
