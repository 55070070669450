import { Box, Grid, Typography, useTheme } from "@material-ui/core"
import { useFormikContext } from "formik"
import React, { FC, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "../../../context/SnackbarContext"
import { FormikFileSelectItem } from "./formik-file-select-item"

type IFormikFileSelectProps = {
  valueName: string
  label: string
  maxFiles?: number
}

export const FormikFileSelect: FC<IFormikFileSelectProps> = ({ valueName, label, maxFiles }) => {
  const { showSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()

  const { getFieldProps, getFieldHelpers } = useFormikContext()
  const { value } = getFieldProps<File[]>(valueName)
  const { setValue } = getFieldHelpers<File[]>(valueName)

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      if (maxFiles && value.length + acceptedFiles.length > maxFiles) {
        showSnackbar({
          message: t("fields.file_select.max_files_error", { maxFiles }),
          severity: "error",
        })
        return
      }

      const filesToAdd: Promise<File>[] = []
      for (const file of acceptedFiles) {
        filesToAdd.push(
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
              resolve(file)
            }
            reader.onerror = () => {
              reject()
              showSnackbar({
                message: t("fields.file_select.file_error"),
                severity: "error",
              })
            }
            reader.readAsArrayBuffer(file)
          })
        )
      }
      const files = await Promise.all(filesToAdd);

      setValue([...value, ...files]);


    },
    [showSnackbar, t, setValue, value, maxFiles]
  )

  const onDropRejected = useCallback(() => {
    showSnackbar({
      message: t("fields.file_select.file_error"),
      severity: "error",
    })
  }, [showSnackbar, t])

  const removeByIndex = useCallback(
    index => {
      setValue(value.filter((item, i) => index !== i))
    },
    [setValue, value]
  )

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 20,
    maxSize: 12582912, // 12MB
    onDropAccepted,
    onDropRejected,
  })

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textSecondary">
            {label}
          </Typography>
        </Grid>

        <Grid item xs={value.length > 0 ? 4 : 12}>
          <Grid container justifyContent="center" style={{ height: "100%", cursor: "pointer" }}>
            <Box
              width="100%"
              height="100%"
              {...getRootProps()}
              sx={{
                border: `2px dotted ${theme.palette.text.secondary}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              <Grid
                container
                style={{ height: "100%" }}
                alignContent="center"
                justifyContent="center"
              >
                <Typography variant="caption" align="center">
                  {t("fields.file_select.drop_area")}
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {value.length > 0 && (
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {value.map((file, index) => (
                <FormikFileSelectItem
                  valueName={`${valueName}.${index}`}
                  onRemove={() => removeByIndex(index)}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
