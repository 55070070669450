import { gql } from "apollo-boost"
import { Mutation } from "react-apollo"
import { Offer, OfferVariables } from "./types/Offer"

export default gql`
    mutation Offer($projectKey: String!, $sprintName: String!, $number:String!, $version: String!, $spEur: Float!, $lang: LanguageCode, $nonFunctionalRequirements: String) {
        offer(projectKey: $projectKey, sprintName: $sprintName, number: $number, version: $version, spEur: $spEur, lang: $lang, nonFunctionalRequirements: $nonFunctionalRequirements) {
            sprint {
                number
            }
            base64data
            contentType
            filename
        }
    }`

export class OfferMutation extends Mutation<Offer, OfferVariables> {
}
