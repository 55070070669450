import { gql } from "apollo-boost"
import { Mutation } from "react-apollo"
import { UserStory, UserStoryVariables } from './types/UserStory';

export default gql`
mutation UserStory($projectKey: String!, $sprintName: String!, $version: String!) {
  userStory(projectKey: $projectKey, sprintName: $sprintName, version: $version) {
    sprint {
      number
    }
    
    base64data
    contentType
    filename
  }
}`

export class UserStoryMutation extends Mutation<UserStory, UserStoryVariables> { }
