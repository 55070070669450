import { gql } from "apollo-boost"
import { Mutation } from "react-apollo"
import { RoadmapDownload, RoadmapDownloadVariables } from './types/RoadmapDownload';

export default gql`
mutation RoadmapDownload($projectKey: String!, $from: String!, $to: String!, $reviewSprints: [String!], $backlogSprints: [String!]) {
  roadmap(projectKey: $projectKey, from: $from, to: $to, reviewSprints: $reviewSprints, backlogSprints: $backlogSprints) {
    base64data
    contentType
    filename
  }
}`

export class RoadmapDownloadMutation extends Mutation<RoadmapDownload, RoadmapDownloadVariables> { }
