import React, { FunctionComponent } from "react"
import { Query } from "react-apollo"
import {
  ISprintPreviewResult,
  SPRINT_PREVIEW_QUERY,
} from "../../../../api/graphql/query/sprint-preview"
import Spinner from "@atlaskit/spinner"
import { useTranslation } from "react-i18next"
import { Grid, makeStyles } from "@material-ui/core"
import { SprintPreviewTable } from "./components/SprintPreview/SprintPreviewTable"

const DEFAULT_VALUE = 6;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
  },
  table: {
    height: `100%`,    
    paddingTop: theme.spacing(2),
  },
}))

interface ISprintPreviewProps {}

export const SprintPreview: FunctionComponent<ISprintPreviewProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles() 
  return (
    <Grid container spacing={0} className={classes.container}>     
      <Grid item xs={12} className={classes.table}>
        <Query query={SPRINT_PREVIEW_QUERY} variables={{ amountOfSprints: DEFAULT_VALUE }}>
          {({ data, loading, error }: ISprintPreviewResult) => {
            return (
              <>
                {loading && (
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    style={{ height: "100%" }}
                  >
                    <Spinner />
                    <span style={{ marginLeft: "1em" }}>{t("page.dashboard.sprint_preview.loading.message")}</span>
                  </Grid>
                )}
                {error && (
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    style={{ height: "100%" }}
                  >
                    <span>{t("page.dashboard.sprint_preview.error.message")}</span>
                  </Grid>
                )}
                {!loading && !error && data?.sprintPreview && (
                  <SprintPreviewTable data={data.sprintPreview} />
                )}
              </>
            )
          }}
        </Query>
      </Grid>
    </Grid>
  )
}
