import React, { FC } from "react";
import { Background } from "../../styled/login";
import { LoginContextProvider } from "./context/LoginContext";
import { LoginContent } from "./LoginContent";
import { IBaseProps } from "../../../utils/BaseProps";

interface ILoginPageProps extends IBaseProps {
  history: any;
}

const LoginPage: FC<ILoginPageProps> = ({ history }) => (
  <Background>
    <LoginContextProvider>
      <LoginContent history={history} />
    </LoginContextProvider>
  </Background>
)

export default LoginPage;
