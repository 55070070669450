import { ModalBody } from "@atlaskit/modal-dialog"
import { Grid, MenuItem } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Salutation } from "../../../../../../../context/CustomersContext"
import { properCase } from "../../../../../../../utils/properCase"
import { FormikSwitch } from "../../../../../../formik/formik-switch"
import { FormikColorPicker } from "../../../../../../formik/formik-color-picker"
import { FormikTextField } from "../../../../../../formik/formik-text-field"

export const EditCustomerMetadataFormBody: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <ModalBody>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.table.headers.project_name")}
              valueName={"projectName"}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.company_name")}
              valueName={"companyName"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.company_type")}
              valueName={"companyType"}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={2}>
            <FormikTextField
              select
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.title")}
              valueName={"salutation"}
              size="small"
            >
              {Object.values(Salutation).map(title => (
                <MenuItem key={title} value={title}>
                  {properCase(title)}
                </MenuItem>
              ))}
            </FormikTextField>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.firstname")}
              valueName={"firstname"}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.lastname")}
              valueName={"lastname"}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.street_name")}
              valueName={"streetName"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.street_number")}
              valueName={"streetNumber"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.postal_code")}
              valueName={"postalCode"}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.city")}
              valueName={"city"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.country")}
              valueName={"country"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.vat_id")}
              valueName={"vatId"}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormikTextField
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.dashboard_name")}
              valueName={"dashboardName"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikColorPicker
              fullWidth
              variant="outlined"
              label={t("page.customer.modal.dashboard_color")}
              helperText={t("page.customer.modal.dashboard_color_hint")}
              valueName={"dashboardColor"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikSwitch
              label={t("page.customer.modal.has_support_contract")}
              valueName={"hasSupportContract"}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalBody>
  )
}
