import React, { FC, RefObject, useCallback, useMemo, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import LinkFilledIcon from "@atlaskit/icon/glyph/link-filled"
import { DateTime } from "luxon"
import IssueList from "./IssueList"
import { GetSprintsQueryResult } from "../../api/backend/graphql/types"
import SprintActions from "./SprintActions"

const SprintTitle = styled.div<{ highlight: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
  border: ${props => (props.highlight ? 1 : 0)}px solid rgb(52, 69, 99);
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafafa;

  &:hover {
    background-color: #eaeaea;
  }
`

const Spacer = styled.div`
  flex-grow: 1;
`

const DisclosureIndicator = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 12px;
    left: 4px;
    content: " ";
    height: 16px;
    width: 16px;
    background-color: ${(props: { isOpen: boolean }) => (props.isOpen ? "#253858" : "transparent")};
    border-radius: 12px;
  }

  &::before {
    position: absolute;
    top: 8px;
    content: " ";
    height: 20px;
    width: 20px;
    border: 2px solid #253858;
    border-radius: 12px;
  }
`

const Subtitle = styled.div`
  color: #8c96a5;
  font-weight: normal;
  font-size: 14px;
`

const StoryPointsIndicator = styled.div`
  font-size: 1.3em;
  padding-right: 8px;
`

interface ISprintItemProps {
  history: any
  sprint: GetSprintsQueryResult
  projectKey: string
  projectName: string
  linkToProject?: boolean
  enableQuickActions?: boolean
  innerRef?: RefObject<HTMLDivElement>
  highlightCurrentSprint?: boolean
  isBacklog?: boolean
  initialIsOpen?: boolean
  onIsOpenChange?: (value: boolean) => void
  hasSupportTicket?: boolean
  hasSupportContract?: boolean
}

export const SprintItem: FC<ISprintItemProps> = ({
  history,
  sprint,
  projectKey,
  projectName,
  linkToProject,
  enableQuickActions,
  innerRef,
  isBacklog,
  highlightCurrentSprint = true,
  initialIsOpen = false,
  onIsOpenChange = () => null,
  hasSupportTicket = false,
  hasSupportContract = false

}) => {
  const { t } = useTranslation()
  const [spSum, setSpSum] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen)
  const [isSprintActionsOpen, setIsSprintActionsOpen] = useState<boolean>(false)

  const isCurrentSprint = useMemo(() => {
    return (
      Math.round(DateTime.fromISO(sprint.start).diffNow("days").days) <= 0 &&
      Math.round(DateTime.fromISO(sprint.end).diffNow("days").days) >= 0
    )
  }, [sprint])

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
    onIsOpenChange(!isOpen)
  }, [setIsOpen, isOpen, onIsOpenChange])

  return (
    <>
      <div ref={innerRef}>
        <SprintTitle highlight={isCurrentSprint && highlightCurrentSprint}>
          <DisclosureIndicator isOpen={isOpen} onClick={handleToggle} />
          <h3>
            {linkToProject ? (
              <Link style={{ color: "inherit" }} to={`/projects/${projectKey}/sprints`}>
                {projectKey} - {sprint.name} <LinkFilledIcon size="small" label="Project" />
              </Link>
            ) : (
              <>
                {projectKey} - {sprint.name}
              </>
            )}
            <Subtitle>
              {sprint.start && sprint.end
                ? t("page.sprints.list.end", {
                    startDate: DateTime.fromISO(sprint.start).toLocaleString(),
                    endDate: DateTime.fromISO(sprint.end).toLocaleString(),
                  })
                : t("page.sprints.list.no_development")}
            </Subtitle>
            {sprint.goal && <Subtitle>{sprint.goal}</Subtitle>}
          </h3>
          <Spacer />
          {spSum !== 0 && <StoryPointsIndicator>{`${spSum} SP`}</StoryPointsIndicator>}
          {enableQuickActions && !isBacklog && (
            <>
              <SprintActions
                projectKey={projectKey}
                sprintName={sprint.name}
                isOpen={isSprintActionsOpen}
                onClose={() => {
                  setTimeout(() => {
                    setIsSprintActionsOpen(false)
                  }, 100)
                }}
              />
            </>
          )}
        </SprintTitle>
        {isOpen && (
          <IssueList
            projectKey={projectKey}
            projectName={projectName}
            sprint={sprint}
            history={history}
            setSPSum={setSpSum}
            isBacklog={isBacklog}
            hasSupportTicket={hasSupportTicket}
            hasSupportContract={hasSupportContract}
          />
        )}
      </div>
    </>
  )
}
