import { gql } from "apollo-boost"
import { Query } from "react-apollo"
import { Roadmap } from "./types/Roadmap"
import { Sprints_sprints } from "./types/Sprints"

export default gql`
  query Sprints($projectKey: String!) {
    project(key: $projectKey) {
      key
      name
      productOwner {
        name
      }
      customerMetadata {
        hasSupportContract
      }
      hasSupportTicket
    }
    sprints(projectKey: $projectKey) {
      name
      start
      end
      goal
    }
  }
`

export class SprintsQuery extends Query<Roadmap> {
}

export type Sprint = Sprints_sprints
