import React, { createContext, FC, useContext } from "react"
import { FormikProvider, useFormik } from "formik"
import { useJiraSupportValidationSchema } from "./hooks/use-jira-support-validation-schema"
import {
  CreateJiraSupportTicketMutationVariables,
  JiraPriorityType,
  useCreateJiraSupportTicketMutation,
} from "../../../../../../../../api/backend/graphql/generated"
import { ApolloError } from "@apollo/client"
import { useSnackbar } from "../../../../../../../../context/SnackbarContext"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

interface IJiraSupportContextProps {
  loading: boolean
  projectKey: string
  projectName: string
  closeModal: () => void
}

export const JiraSupportContext = createContext<IJiraSupportContextProps>(
  {} as IJiraSupportContextProps
)

interface IJiraSupportContextProviderProps {
  projectKey: string
  projectName: string
  closeModal: () => void
  parentIssueKey?: string
}

export const JiraSupportContextProvider: FC<IJiraSupportContextProviderProps> = props => {
  const { showSnackbar } = useSnackbar()
  const { projectKey, projectName, closeModal, parentIssueKey, children } = props
  const { t } = useTranslation()

  const handleComplete = () => {
    toast.success(
      t("page.sprints.support.modal.success_message")
    )
    closeModal()
  }

  const [createJiraSupportTicket, { loading }] = useCreateJiraSupportTicketMutation({
    onCompleted: handleComplete,
    onError: (error: ApolloError) => {
      showSnackbar({
        message: error.message,
        severity: "error",
      })
    },
  })

  const validationSchema = useJiraSupportValidationSchema()
  const formik = useFormik<CreateJiraSupportTicketMutationVariables>({
    initialValues: {
      title: "",
      description: "",
      priority: JiraPriorityType.MEDIUM,
      projectKey,
      attachments: [],
    },
    validateOnMount: true,
    onSubmit: data => {
      if (projectKey) {
        createJiraSupportTicket({
          variables: {
            ...data,
            projectKey,
            parentIssueKey,
          }
        });
      }
    },
    validationSchema,
  })

  return (
    <JiraSupportContext.Provider
      value={{
        loading,
        projectKey,
        projectName,
        closeModal,
      }}
    >
      <FormikProvider value={formik}>{children}</FormikProvider>
    </JiraSupportContext.Provider>
  )
}

export const useJiraSupport = () => useContext(JiraSupportContext)
