import { QueryResult } from 'react-apollo';
import { BurnedStoryPoints, BurnedStoryPoints_burnedStoryPoints, BurnedStoryPoints_burnedStoryPoints_sprints_projects } from './types/BurnedStoryPoints';
import { gql } from "apollo-boost"

export const BURNED_STORY_POINTS_QUERY = gql`
  query BurnedStoryPoints($amountOfSprints: Float!) {
    burnedStoryPoints(amountOfSprints: $amountOfSprints) {    
      sprints {
        projects {
          name
          key
          projectTotal
          avatarUrl
        }
        sprintTotal
        name
      }
      total
    }
  }

`
export interface IBurnedStoryPointsResult extends QueryResult<BurnedStoryPoints> {}
export interface IBurnedStoryPoints extends BurnedStoryPoints_burnedStoryPoints {}
export interface IBurnedStoryPointsSprintProject extends BurnedStoryPoints_burnedStoryPoints_sprints_projects {} 