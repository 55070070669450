import React, { FC } from "react";
import __ from "lodash";
import { Mutation, MutationFn, Query } from "react-apollo";
import { useTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import Markdown from "react-markdown";
// import Spinner from "@atlaskit/spinner"
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import { IBaseProps } from "../../utils/BaseProps";
import {
  getPhaseOfStatus,
  ISSUE_QUERY,
  mapStatusToColor,
  mapStatusToIcon,
  mapStatusToText,
  TYPE,
} from "../../api/graphql/query/issue";
import "../../markdown.css";

import Button from "@atlaskit/button";
import { Grid, GridColumn } from "@atlaskit/page";
import { ProgressTracker } from "@atlaskit/progress-tracker";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { Comments } from "./Comments";
import { ADD_ISSUE_COMMENT_MUTATION } from "../../api/graphql/mutation/add-issue-comment";
import { AddCommentMutation, AddCommentMutationVariables, } from "../../api/graphql/mutation/types/AddCommentMutation";
import { Issue, IssueVariables } from "../../api/graphql/query/types/Issue";
import { DOWNLOAD_ISSUE_ATTACHMENT } from "../../api/graphql/query/download-attachment";
// import { DownloadAttachment, DownloadAttachmentVariables } from "../../api/graphql/query/types/downloadAttachment"
import { useLazyQuery } from "@apollo/react-hooks";
import { DownloadAttachment, DownloadAttachmentVariables, } from "../../api/graphql/query/types/downloadAttachment";
import { downloadBase64 } from "../../utils/download";
import { AuthConsumer } from "../../context/AuthContext";
import { ModalHeaderSpacer } from "../styled/ModalHeaderSpacer";
import { StoryKey } from "../styled/StoryKey";
import { config } from "../../utils/config";

interface IIssueDetailProps extends IBaseProps {
  isOpen: boolean;
  issueKey: string;
  onClose: () => void;
}

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px;

  & > * {
    margin: 0 5px;
  }
`;

const StoryStatus = styled.div`
  border: 1px solid rgb(223, 225, 230);
  border-radius: 3px;
  padding: 0 5px;
  color: rgb(107, 119, 140);
`;

const StoryCard = styled.div``;

const StoryProgress = styled.div`
  display: flex;
  justify-content: center;
  & > * {
    flex-grow: 1;
  }
`;

// const StoryDetails = styled.div`
// `

const getIssueIcon = (type: string) => {
  const compare = `${type}`.toUpperCase().trim();
  switch (compare) {
    case "STORY":
      return TYPE.STORY.ICON;
    case "BUG":
      return TYPE.BUG.ICON;
    case "TASK":
      return TYPE.TASK.ICON;
    case "EPIC":
      return TYPE.EPIC.ICON;
    case "DESIGN DELIVERABLE":
      return TYPE.DESIGN_DELIVERABLE.ICON;
  }

  return TYPE.UNKNOWN.ICON;
};

const mapStatusToTracker = (status: string) => {
  return [
    {
      id: "requested",
      label: "Beauftragung",
      percentageComplete: getPhaseOfStatus(status) > 1 ? 100 : 0,
      status:
        getPhaseOfStatus(status) < 1
          ? "unvisited"
          : getPhaseOfStatus(status) > 1
          ? "visited"
          : "current",
    },
    {
      id: "planning",
      label: "Planung",
      percentageComplete: getPhaseOfStatus(status) > 2 ? 100 : 0,
      status:
        getPhaseOfStatus(status) < 2
          ? "unvisited"
          : getPhaseOfStatus(status) > 2
          ? "visited"
          : "current",
    },
    {
      id: "development",
      label: "Umsetzung",
      percentageComplete: getPhaseOfStatus(status) > 3 ? 100 : 0,
      status:
        getPhaseOfStatus(status) < 3
          ? "unvisited"
          : getPhaseOfStatus(status) > 3
          ? "visited"
          : "current",
    },
    {
      id: "shipping",
      label: "Lieferung",
      percentageComplete: getPhaseOfStatus(status) > 4 ? 100 : 0,
      status:
        getPhaseOfStatus(status) < 4
          ? "unvisited"
          : getPhaseOfStatus(status) > 4
          ? "visited"
          : "current",
    },
  ];
};

const IssueDetail: FC<IIssueDetailProps> = (props) => {
  const { t } = useTranslation();
  const [downloadAttachment] = useLazyQuery<
    DownloadAttachment,
    DownloadAttachmentVariables
  >(DOWNLOAD_ISSUE_ATTACHMENT, {
    onCompleted: async (data) => {
      const { base64, filename, mimeType } = data.downloadAttachment;
      if (data.downloadAttachment) {
        await downloadBase64(base64, mimeType, filename);
      }
    },
  });

  const handleClose = () => {
    if (props.onClose) props.onClose();
  };

  const postComment = async (
    addComment: MutationFn<AddCommentMutation, AddCommentMutationVariables>,
    refetch: () => Promise<any>,
    comment: string
  ) => {
    if (!comment) {
      return;
    }
    await addComment({ variables: { key: props.issueKey, comment } }).then(() =>
      refetch()
    );
  };

  // const downloadAttachment = async () => {}
  return (
    <ModalTransition>
      {props.isOpen && (
        <Query<Issue, IssueVariables>
          query={ISSUE_QUERY}
          variables={{ key: props.issueKey }}
        >
          {({ data, loading, error, refetch }) => {
            if (!data || loading || error) return <></>;
            const issueIcon = getIssueIcon(__.get(data, "issue.type.name"));
            return (
              <ModalDialog
                width="x-large"
                components={{
                  Header: () => (
                    <ModalHeader>
                      {issueIcon}
                      <StoryKey>{props.issueKey}</StoryKey>
                      {data && data.issue && (
                        <StoryStatus
                          style={{
                            color: mapStatusToColor(data.issue.status.name),
                            borderColor: mapStatusToColor(
                              data.issue.status.name
                            ),
                          }}
                        >
                          {mapStatusToIcon(data.issue.status.name)}{" "}
                          {t(`issue.issue_status.${mapStatusToText(data.issue.status.name)}`)}
                        </StoryStatus>
                      )}
                      <ModalHeaderSpacer />
                      <Button
                        onClick={handleClose}
                        appearance="subtle"
                        iconBefore={
                          <CrossIcon label="Schließen">Schließen</CrossIcon>
                        }
                      />
                    </ModalHeader>
                  ),
                  // Footer: () => <div>Footer</div>,
                }}
                onClose={handleClose}
              >
                <Grid>
                  <GridColumn medium={12}>
                    <StoryCard>
                      <StoryProgress>
                        <ProgressTracker
                          style={{ flexGrow: 1 }}
                          items={mapStatusToTracker(
                            data.issue ? data.issue.status.name : ""
                          )}
                        />
                      </StoryProgress>
                      <h1>{__.get(data, "issue.summary")}</h1>
                      <Markdown
                        className="story--md"
                        source={__.get(data, "issue.description")}
                      />
                    </StoryCard>
                  </GridColumn>

                  {config.REACT_APP_ENABLE_ISSUE_COMMENTS &&
                  <>
                    <AuthConsumer>
                      {({ isCustomer }) => {
                        return (
                          !isCustomer() && (
                            <GridColumn medium={12}>
                              <h3>{props.t("issue.attachment.attachments")}</h3>
                              <ul>
                                {data.issue.attachments
                                  .sort((a, b) =>
                                    a.filename.localeCompare(b.filename)
                                  )
                                  .map((attachment) => (
                                    <li key={attachment.id}>
                                      <a
                                        href="/#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          downloadAttachment({
                                            variables: {
                                              attachmentId: attachment.id,
                                            },
                                          });
                                        }}
                                      >{`${attachment.filename} (${Math.round(
                                        attachment.size / 1024
                                      )}Kb)`}</a>
                                    </li>
                                  ))}
                              </ul>
                            </GridColumn>
                          )
                        );
                      }}
                    </AuthConsumer>
                    <GridColumn>
                      <Mutation<AddCommentMutation, AddCommentMutationVariables>
                        mutation={ADD_ISSUE_COMMENT_MUTATION}
                      >
                        {(addComment) => (
                          <Comments
                            comments={data.issue.comments}
                            handleSubmit={(comment) =>
                              postComment(addComment, refetch, comment)
                            }
                          />
                        )}
                      </Mutation>
                    </GridColumn>
                  </>}
                  {/* <GridColumn medium={4}>
                  <StoryDetails>

                  </StoryDetails>
                </GridColumn> */}
                </Grid>
              </ModalDialog>
            );
          }}
        </Query>
      )}
    </ModalTransition>
    // <Query query={ISSUE_QUERY} variables={{ key: props.key }}>
    // {({ data, loading, error, refetch }) => (
    //   <React.Fragment>
    //     <h1>{t("page.IssueDetail.header.title")}</h1>
    //     {loading &&
    //       <React.Fragment>
    //         <Spinner /> {t("page.IssueDetail.loading.message")}
    //       </React.Fragment>
    //     }
    //   </React.Fragment>
    // )}
    // </Query>
  );
};

export default withTranslation()(IssueDetail);
