import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, ButtonProps, MenuItem, Popover } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from "react-i18next";
import { SORT_BY_OPTIONS, SortType } from "../../@types/sorting";

interface ISortByButtonProps extends ButtonProps {
  sortBy: SortType;
  setSortBy: (value: SortType) => void;
}

const SortByButton: FC<ISortByButtonProps> = ({
  setSortBy,
  sortBy,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleSortBy = useCallback((value: string) => {
    setSortBy(value);
    handleClose();
  }, [handleClose, setSortBy]);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const renderLabel = useMemo(() => {
    return `${t("global.sort_by.title")}: ${t(`global.sort_by.${sortBy}`)}`;
  }, [t, sortBy]);

  return (
    <>
      <Button
        {...rest}
        variant="outlined"
        disableRipple
        style={{
          color: 'rgb(52, 69, 99)',
          transition: 'all 0.3s linear',
          width: i18n.language === 'de' ? '430px' : '300px'
        }}
        onClick={handleOpen}
        endIcon={open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
      >
        {renderLabel}
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option}
            selected={option === sortBy}
            onClick={() => handleSortBy(option)}
            style={{
              color: 'rgb(52, 69, 99)',
              width: i18n.language === 'de' ? '430px' : '300px'
            }}
          >
            {t(`global.sort_by.${option}`)}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
};

export default SortByButton;
