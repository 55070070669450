import * as React from "react";
import { Mutation } from "react-apollo";
import ModalDialog, { ModalFooter } from "@atlaskit/modal-dialog";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import Form, { Field } from "@atlaskit/form";
import { toast } from "react-toastify";
import RECOMMISSION_MUTATION from "../../api/graphql/mutation/recommission";
import { Recommission } from "../../api/graphql/mutation/types/Recommission";
import { SprintsAndIssues_issues as Issue } from "../../api/graphql/query/types/SprintsAndIssues";
import StoryList from "./StoryList";

const DEFAULT_SPEUR = 582;
const DEFAULT_NUMBER = "999";
const DEFAULT_VERSION = "1.0";

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

interface FormProps {
  onSubmit: (e: React.FormEvent | any) => void;
  ref: React.RefObject<HTMLFormElement>;
  onKeyDown: (e: React.KeyboardEvent) => void;
}

interface FieldProps {
  id: string;
  isRequired: boolean;
  isDisabled: boolean;
  isInvalid: boolean;
  onChange: (e: any) => any;
  onBlur: () => any;
  onFocus: () => any;
  value: any;
  "aria-invalid": "true" | "false";
  "aria-labelledby": string;
}

interface IGenerateRecommissionState {
  isGeneratingRecommission: boolean;
}

interface IGenerateRecommissionProps {
  isOpen: boolean;
  projectKey: string;
  sprintName: string;
  done: () => void;
  close: () => void;
}

export default class GenerateRecommission extends React.Component<
  IGenerateRecommissionProps,
  IGenerateRecommissionState
> {
  private selected: Issue[] = [];
  constructor(props: IGenerateRecommissionProps) {
    super(props);
    this.state = {
      isGeneratingRecommission: false,
    };
  }

  handleRecommission = (
    mutation: Function,
    projectKey: string,
    sprintName: string,
    number: Number,
    version: string,
    spSatz: Number,
    storyKeys: string[]
  ) => {
    this.setState({ isGeneratingRecommission: true });
    console.log("Handle Recommission");
    mutation({
      variables: {
        projectKey,
        sprintName,
        number,
        version,
        spEur: spSatz,
        storyKeys,
      },
    });
  };

  generateRecommissionHandler = (
    mutation: Function,
    projectKey: string,
    sprintName: string,
    number: Number,
    version: string,
    spSatz: Number,
    storyKeys: string[]
  ) => {
    console.log("in Generate recommission");
    return this.handleRecommission(
      mutation,
      projectKey,
      sprintName,
      number,
      version,
      spSatz,
      storyKeys
    );
  };

  handleRecommissionComplete = (response: Recommission) => {
    console.log("handleRecommissionComplete");
    setTimeout(async () => {
      if (response.recommission) {
        const url = `data:${response.recommission.contentType};base64,${response.recommission.base64data}`;
        const internal = await fetch(url);
        const blob = await internal.blob();

        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = response.recommission.filename || "download.docx";
        a.click();
        URL.revokeObjectURL(a.href);
      }

      this.props.done();
    }, 300);
  };

  handleRecommissionError = () => {
    toast.error(
      "Das angeforderte Neubeauftragungsdokument konnte nicht erstellt werden."
    );
    this.props.done();
  };

  public render() {
    return (
      <Mutation
        mutation={RECOMMISSION_MUTATION}
        onCompleted={this.handleRecommissionComplete}
        onError={this.handleRecommissionError}
      >
        {(recommission: Function) => (
          <>
            {this.props.isOpen && (
              <ModalDialog
                heading="Neubeauftragung generieren"
                onClose={() => {
                  this.props.close();
                  this.props.done();
                }}
                components={{
                  Container: ({ children, className }: ContainerProps) => (
                    <Form
                      onSubmit={(data: any) => {
                        this.generateRecommissionHandler(
                          recommission,
                          this.props.projectKey,
                          this.props.sprintName,
                          data.number,
                          data.version,
                          Number.parseFloat(data.spSatz),
                          this.selected.map((s) => s.key)
                        );
                        this.props.close();
                      }}
                    >
                      {({ formProps }: { formProps: FormProps }) => (
                        <form {...formProps} className={className}>
                          {children}
                        </form>
                      )}
                    </Form>
                  ),
                  Footer: () => (
                    <ModalFooter>
                      <span />
                      <Button appearance="primary" type="submit">
                        Übernehmen
                      </Button>
                    </ModalFooter>
                  ),
                }}
              >
                <p>Bitte gib die Basisdaten des Angebotsdokument ein:</p>

                <Field
                  label="Version"
                  name="version"
                  defaultValue={DEFAULT_VERSION}
                  isRequired
                >
                  {({ fieldProps }: { fieldProps: FieldProps }) => (
                    <Textfield placeholder={DEFAULT_VERSION} {...fieldProps} />
                  )}
                </Field>

                <Field
                  label="Nummer"
                  name="number"
                  defaultValue={DEFAULT_NUMBER}
                  isRequired
                >
                  {({ fieldProps }: { fieldProps: FieldProps }) => (
                    <Textfield placeholder={DEFAULT_NUMBER} {...fieldProps} />
                  )}
                </Field>

                <Field
                  label="SP Satz in €"
                  name="spSatz"
                  defaultValue={DEFAULT_SPEUR}
                  isRequired
                >
                  {({ fieldProps }: { fieldProps: FieldProps }) => (
                    <Textfield placeholder={DEFAULT_SPEUR} {...fieldProps} />
                  )}
                </Field>

                <StoryList
                  projectKey={this.props.projectKey}
                  sprintNames={[this.props.sprintName]}
                  updateSelected={(stories: Issue[]) => {
                    this.selected = stories;
                  }}
                />
              </ModalDialog>
            )}
          </>
        )}
      </Mutation>
    );
  }
}
