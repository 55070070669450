import { Grid, makeStyles, Theme } from "@material-ui/core"
import React, { Dispatch, FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import "react-circular-progressbar/dist/styles.css"
import { useTranslation } from "react-i18next"
import KeyHandler, { KEYPRESS } from "react-key-handler"
import { FilterButton } from "../../../parts/FilterButton"
import SortByButton from "../../../parts/SortByButton"
import { SprintItem } from "../../../parts/SprintItem"
import { SprintsHeader } from "../../../parts/SprintsHeader"
import { useSprints } from "./context/SprintsContext"
import { useSelectedSprintName } from "./hooks/use-selected-sprint-name"
import { useSprintFilter } from "./hooks/use-sprint-filter"

type SprintType = "future" | "completed" | "nonStandard" | "backlog"

const useStyles = makeStyles((theme: Theme) => ({
  buttonBox: {
    paddingBottom: "16px",
  },
}))

interface ISprintsContentProps {
  history: any
  initialSprint?: string | null
}

const SprintsContent: FC<ISprintsContentProps> = props => {
  const { t } = useTranslation()
  const { history } = props
  const classes = useStyles()
  const { projectName, loading, setProjectKey, sprints, showBacklog, searchEl, sortBy, setSortBy, hasSupportTicket, hasSupportContract } = useSprints()

  const projectKey = useMemo(() => {
    // todo: when we update the react-router, then change this accordingly
    const splitString = history.location.pathname.split("/")
    return splitString[2]
  }, [history])
  useEffect(() => setProjectKey(projectKey), [setProjectKey, projectKey])

  const sprintKey = useMemo(() => {
    let urlSprintName
    const paths = history.location.pathname.split("/")
    if (paths.length === 5) {
      urlSprintName = decodeURIComponent(paths[4])
    }
    return urlSprintName
  }, [history])

  const [completedSprints, setCompletedSprints] = useState<boolean>(true)
  const [futureSprints, setFutureSprints] = useState<boolean>(true)
  const [nonStandardSprints, setNonStandardSprints] = useState<boolean>(true)
  const [backlog, setBacklog] = useState<boolean>(true)
  const searchSprints = useSprintFilter({
    backlog,
    completedSprints,
    futureSprints,
    nonStandardSprints,
  })

  const handleFKeyPress = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation()
      e.preventDefault()
      if (searchEl.current) {
        searchEl.current.focus()
      }
    },
    [searchEl]
  )

  const handleEscapeKeyPress = useCallback(() => {
    if (searchEl.current) {
      searchEl.current.blur()
    }
  }, [searchEl])

  const handleToggleSprints = useCallback(
    (sprintType: SprintType) => () => {
      const setters: Record<SprintType, Dispatch<React.SetStateAction<boolean>>> = {
        future: setFutureSprints,
        backlog: setBacklog,
        nonStandard: setNonStandardSprints,
        completed: setCompletedSprints,
      }
      setters[sprintType](value => !value)
    },
    [setCompletedSprints, setFutureSprints, setNonStandardSprints, setBacklog]
  )

  const selectedSprint = useSelectedSprintName(
    sprintKey,
    sprints.map(s => s.name)
  )

  const handleOpenSprintChange = (sprintName: string) => (opened: boolean) => {
    if (opened) {
      window.history.replaceState(
        sprintName,
        "",
        `/projects/${projectKey}/sprints/` + encodeURIComponent(sprintName)
      )
    }
  }

  const selectedSprintRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    selectedSprintRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
  }, [selectedSprint])

  return (
    <>
      <SprintsHeader history={history} />
      <KeyHandler keyEventName={KEYPRESS} keyValue="f" onKeyHandle={handleFKeyPress} />
      <KeyHandler keyEventName={KEYPRESS} keyValue="Escape" onKeyHandle={handleEscapeKeyPress} />

      {!loading && (
        <Grid container style={{ paddingBottom: "8px" }} spacing={2}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            className={classes.buttonBox}
            spacing={2}
          >
            <Grid item>
              <SortByButton sortBy={sortBy} setSortBy={setSortBy} />
            </Grid>
          </Grid>
          <Grid item>
            <FilterButton
              tooltipTitle={t("page.sprints.filters.completed_sprints_tooltip")}
              label={t("page.sprints.filters.completed_sprints_label")}
              isActive={completedSprints}
              clickFunction={handleToggleSprints("completed")}
            />
          </Grid>
          <Grid item>
            <FilterButton
              tooltipTitle={t("page.sprints.filters.future_sprints_tooltip")}
              label={t("page.sprints.filters.future_sprints_label")}
              isActive={futureSprints}
              clickFunction={handleToggleSprints("future")}
            />
          </Grid>
          <Grid item>
            <FilterButton
              tooltipTitle={t("page.sprints.filters.non_standard_sprints_tooltip")}
              label={t("page.sprints.filters.non_standard_sprints_label")}
              isActive={nonStandardSprints}
              clickFunction={handleToggleSprints("nonStandard")}
            />
          </Grid>
          {showBacklog && (
            <Grid item>
              <FilterButton
                tooltipTitle={t("page.sprints.filters.backlog_tooltip")}
                label={t("page.sprints.filters.backlog_label")}
                isActive={backlog}
                clickFunction={handleToggleSprints("backlog")}
              />
            </Grid>
          )}
        </Grid>
      )}

      {!loading &&
        searchSprints.length > 0 &&
        searchSprints.map(sprint => (
          <SprintItem
            key={sprint.id}
            projectKey={projectKey}
            projectName={projectName ?? ""}
            sprint={sprint}
            history={history}
            enableQuickActions={true}
            isBacklog={sprint.isBacklog}
            initialIsOpen={sprint.name === selectedSprint}
            innerRef={sprint.name === selectedSprint ? selectedSprintRef : undefined}
            onIsOpenChange={handleOpenSprintChange(sprint.name)}
            hasSupportTicket={hasSupportTicket}
            hasSupportContract={hasSupportContract}
          />
        ))}
    </>
  )
}

export default SprintsContent
