import * as React from "react"
import i18next from "i18next";
import { Mutation } from "react-apollo"
import Button from "@atlaskit/button"
import Form, { Field } from "@atlaskit/form"
import ModalDialog, { ModalFooter } from "@atlaskit/modal-dialog"
import Select from "@atlaskit/select"
import Textfield from "@atlaskit/textfield"
import { toast } from "react-toastify"
import { LanguageCode } from "../../api/graphql/graphql-global-types"
import OFFER_MUTATION from "../../api/graphql/mutation/offer"
import { Offer } from "../../api/graphql/mutation/types/Offer"
import { DEFAULT_LANG, LANGUAGES } from "../../languages/language-manager"

const DEFAULT_SPEUR = 582

interface ContainerProps {
  children: React.ReactNode
  className?: string
}

interface FormProps {
  onSubmit: (e: React.FormEvent | any) => void
  ref: React.RefObject<HTMLFormElement>
  onKeyDown: (e: React.KeyboardEvent) => void
}

interface FieldProps {
  id: string
  isRequired: boolean
  isDisabled: boolean
  isInvalid: boolean
  onChange: (e: any) => any
  onBlur: () => any
  onFocus: () => any
  value: any
  "aria-invalid": "true" | "false"
  "aria-labelledby": string
}

interface IGenerateOfferState {
  isGeneratingOffer: boolean
}

interface IGenerateOfferProps {
  isOpen: boolean
  projectKey: string
  sprintName: string
  done: () => void
  close: () => void
  t: i18next.TFunction
}

export default class GenerateOffer extends React.Component<IGenerateOfferProps,
  IGenerateOfferState> {
  constructor(props: IGenerateOfferProps) {
    super(props)
    this.state = {
      isGeneratingOffer: false,
    }
  }

  handleOffer = (
    mutation: Function,
    projectKey: string,
    sprintName: string,
    number: Number,
    version: string,
    spSatz: Number,
    lang: LanguageCode,
    nonFunctionalRequirements: string,
  ) => {
    this.setState({isGeneratingOffer: true})
    console.log("Handle Offer")
    mutation({
      variables: {
        projectKey,
        sprintName,
        number,
        version,
        spEur: spSatz,
        lang,
        nonFunctionalRequirements
      },
    })
  }

  generateOfferHandler = (
    mutation: Function,
    projectKey: string,
    sprintName: string,
    number: Number,
    version: string,
    spSatz: Number,
    lang: LanguageCode,
    nonFunctionalRequirements: string,
  ) => {
    console.log("in Generate offer")
    return this.handleOffer(mutation, projectKey, sprintName, number, version, spSatz, lang, nonFunctionalRequirements)
  }

  handleOfferComplete = (response: Offer) => {
    console.log("handleOfferComplete")
    setTimeout(async () => {
      if (response.offer) {
        const url = `data:${response.offer.contentType};base64,${response.offer.base64data}`
        const internal = await fetch(url)
        const blob = await internal.blob()

        const a = document.createElement("a")
        a.href = URL.createObjectURL(blob)
        a.download = response.offer.filename || "download.docx"
        a.click()
        URL.revokeObjectURL(a.href)
      }

      this.props.done()
    }, 300)
  }

  handleOfferError = () => {
    const {t} = this.props;
    toast.error(t("page.sprints.general_offer.toast_error"))
    this.props.done()
  }

  public render() {
    const {t} = this.props;

    return (
      <Mutation
        mutation={OFFER_MUTATION}
        onCompleted={this.handleOfferComplete}
        onError={this.handleOfferError}
      >
        {(offer: Function) => (
          <>
            {this.props.isOpen && (
              <ModalDialog
                heading={t("page.sprints.general_offer.heading")}
                onClose={() => {
                  this.props.close()
                  this.props.done()
                }}
                components={{
                  Container: ({children, className}: ContainerProps) => (
                    <Form
                      onSubmit={(data: any) => {
                        this.generateOfferHandler(
                          offer,
                          this.props.projectKey,
                          this.props.sprintName,
                          data.number,
                          data.version,
                          Number(data.spSatz),
                          data.lang.value,
                          data.requirements
                        )
                        this.props.close()
                      }}
                    >
                      {({formProps}: { formProps: FormProps }) => (
                        <form {...formProps} className={className}>
                          {children}
                        </form>
                      )}
                    </Form>
                  ),
                  Footer: () => (
                    <ModalFooter>
                      <span/>
                      <Button appearance="primary" type="submit">
                        {t("page.sprints.general_offer.footer_btn")}
                      </Button>
                    </ModalFooter>
                  ),
                }}
              >
                <p>{t("page.sprints.general_offer.enter_data")}</p>
                <Field label={t("page.sprints.general_offer.version")} name="version" defaultValue="1.0" isRequired>
                  {({fieldProps}: { fieldProps: FieldProps }) => (
                    <Textfield placeholder="1.0" {...fieldProps} />
                  )}
                </Field>
                <Field label={t("page.sprints.general_offer.number")} name="number" defaultValue="999" isRequired>
                  {({fieldProps}: { fieldProps: FieldProps }) => (
                    <Textfield placeholder="999" {...fieldProps} />
                  )}
                </Field>
                <Field label={t("page.sprints.general_offer.sp_rate")} name="spSatz" defaultValue={DEFAULT_SPEUR} isRequired>
                  {({fieldProps}: { fieldProps: FieldProps }) => (
                    <Textfield placeholder={DEFAULT_SPEUR} {...fieldProps} />
                  )}
                </Field>
                <Field
                  label={t("page.sprints.general_offer.requirements")}
                  name="requirements"
                >
                  {({fieldProps}: { fieldProps: FieldProps }) => (
                    <Textfield placeholder={t("page.sprints.general_offer.requirements")} {...fieldProps} />
                  )}
                </Field>
                <Field
                  label={t("page.sprints.general_offer.language")}
                  name="lang"
                  isRequired
                  defaultValue={{
                    label: DEFAULT_LANG.name,
                    value: DEFAULT_LANG.code,
                  }}
                >
                  {({fieldProps}: { fieldProps: FieldProps }) => (
                    <>
                      <Select
                        {...fieldProps}
                        placeholder={DEFAULT_LANG.name}
                        options={Object.values(LANGUAGES).map(lang => ({
                          label: lang.name,
                          value: lang.code,
                        }))}
                      ></Select>
                      <div style={{height: 100}}></div>
                      {/* Space for select options */}
                    </>
                  )}
                </Field>
              </ModalDialog>
            )}
          </>
        )}
      </Mutation>
    )
  }
}
