import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import _ from 'lodash';
import { DataGrid, GridCellParams, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { EditButton } from './EditButton';
import { CustomerMetadataCell } from './CustomerMetadataCell';
import { CustomersContext } from '../../../../context/CustomersContext';
import { CustomerDashboardCell } from './CustomerDashboardCell';
import { useTranslation } from 'react-i18next';
import KeyHandler, { KEYPRESS, KEYUP } from 'react-key-handler';
import { HelperText } from '../../../styled/HelperText';
import { match } from 'fuzzyjs';


interface ICustomersPageTableProps {
}

export const CustomersPageTable: FunctionComponent<ICustomersPageTableProps> = (props) => {
  const { t } = useTranslation();
  const { rows } = useContext(CustomersContext);
  const [searchInput, setSearchInput] = useState<HTMLInputElement | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [filteredRows, setFilteredRows] = useState<GridRowsProp[]>(rows);

  const columns: GridColDef[] = [
    {
      field: 'projectName',
      headerName: t('page.customer.table.headers.project_name'),
      flex: 1,
    },
    {
      field: 'customerData',
      headerName: t('page.customer.table.headers.customer_data'),
      flex: 1,
      sortable: false,
      renderCell: (data: GridCellParams) => {
        if (data.row.customerData === null || data.row.customerData === undefined) return <></>;
        return (
          <CustomerMetadataCell customerData={data.row.customerData}/>
        );
      },
    },
    {
      field: 'dashboard',
      headerName: t('page.customer.table.headers.dashboard'),
      flex: 1,
      sortable: false,
      renderCell: (data: GridCellParams) => {
        return <CustomerDashboardCell dashboardData={data.row.dashboard}/>;
      },
    }, {
      field: 'actions',
      headerName: t('page.customer.table.headers.actions'),
      flex: 1,
      renderCell: (data: GridCellParams) => {
        if (data.row.customerData === null || data.row.customerData === undefined) {
          return (
            <EditButton jiraKey={data.row.id} projectName={data.row.projectName}/>
          );
        }
        return (
          <EditButton
            jiraKey={data.row.id}
            projectName={data.row.customerData.projectName}
            customerData={data.row.customerData}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const fuzzy = (rows || [])
      .map(row => {
        const result = match(search ?? '', `${row['id']} ${row['projectName']}`, { withScore: true });
        return { ...row, ...result };
      })
      .filter(row => row.match);

    setFilteredRows(_.sortBy(fuzzy, ['projectName']));
  }, [rows, search, setFilteredRows]);

  const handleSearchKey = useCallback((row: GridRowsProp | undefined) => (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setSearch(null);
    }
  }, [setSearch]);

  return (
    <>
      <div className="search-input">
        <input
          value={search ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
          }}
          onKeyUp={handleSearchKey(filteredRows && filteredRows.length > 0 ? filteredRows[0] : undefined)}
          placeholder={t('page.customer.search')}
          ref={c => (setSearchInput(c))}
          style={{
            width: 'calc(100% - 30px)',
            padding: '10px 15px',
            backgroundColor: '#253858',
            border: 'none',
            borderRadius: '6px',
            marginBottom: '15px',
            color: '#fff',
            fontSize: '1.5em',
            fontWeight: 'lighter',
          }}
        />
        <span>{t('page.customer.filter')}</span>
        <KeyHandler
          keyEventName={KEYPRESS}
          keyValue="f"
          onKeyHandle={(event: KeyboardEvent) => {
            event.stopPropagation();
            event.preventDefault();
            searchInput && searchInput.focus();
          }}
        />
        <KeyHandler
          keyEventName={KEYUP}
          code="Escape"
          onKeyHandle={() => {
            setSearch(null);
          }}
        />
        <HelperText className="helper-text">
          {t('page.customer.esc')}<br/>
          <div className="only-active">{t('page.projects.enter')}</div>
        </HelperText>
      </div>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          rowHeight={120}
          pageSize={50}
          pagination
        />
      </div>
    </>
  );
};
