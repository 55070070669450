import { TextField, TextFieldProps } from "@material-ui/core"
import { useFormikContext } from "formik"
import { isString } from "lodash"
import React, { ChangeEvent, FC } from "react"

type IFormikTextFieldProps = TextFieldProps & {
  valueName: string
  clearable?: boolean
  onChange?: (e: ChangeEvent<any>) => void
  hide?: boolean
}

export const FormikTextField: FC<IFormikTextFieldProps> = ({
  valueName,
  clearable,
  onChange,
  hide,
  ...rest
}) => {
  const { getFieldProps, getFieldMeta, handleChange, handleBlur } = useFormikContext()
  const field = getFieldProps(valueName)
  const fieldMeta = getFieldMeta(valueName)

  const handleChangeProxy = (e: ChangeEvent<any>) => {
    handleChange(e)
    onChange && onChange(e)
  }

  return (
    <TextField
      {...rest}
      fullWidth
      name={valueName}
      value={field.value ?? ""}
      error={fieldMeta.touched && isString(fieldMeta.error)}
      helperText={fieldMeta.touched && isString(fieldMeta.error) && fieldMeta.error}
      onChange={handleChangeProxy}
      onBlur={handleBlur}
    />
  )
}
