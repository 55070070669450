import { gql } from "apollo-boost"
import { Mutation } from "react-apollo"
import { StoryCards, StoryCardsVariables } from './types/StoryCards';

export default gql`
mutation StoryCards($issueKeys: [String!]!) {
  storyCards(issueKeys: $issueKeys) {
    base64data
    contentType
    filename
  }
}`

export class StoryCardsMutation extends Mutation<StoryCards, StoryCardsVariables> { }
