import React, { FunctionComponent } from "react"
import { Query } from "react-apollo"
import {
  CircularProgress,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import AVERAGE_VELOCITY_QUERY, { IAverageVelocityResult } from "../../../../api/graphql/query/average-velocity";
import { useTranslation } from "react-i18next";
import { getLastSprintNames } from "../../../../utils/last-sprints";

const useStyles = makeStyles(theme => ({
  table: {
    header: "500px",
    minWidth: "300px",
    paddingTop: theme.spacing(2),
  },
  header: {
    align: "left"
  },
}))

const numberOfPastSprints = [3, 6, 26];

interface ISprintVelocityProps {
}

export const SprintVelocity: FunctionComponent<ISprintVelocityProps> = props => {
  const {t} = useTranslation()
  const classes = useStyles()

  const headers = [
    {
      label: t("page.dashboard.sprint_velocity.table.headers.number_of_sprints"),
      id: "number_of_sprints",
    },
    {
      label: t("page.dashboard.sprint_velocity.table.headers.average_velocity"),
      id: "average_velocity",
    }
  ];

  return (
    <Grid container spacing={0} >
      <TableContainer style={{maxHeight: "100%", overflowY: "auto"}}>
        <Table className={classes.table} aria-label="sprint velocity table">
          <TableHead>
            <TableRow>
              {headers.map((column, idx: number) => (
                <TableCell key={`${column.id}-${idx}`} className={classes.header}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {numberOfPastSprints.map((numberOfSprints: number) => {
              const variables = {
                sprints: getLastSprintNames(numberOfSprints)
              };

              return (
                <Query query={AVERAGE_VELOCITY_QUERY} variables={variables} fetchPolicy="cache-first"
                       notifyOnNetworkStatusChange={true} key={`query-${numberOfSprints}`}>
                  {({data, loading, error, refetch, networkStatus}: IAverageVelocityResult) => {
                    return (
                      <>
                        {loading && (
                          <TableRow key={`${numberOfSprints}-sprints-loading`}>
                            <TableCell key={`${numberOfSprints}-cell-number-of-sprints`}>
                              {numberOfSprints}
                            </TableCell>
                            <TableCell key={`${numberOfSprints}-cell-sprint-velocity`}>
                              <CircularProgress/>
                            </TableCell>
                          </TableRow>
                        )}
                        {error && (
                          <TableRow key={`${numberOfSprints}-sprints-error`}>
                            <TableCell key={`${numberOfSprints}-cell-number-of-sprints`}>
                              {numberOfSprints}
                            </TableCell>
                            <TableCell key={`${numberOfSprints}-cell-sprint-velocity`}>
                              {t("page.dashboard.sprint_velocity.table.error")}
                            </TableCell>
                          </TableRow>
                        )}
                        {!loading && (
                          <TableRow key={`${numberOfSprints}-sprints`}>
                            <TableCell key={`${numberOfSprints}-cell-number-of-sprints`}>
                              {numberOfSprints}
                            </TableCell>
                            <TableCell key={`${numberOfSprints}-cell-sprint-velocity`}>
                              {`${data?.averageVelocity.toFixed(2)} SP`}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )
                  }}
                </Query>
              );
            })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>

  );
}
