import React, { FC, Fragment } from "react"
import debounceRender from "react-debounce-render"
import styled from "styled-components"
import { Query, QueryResult } from "react-apollo"
import { issuesForSprint as ISSUES_QUERY } from "../../api/graphql/query/issues"
import { Roadmap_sprints } from "../../api/graphql/query/types/Roadmap"
import { useTranslation } from "react-i18next"
import { AuthConsumer } from "../../context/AuthContext"
import { IssueItem } from "./IssueItem"
import { Issues_issues } from "../../api/graphql/query/types/Issues"
import { Box, CircularProgress, Grid } from "@material-ui/core"

const Issues = styled.div`
  padding-left: 65px;
  padding-right: 25px;
`

interface IIssueListProps {
  history: any
  projectKey: string
  projectName: string
  sprint: Roadmap_sprints
  setSPSum: React.Dispatch<React.SetStateAction<number>>
  isBacklog?: boolean
  hasSupportTicket?: boolean
  hasSupportContract?: boolean
}

const IssueList: FC<IIssueListProps> = ({
  projectKey,
  projectName,
  sprint,
  setSPSum,
  history,
  isBacklog,
  hasSupportTicket = false,
  hasSupportContract= false
}) => {
  const { t } = useTranslation()

  return (
    <Query
      query={ISSUES_QUERY}
      variables={{ projectKey: projectKey, sprintName: sprint.name, isBacklog }}
      fetchPolicy="cache-first"
    >
      {({ data, loading, error, refetch }: QueryResult) => {
        let sPSum = 0
        return (
          <Issues>
            {data &&
              data.issues &&
              data.issues.map((issue: Issues_issues) => (
                <AuthConsumer key={issue.id}>
                  {({ isCustomer, isJiraUser }) => {
                    if (issue.SP && issue.SP !== "❓" && issue.SP !== "-") {
                      sPSum += Number(issue.SP)
                    } else if (
                      issue.SPEstimate &&
                      issue.SPEstimate !== "❓" &&
                      issue.SPEstimate !== "-"
                    ) {
                      sPSum += Number(issue.SPEstimate)
                    }
                    setSPSum(sPSum)

                    if (issue.denovo_care_visibility === false && isCustomer()) {
                      return <Fragment key={issue.key}></Fragment>
                    } else {
                      return (
                        <IssueItem
                          key={issue.key}
                          issue={issue}
                          projectKey={projectKey}
                          projectName={projectName}
                          history={history}
                          hasSupportTicket={hasSupportTicket}
                          hasSupportContract={hasSupportContract}
                        />
                      )
                    }
                  }}
                </AuthConsumer>
              ))}
            {loading && (
              <Grid container justifyContent="center" alignContent="center">
                <Grid item>
                  <Box sx={{ py: 6, mr: 1 }}>
                    <CircularProgress size={20} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ py: 6 }}>{t("issue.issue_loading")}</Box>
                </Grid>
              </Grid>
            )}
            {data && data.issues && data.issues.length < 1 && (
              <div style={{ marginBottom: "2em" }}>{t("issue.no_tickets")}</div>
            )}
          </Issues>
        )
      }}
    </Query>
  )
}

export default debounceRender(IssueList, 300, { leading: false })
