export const downloadBase64 = async (
  base64: string,
  mimeType: string,
  filename: string
) => {
  const url = `data:${mimeType};base64,${base64}`;
  const internal = await fetch(url);
  const blob = await internal.blob();

  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.click();
  URL.revokeObjectURL(a.href);
};
