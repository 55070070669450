import React, { FC } from "react";
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog"
import { JiraSupportForm } from "./form/JiraSupportForm";
import { JiraSupportContextProvider } from "./form/context/JiraSupportContext";

interface IJiraSupportTicketModalProps {
  projectKey: string;
  projectName: string;
  showModal: boolean;
  closeModal: () => void;
  parentIssueKey?: string;
}

export const JiraSupportTicketModal: FC<IJiraSupportTicketModalProps> = (props) => {
  const { projectKey, projectName, showModal, closeModal, parentIssueKey } = props;

  return (
    <ModalTransition>
      {showModal && (
        <ModalDialog width="medium" onClose={closeModal}>
          <JiraSupportContextProvider projectKey={projectKey} projectName={projectName} closeModal={closeModal} parentIssueKey={parentIssueKey}>
            <JiraSupportForm/>
          </JiraSupportContextProvider>
        </ModalDialog>
      )}
    </ModalTransition>
  )
}
