import { gql } from "apollo-boost";

export default gql`
    mutation CreateOrEditCustomerMetadata($createCustomerMetadata: CreateCustomerMetadata!) {
        createOrEditCustomerMetadata(createCustomerMetadata: $createCustomerMetadata) {
            jiraProjectKey
            projectName
            companyName
            companyType
            salutation
            lastname
            firstname
            streetName
            postalCode
            streetNumber
            city
            country
            vatId
            dashboardName
            dashboardColor
            hasSupportContract
            _id
            createdAt
            updatedAt
        }
    }
`