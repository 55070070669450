import React, { FunctionComponent, Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { DashboardPanel } from "./components/DashboardPanel/DashboardPanel"
import { VacationForecast } from "./panels/VacationForecast"
import { SprintVelocity } from "./panels/SprintVelocity"
import { BurnedStoryPoints } from "./panels/BurnedStoryPoints"
import { SprintPreview } from "./panels/SprintPreview"

const DEFAULT_TAB = 0;

const useTabItems = () => {
  const { t } = useTranslation()
  return [
    {
      handle: "vacation_forecast",
      tabTitle: t("page.dashboard.tab_titles.vacation_forecast"),
      component: VacationForecast,
    },
    {
      handle: "sprint_velocity",
      tabTitle: t("page.dashboard.tab_titles.sprint_velocity"),
      component: SprintVelocity,
    },
    {
      handle: "burned_story_points",
      tabTitle: t("page.dashboard.tab_titles.burned_story_points"),
      component: BurnedStoryPoints,
    },    
    {
      handle: "sprint_preview",
      tabTitle: t("page.dashboard.tab_titles.sprint_preview"),
      component: SprintPreview,
    },
  ]
}

interface IDashboardPageProps {}

export const DashboardPage: FunctionComponent<IDashboardPageProps> = props => {
  const tabItems = useTabItems()
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB)

  const handleSelectedTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue)
  }

  return (
    <Fragment>
      <Tabs
        value={selectedTab}
        onChange={handleSelectedTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabItems.map((item, index) => (
          <Tab key={index} label={item.tabTitle} />
        ))}
      </Tabs>
      {tabItems.map((item, index) => {
        const TabComponent = item.component
        return (
          <DashboardPanel value={selectedTab} key={index} index={index}>
            <TabComponent />
          </DashboardPanel>
        )
      })}
    </Fragment>
  )
}
