import __ from "lodash"
import moment from "moment-timezone"

export const addSprints = (sprintKey: string, additionalSprints: number): string => {
  const matches = /([1-9]{1}[0-9]{3})\/([0-9]{2})/ig.exec(sprintKey)
  if (matches && matches.length === 3) {
    const year = parseInt(matches[1])
    let sprintNum = parseInt(matches[2]) + additionalSprints

    return `${year + Math.floor((sprintNum - 1) / 26)}/${__.padStart(`${(sprintNum > 0) ? ((sprintNum - 1) % 26 + 1) : (26 + (sprintNum % 26))}`, 2, "0")}`
  }
  return sprintKey
}

export const currentSprint = () => {
  const now = moment()
  const sprintNum = Math.ceil(now.week() / 2) 
  return `${now.year() + (sprintNum === 1 && now.month() !== 1 ? 1 : 0)}/${ __.padStart(`${sprintNum}`, 2, "0")}`
}

export const listSprints = (from?: string, to?: string) => {
  const firstSprint = from || currentSprint()
  const lastSprint = to || currentSprint()
  var selectedSprints = [firstSprint]
  var nextSelectedSprint = addSprints(firstSprint, 1)
  
  while (firstSprint !== lastSprint && nextSelectedSprint !== lastSprint) {
    selectedSprints.push(nextSelectedSprint)
    nextSelectedSprint = addSprints(nextSelectedSprint, 1)
  }
  
  if (firstSprint !== lastSprint && nextSelectedSprint === lastSprint) {
    selectedSprints.push(nextSelectedSprint)
  }

  return selectedSprints
}