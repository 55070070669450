import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../../../context/AuthContext"
import Button from "@atlaskit/button"
import RefreshIcon from "@atlaskit/icon/glyph/refresh"
import { useErpPageContext } from "../context/ERPPageContext"
import Levels from "react-activity/lib/Levels"
import Spinner from "@atlaskit/spinner"

export const ERPPageHeading: FC = () => {
  const { isCustomer } = useAuthContext()
  const { loading, refetch, networkStatus } = useErpPageContext()
  const { t } = useTranslation()

  const loadingMessage = networkStatus === 4 ? t("page.erp.update") : t("page.erp.retrieve")
  const loadingIcon =
    networkStatus === 4 ? (
      <Levels color="#253858" size={16} speed={1} animating={true} />
    ) : (
      <Spinner />
    )
  return (
    <h1>
      {isCustomer() ? t("Ressourcenplanung") : t("ERP")}
      <span style={{ float: "right", fontSize: "14px", fontWeight: "normal" }}>
        <Button
          isDisabled={loading}
          iconAfter={
            loading ? (
              loadingIcon
            ) : (
              <RefreshIcon size="medium" label={t("page.erp.update_planning")} />
            )
          }
          onClick={() => refetch()}
        >
          {loading ? loadingMessage : t("page.erp.refresh")}
        </Button>
      </span>
    </h1>
  )
}
