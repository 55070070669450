import { Grid } from "@material-ui/core"
import React, { FC } from "react"
import "react-activity/lib/Levels/Levels.css"
import "../../../index"
import { IBaseProps } from "../../../utils/BaseProps"
import { ERPPageHeading } from "./components/ERPPageHeading"
import { ErpPageContextProvider } from "./context/ERPPageContext"
import { ERPPageContent } from "./ERPPageContent"

interface IERPProps extends IBaseProps {
  history: any
}

export const ERPPage: FC<IERPProps> = props => {
  return (
    <ErpPageContextProvider>
      <Grid
        container
        direction="column"
        style={{ height: "calc(100vh - 2em)", overflow: "hidden" }}
        justifyContent="center"
        wrap="nowrap"
      >
        <Grid item>
          <ERPPageHeading />
        </Grid>
        <Grid
          item
          xs
          style={{
            overflow: "hidden",
          }}
        >
          <ERPPageContent {...props} />
        </Grid>
      </Grid>
    </ErpPageContextProvider>
  )
}
