import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import Dropdown, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { sPStyle } from '../../utils/sp-style';
import { mapStatusToColor, mapStatusToIcon, mapStatusToText } from '../../api/graphql/query/issue';
import { Issues_issues } from '../../api/graphql/query/types/Issues';
import IssueDetail from './IssueDetail';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import KeyHandler, { KEYPRESS } from "react-key-handler";
import AddCircleIcon from "@atlaskit/icon/glyph/add-circle";
import { JiraSupportTicketModal } from "../pages/project/Sprints/components/modal/JiraSupportTicketModal";

const Issue = styled.div`
  margin-bottom: 20px;
  min-height: 62px;
  position: relative;
  border-radius: 1px;
  background-color: white;
  padding-left: 8px;
  display: flex;
  width: 100%;
  align-items: center;

  &:before {
    content: " ";
    background-color: ${props => props.color || 'transparent'};
    position: absolute;
    left: -4px;
    height: 100%;
    width: 36px;
    z-index: -1;
    border-radius: 3px;
  }
`;

const IssueHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
  flex-grow: 1;
`;

const Spacer = styled.div`
  flex-grow: 1;
`

const IssueIdentifier = styled.div`
  cursor: pointer;
  flex-grow: 1;
`;

const IssueState = styled.div`
  display: flex !important;
  align-items: center !important;
  padding-right: 8px;
`;

const IssueDropDown = styled.div`
  display: flex !important;
  align-items: center !important;
`;

const IssueKey = styled.div`
`;

const IssueSummary = styled.div`
`;

const IssueStatus = styled.div`
  display: inline-block;
  padding: 0 5px;
  margin-left: 4px;
  border-radius: 3px;
  border: 1px solid ${props => props.color || 'rgba(0,0,0,0.3)'};
  color: ${props => props.color || 'rgb(52, 69, 99)'};
`;

const KeyboardButton = styled.div`
  display: inline;
  margin-left: 1em;
  padding: 0.1em 0.3em;
  font-size: 0.7em;
  border: 1px solid rgba(0,0,0,0.5);
  border-radius: 5px;
  opacity: 0.5;
  float: right;
`

interface IIssueItemProps {
  issue: Issues_issues;
  projectKey: string;
  projectName: string;
  history: any;
  hasSupportTicket?: boolean;
  hasSupportContract?: boolean;
}

export const IssueItem: FC<IIssueItemProps> = ({ issue, projectKey, projectName, hasSupportTicket= false, hasSupportContract = false }) => {
  const { t } = useTranslation();
  const sp = sPStyle(issue);
  const [isOpen, setIsOpen] = useState(false);
  const [isJiraOpen, setIsJiraOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);
  const openJiraModal = useCallback(() => setIsJiraOpen(true), [setIsJiraOpen])


  return (
    <>
      <IssueDetail
        onClose={closeModal}
        issueKey={issue.key}
        isOpen={isOpen}
      />

      <JiraSupportTicketModal
        projectKey={projectKey}
        projectName={projectName}
        showModal={isJiraOpen}
        closeModal={() => setIsJiraOpen(false)}
        parentIssueKey={issue.key}
      />

      <Issue color={mapStatusToColor(issue.status.name)}>
        <IssueHeader>
          {!isOpen && (
            <>
              <IssueIdentifier onClick={openModal}>
                <IssueKey>
                  <b>{issue.key}</b> {issue.type.name}
                  <IssueStatus
                    color={mapStatusToColor(issue.status.name)}
                  >
                    {mapStatusToIcon(issue.status.name)} {t(`issue.issue_status.${mapStatusToText(issue.status.name)}`)}
                  </IssueStatus>
                </IssueKey>
                <IssueSummary>
                  {issue.summary}
                </IssueSummary>
              </IssueIdentifier>
              <Spacer />
              <IssueState>
                {sp}
              </IssueState>
              {hasSupportTicket && hasSupportContract && (
                <IssueDropDown>
                  <Dropdown
                    position="bottom right"
                    triggerType="button"
                  >
                    <DropdownItemGroup title={t("issue.support.title")}>
                      <KeyHandler
                        keyEventName={KEYPRESS}
                        keyValue="s"
                        onKeyHandle={openJiraModal}
                      />
                      <DropdownItem isDisabled={false} onClick={openJiraModal} elemBefore={<AddCircleIcon label={t("issue.support.support")} size="small" />}>
                        {t("issue.support.support")}
                        <KeyboardButton>S</KeyboardButton>
                      </DropdownItem>

                    </DropdownItemGroup>
                  </Dropdown>
                </IssueDropDown>
              )}
            </>
          )}
          {isOpen && <CircularProgress />}
        </IssueHeader>
      </Issue>
    </>
  );
};
