import React from "react"

interface IFilterContextState {

}

const defaultState: IFilterContextState = {

}

const FilterContext = React.createContext<IFilterContextState>(defaultState)

class FilterProvider extends React.Component {

    constructor(props: any) {
        super(props)

        this.state = {

        }
    }


    render() {
      return (
        <FilterContext.Provider
            value={{

            }}
        >
          {this.props.children}
        </FilterContext.Provider>
      )
    }
}

const FilterConsumer = FilterContext.Consumer

export { FilterProvider, FilterConsumer }
