import React, { ChangeEvent, FC, useCallback, useState } from "react"
import { Button, Grid, makeStyles } from "@material-ui/core"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Spinner from "@atlaskit/spinner"
import { useSprints } from "../pages/project/Sprints/context/SprintsContext"
import { HelperText } from "../styled/HelperText"
import { ABILITIES, useAuthContext } from "../../context/AuthContext"
import { JiraSupportTicketModal } from "../pages/project/Sprints/components/modal/JiraSupportTicketModal"

const useStyles = makeStyles(theme => ({
  btn: {
    backgroundColor: "white",
    color: "rgb(52, 69, 99)",
    transition: "all 0.3s linear",
  },
}))
const StyledSprintsHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`

const Title = styled.div`
  color: rgb(52, 69, 99);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
`

const Subtitle = styled.div`
  color: #8c96a5;
  font-weight: normal;
  font-size: 14px;
`

export interface ISprintsHeaderProps {
  history: any
}

export const SprintsHeader: FC<ISprintsHeaderProps> = ({ history }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    loading,
    projectKey,
    projectName,
    searchEl,
    search,
    setSearch,
    hasSupportTicket,
    hasSupportContract,
  } = useSprints()
  const { hasAbility } = useAuthContext()
  const [showJiraSupportModal, setShowJiraSupportModal] = useState<boolean>(false)

  const handleSearchKey = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.altKey && e.key === "Backspace") {
        history.push(`/projects`)
      } else if (e.key === "Enter") {
        if (searchEl.current) {
          searchEl.current.blur()
        }
        e.stopPropagation()
        e.preventDefault()
      } else if (e.key === "Escape") {
        setSearch("")
      }
    },
    [history, searchEl, setSearch]
  )

  return (
    <>
      <StyledSprintsHeader>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item container direction="column" xs>
            <Grid item>
              <Title>{!loading ? projectName : t("page.sprints.patience")}</Title>
            </Grid>
            <Grid item>
              <Subtitle>
                {loading && (
                  <>
                    <span style={{ paddingRight: "5px" }}>
                      <Spinner size="small" />
                    </span>
                    {t("page.sprints.loading.message")}
                  </>
                )}
                {!loading && t("page.sprints.header.title")}
              </Subtitle>
            </Grid>
          </Grid>

          {hasSupportTicket && hasSupportContract && (
            <Grid item container xs justifyContent="flex-end">
              <Button
                disabled={false}
                onClick={() => setShowJiraSupportModal(true)}
                variant="outlined"
                className={classes.btn}
              >
                {t("page.sprints.support_btn")}
              </Button>
            </Grid>
          )}
        </Grid>
      </StyledSprintsHeader>
      <div className="search-input">
        <input
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value)
          }}
          onKeyUp={handleSearchKey}
          placeholder={t("page.sprints.input")}
          ref={searchEl}
          style={{
            position: "relative",
            width: "calc(100% - 30px)",
            padding: "10px 15px",
            backgroundColor: "#253858",
            border: "none",
            borderRadius: "6px",
            marginBottom: "15px",
            color: "#fff",
            fontSize: "1.5em",
            fontWeight: "lighter",
          }}
        />
        <span>{t("page.sprints.helperText.filter")}</span>
        <HelperText className="helper-text">
          {hasAbility(ABILITIES.PRODUCT_OWNER) && (
            <>
              {t("page.sprints.helperText.quick_actions")}
              <br />
            </>
          )}
          <div className="only-active">{t("page.sprints.helperText.project_overview")}</div>
        </HelperText>
      </div>

      <JiraSupportTicketModal
        projectKey={projectKey ?? ""}
        projectName={projectName ?? ""}
        showModal={showJiraSupportModal}
        closeModal={() => setShowJiraSupportModal(false)}
      />
    </>
  )
}
