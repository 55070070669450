import React, { ChangeEvent, FunctionComponent, useCallback, useState } from "react"
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import { Query } from "react-apollo";
import VACATION_FORECAST_QUERY, { IVacationForecastResult } from "../../../../api/graphql/query/vacation-forecast"
import { useTranslation } from "react-i18next";
import Spinner from "@atlaskit/spinner";
import { VacationForecastTable } from "./components/VacationForecast/VacationForecastTable";
import { getFutureSprints } from "../../../../utils/future-sprints";
import { Country, DepartmentEnum } from "../../../../api/graphql/graphql-global-types";

const FILTER_HEIGHT = "50px"

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: `calc(100% - ${FILTER_HEIGHT})`
  },
  filter: {
    height: FILTER_HEIGHT,
  },
  countries: {
    minWidth: "200px",
  },
  table: {
    paddingTop: theme.spacing(2),
  }
}));

interface IVacationForecastProps {
}

// For some reason, this enum didn't export from backend correctly in the types folder
enum DepartmentEnumBackend {
  BACKOFFICE = "Backoffice",
  DESIGN = "Design",
  DEVOPS = "DevOps",
  DEVELOPMENT = "Development",
  MARKETING_BUSINESS= "Marketing & Business Development",
  PRODUCT_OWNERS = "Product Owners"
}

export const VacationForecast: FunctionComponent<IVacationForecastProps> = props => {
  const {t} = useTranslation();
  const classes = useStyles();

  const [countries, setCountries] = useState<Country[]>([Country.AUSTRIA]);
  const [departments, setDepartments] = useState<DepartmentEnum[]>([DepartmentEnum.DEVELOPMENT]);

  const handleCountriesChange = useCallback((changeEvent: ChangeEvent<{name?: string, value: unknown}>) => {
    setCountries(changeEvent.target.value as Country[])
  }, [setCountries]);

  const handleDepartmentChange = useCallback((changeEvent: ChangeEvent<{name?: string, value: unknown}>) => {
    setDepartments(changeEvent.target.value as DepartmentEnum[])
  }, [setDepartments]);

  const variables = {
    sprints: getFutureSprints(2),
    countries,
    departments
  };

  return (
    <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} className={classes.filter}>
          <FormControl>
            <InputLabel id="countries-label">
              {t("page.dashboard.vacation_forecast.countries")}
            </InputLabel>
            <Select
              multiple
              labelId="countries-label"
              value={countries}
              onChange={handleCountriesChange}
              className={classes.countries}
            >
              <MenuItem value={Country.AUSTRIA} key={Country.AUSTRIA}>Austria</MenuItem>
              <MenuItem value={Country.HELLAS} key={Country.HELLAS}>Hellas</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.filter}>
          <FormControl>
            <InputLabel id="departments-label">
              {t("page.dashboard.vacation_forecast.departments")}
            </InputLabel>
            <Select
              multiple
              labelId="departments-label"
              value={departments}
              onChange={handleDepartmentChange}
              className={classes.countries}
            >
              {Object.keys(DepartmentEnumBackend).map((value: string, idx: number) => (
                <MenuItem key={`${idx}-${value}`} value={value}>{DepartmentEnumBackend[value]}</MenuItem>
              ))}
            </Select>
          </FormControl>
      </Grid>

      <Grid item xs={12} className={classes.table}>
        <Query
          query={VACATION_FORECAST_QUERY}
          variables={variables}
          fetchPolicy="cache-first"
          notifyOnNetworkStatusChange={true}
        >
          {({data, loading, error, refetch, networkStatus}: IVacationForecastResult) => {
            return (
              <>
                {loading && (
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    style={{height: "100%"}}
                  >
                    <Spinner/>
                    <span style={{marginLeft: "1em"}}>{t("page.dashboard.burned_story_points.loading.message")}</span>
                  </Grid>
                )}
                {error && (
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    style={{height: "100%"}}
                  >
                    <span>{t("page.dashboard.burned_story_points.error.message")}</span>
                  </Grid>
                )}
                {!loading && <VacationForecastTable vacationForecast={data?.getVacationForecast || []} sprints={variables.sprints} />}
              </>
            )
          }}
        </Query>
      </Grid>
    </Grid>
  )
}
