import React, { FunctionComponent, useContext, useEffect } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { CustomersPageTable } from "./CustomersPageTable";
import { CustomersContext } from "../../../../context/CustomersContext";
import { useTranslation } from "react-i18next";
import { EditCustomerMetadataModal } from './modal/EditCustomerMetadataModal';

interface ICustomerPageContentProps {
}

export const CustomerPageContent: FunctionComponent<ICustomerPageContentProps> = (props) => {
  const {t} = useTranslation();

  const {getProjects, jiraProjectsLoading, jiraProjectsError} = useContext(CustomersContext);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs>
      {jiraProjectsLoading && (
        <CircularProgress/>
      )}
      {jiraProjectsError && (
        <Typography>{t("page.customer.table.error.message")}</Typography>
      )}
      {!jiraProjectsLoading && !jiraProjectsError && (
        <>
          <CustomersPageTable/>
          <EditCustomerMetadataModal/>
        </>
      )}
    </Grid>
  )
}