import moment from "moment";
import lodash from "lodash";

// NOTE: changing this locale, changes the order of weekdays
moment.locale("de");
const sprints: string[] = [];

// include this many sprints of the past
const pastSprintCount = 1;
// wednesday
const isoDayOfRetro = 3;
// length of a sprint in weeks
const sprintLength = 2;

const now = moment();
// does a new sprint begin this week?
// sprint begin    -> odd calendar week
// no sprint begin -> even calendar week
const weekIncludesSprintBegin = now.week() % 2 !== 0;
const dayIsBeforeRetro = now.isoWeekday() < isoDayOfRetro;

// start of the current sprint
const sprintStart = now
  .clone()
  .day(isoDayOfRetro)
  .subtract(weekIncludesSprintBegin ? 0 : 1, "week");

const sub =
  weekIncludesSprintBegin && dayIsBeforeRetro
    ? pastSprintCount * 2 * sprintLength
    : pastSprintCount * sprintLength;
const startDate = sprintStart.clone().subtract(sub, "week");

for (let i = 0; i < 12; i++) {
  // skip i sprints
  const currentDate = startDate.clone().add(i * sprintLength, "week");

  const year = currentDate.year();
  const week = currentDate.week();
  const sprintNr = Math.ceil(week / 2);

  const paddedSprintNr = lodash.padStart(`${sprintNr}`, 2, "0");
  const sprint = `${year}/${paddedSprintNr}`;
  sprints.push(sprint);
}

export default {
  sprints,
};
