import React, { ChangeEvent, FC } from "react";
import FieldText, { Props } from "@atlaskit/field-text";
import { useFormikContext } from "formik";
import { isString } from "lodash";

type IFormikFieldTextProps = Props & {
  valueName: string
  clearable?: boolean
  onChange?: (e: ChangeEvent<any>) => void
  hide?: boolean
}

export const FormikFieldText: FC<IFormikFieldTextProps> = ({
 valueName,
 clearable,
 onChange,
 hide,
 ...rest
}) => {
  const { getFieldProps, getFieldMeta, handleChange, handleBlur } = useFormikContext()
  const field = getFieldProps(valueName)
  const fieldMeta = getFieldMeta(valueName)

  const handleChangeProxy = (e: ChangeEvent<any>) => {
    handleChange(e)
    onChange && onChange(e)
  }

  return (
    <FieldText
      { ...rest }
      name={valueName}
      value={field.value ?? ""}
      isInvalid={fieldMeta.touched && isString(fieldMeta.error)}
      invalidMessage={fieldMeta.touched && isString(fieldMeta.error) && fieldMeta.error}
      shouldFitContainer
      onChange={handleChangeProxy}
      onBlur={handleBlur}
    />
  )
}
