import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { Dictionary } from 'lodash';
import { config } from '../../../utils/config';

export const JWT_LOCAL_STORAGE_KEY = 'DCARE_API_ACCESS_TOKEN';

interface GraphQLContextType {
  client: ApolloClient<NormalizedCacheObject>;
  accessToken: string | null;
  setAccessToken: (token: string | null) => void;
}

const GraphQLContext = createContext<GraphQLContextType>({} as GraphQLContextType);

export const GraphQLProvider = (props: PropsWithChildren<any>) => {
  const value = useGraphQLProvider();

  return (
    <GraphQLContext.Provider value={value}>
      <ApolloProvider client={value.client}>
        {props.children}
      </ApolloProvider>
    </GraphQLContext.Provider>
  );
};

const useGraphQLProvider = (): GraphQLContextType => {
  const [accessToken, _setAccessToken] = useState<string | null>(
     localStorage.getItem(JWT_LOCAL_STORAGE_KEY),
  );

  const setAccessToken = useCallback(
    (token: string | null) => {
      _setAccessToken(token);
        if (token) {
          localStorage.setItem(JWT_LOCAL_STORAGE_KEY, token);
        } else {
          localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
        }
    },
    [_setAccessToken],
  );


  const client = useMemo(() => {
    const headers: Dictionary<string> = {};
    if (accessToken) {
      headers['authorization'] = `Bearer ${accessToken}`;
    }

    const apiLink = createUploadLink({
      uri:  config.REACT_APP_GRAPHQL_URL,
      headers,
    });

    return new ApolloClient({
      link: apiLink,
      cache: new InMemoryCache(),
    });
  }, [accessToken]);

  return { client, accessToken, setAccessToken };
};

export const useGraphQL = () => {
  return useContext(GraphQLContext);
};
