import { Box, Grid } from "@material-ui/core"
import React, { FC, useMemo } from "react"
import Levels from "react-activity/lib/Levels"
import "react-activity/lib/Levels/Levels.css"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useAuthContext } from "../../../context/AuthContext"
import "../../../index"
import { IBaseProps } from "../../../utils/BaseProps"
import { SprintList, SprintListItem } from "../../parts/SprintList"
import { ERPChart } from "./components/ERPChart"
import { useErpPageContext } from "./context/ERPPageContext"

interface IERPPageContentProps extends IBaseProps {
  history: any
}

export const ERPPageContent: FC<IERPPageContentProps> = props => {
  const { t } = useTranslation()
  const {
    data,
    loading,
    selectedSprintName,
    filteredData,
    projectRefs,
    setProjectRefs,
    openedSprints,
    handleSprintToggle,
  } = useErpPageContext()

  const { isCustomer } = useAuthContext();

  const showSprintDetailView = useMemo(() => !!selectedSprintName, [selectedSprintName])

  const empty = !data || !data.erp
  const calendarUnavailable = !loading && !empty && data.erp.some(erp => erp.absent === null)

  const transformedForSprint = useMemo((): SprintListItem[] => {
    const items: SprintListItem[] = []

    for (const erp of filteredData.erp) {
      for (const project of erp.projects) {
        items.push({
          projectKey: project.key,
          sprints: [
            {
              id: "",
              issues: [],
              number: "",
              projectKey: "",
              state: "",
              completeDate: "",
              name: erp.sprint,
              start: erp.start,
              end: erp.end,
              goal: null,
              __typename: "Sprint",
            },
          ],
        })
        setProjectRefs(v => ({ ...v, [`${erp.sprint}${project.key}`]: React.createRef() }))
      }
    }

    return items.sort((a, b) => {
      const projectA = filteredData.projects.find(project => project.key === a.projectKey)
      const projectB = filteredData.projects.find(project => project.key === b.projectKey)

      let nameA: string = a.projectKey
      let nameB: string = b.projectKey

      if (projectA) {
        nameA = projectA.dashboardName || projectA.name || projectA.key
      }
      if (projectB) {
        nameB = projectB.dashboardName || projectB.name || projectB.key
      }

      return nameA.localeCompare(nameB)
    })
  }, [filteredData, setProjectRefs])

  return (
    <>
      {empty && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100%",
            height: "100%",
          }}
        >
          <Levels color="#253858" size={128} speed={1} animating={true} />
        </div>
      )}
      {calendarUnavailable && toast.warn(t("page.erp.warning.calendar_unavailable")) && <></>}
      {!empty && data && data.erp && (
        <Grid container style={{ maxHeight: "100%", height: "100%", overflow: "hidden" }}>
          <Grid
            item
            xs={showSprintDetailView ? 3 : 12}
            style={{ maxHeight: "100%", height: "100%", zIndex: 1000 }}
          >
            <ERPChart />
          </Grid>
          {showSprintDetailView && (
            <Grid item xs={9} style={{ height: "100%" }}>
              <Box sx={{ maxHeight: "100%", overflow: "auto", p: 1 }}>
                <SprintList
                  items={transformedForSprint}
                  refs={projectRefs}
                  linkToProject
                  history={props.history}
                  openedSprints={openedSprints}
                  onSprintIsOpenChange={handleSprintToggle}
                  enableQuickActions={!isCustomer()}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}
