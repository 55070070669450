import Button from "@atlaskit/button"
import { ModalFooter } from "@atlaskit/modal-dialog"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { CustomersContext } from "../../../../../../../context/CustomersContext"
import { useEditCustomerMetadataForm } from "../context/EditCustomerMetadataContext"

export const EditCustomerMetadataFormFooter: FunctionComponent = () => {
  const { t } = useTranslation()

  const { closeModal, createOrEditLoading, isEdit } = useContext(CustomersContext)

  const { submitForm } = useEditCustomerMetadataForm()

  return (
    <ModalFooter>
      <Button appearance="danger" onClick={closeModal}>
        {t("page.customer.modal.close")}
      </Button>
      <Button isLoading={createOrEditLoading} appearance="primary" onClick={submitForm}>
        {isEdit ? t("page.customer.modal.edit") : t("page.customer.modal.create")}
      </Button>
    </ModalFooter>
  )
}
