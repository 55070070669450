import React, { createContext, useCallback, useContext, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Color } from '@material-ui/lab/Alert/Alert';

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface ISnackbarMessage {
  message: string;
  severity: Color;
}

interface SnackbarContextType {
  showSnackbar: (value: ISnackbarMessage) => void;
}

export const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType,
);

export const SnackbarProvider = (props: {
  children: JSX.Element;
}): JSX.Element => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<ISnackbarMessage>({
    message: '',
    severity: 'success',
  });

  const handleSnackbarClose = useCallback(
    () => setSnackbarOpen(false),
    [setSnackbarOpen],
  );

  const showSnackbar = useCallback(
    (message: ISnackbarMessage) => {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    },
    [setSnackbarMessage, setSnackbarOpen],
  );

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
      }}
    >
      {props.children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        style={{ zIndex: 10000 }}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{
            width: '100%',
          }}
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
