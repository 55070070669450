import React, { FC } from "react"
import { JiraSupportFormHeader } from "./JiraSupportFormHeader"
import { JiraSupportFormFooter } from "./JiraSupportFormFooter"
import { JiraSupportFormBody } from "./JiraSupportFormBody"
import { Grid } from "@material-ui/core"

export const JiraSupportForm: FC = () => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <JiraSupportFormHeader />
      </Grid>
      <Grid item xs>
        <JiraSupportFormBody />
      </Grid>
      <Grid item>
        <JiraSupportFormFooter />
      </Grid>
    </Grid>
  )
}
