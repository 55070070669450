import React, { FC } from 'react';
import { Button, ButtonProps, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

interface IFilterButtonProps extends ButtonProps {
  tooltipTitle: string;
  label: string;
  isActive: boolean;
  clickFunction: () => void;
}

export const FilterButton: FC<IFilterButtonProps> = ({
    label,
    isActive,
    clickFunction,
    tooltipTitle,
    ...rest
  }) => (
  <Tooltip title={tooltipTitle}>
    <Button
      {...rest}
      variant="outlined"
      style={{
        backgroundColor: isActive ? 'rgb(52, 69, 99)' : 'white',
        color: isActive ? 'white' : 'rgb(52, 69, 99)',
        transition: 'all 0.3s linear'
      }}
      startIcon={<FilterListIcon/>}
      onClick={clickFunction}
      disableRipple
    >
      {label}
    </Button>
  </Tooltip>
);
