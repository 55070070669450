import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";
import PageHeader from "@atlaskit/page-header";
import { useTranslation } from "react-i18next";
import { CustomersContextProvider } from "../../../context/CustomersContext";
import { CustomerPageContent } from "./components/CustomerPageContent";

interface ICustomersPageProps {
}

export const CustomersPage: FunctionComponent<ICustomersPageProps> = (props) => {
  const {t} = useTranslation();

  return (
    <Grid item container direction="column">
      <Grid item xs>
        <PageHeader>
          {t("page.customer.header")}
        </PageHeader>
      </Grid>
      <CustomersContextProvider>
        <CustomerPageContent/>
      </CustomersContextProvider>
    </Grid>
  )
}