import { ApolloError, NetworkStatus } from "@apollo/client"
import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { ErpQuery, useErpQuery } from "../../../../api/backend/graphql/generated"
import sprints from "../../../../utils/next-sprints"
import KeyHandler, { KEYDOWN } from "react-key-handler"
import store from "store"
import IssueDetail from "../../../parts/IssueDetail"
import { Dictionary } from "lodash"
import { useSnackbar } from "../../../../context/SnackbarContext";

interface IErpPageContextProps {
  data: ErpQuery | undefined
  filteredData: ErpQuery
  loading: boolean
  refetch: () => void
  networkStatus: NetworkStatus
  selectedSprintName: string | null | undefined
  setSelectedSprintName: Dispatch<SetStateAction<string | null | undefined>>
  selectedIssueKey: string
  setSelectedIssueKey: Dispatch<SetStateAction<string>>
  openedSprints: string[]
  setOpenedSprints: (v: string[]) => void
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>

  projectRefs: Dictionary<React.RefObject<HTMLDivElement>>
  setProjectRefs: Dispatch<SetStateAction<Dictionary<React.RefObject<HTMLDivElement>>>>

  keysArray: string[]

  selectSprint: (sprintName: string | null) => void
  scrollToProject: (sprint: string, projectKey: string) => void

  handleDetailViewOpen: (sprintName: string) => void
  handleSprintToggle: (sprintName: string, isOpen: boolean) => void
}

const OPENED_SPRINTS_STORE_KEY = "DENOVO_CARE_ERP_OPENED_SPRINTS";

export const ErpPageContext = createContext<IErpPageContextProps>({} as IErpPageContextProps)

export const ErpPageContextProvider: FC = ({ children }) => {
  const { showSnackbar } = useSnackbar();
  let urlSprintName: string | null = null
  const paths = window.location.pathname.split("/")
  if (paths.length === 3) {
    urlSprintName = decodeURIComponent(paths[2])
  }

  const [selectedSprintName, setSelectedSprintName] = useState<string | null | undefined>(
    urlSprintName
  )
  const [selectedIssueKey, setSelectedIssueKey] = useState<string>("")

  const [projectRefs, setProjectRefs] = useState<Dictionary<React.RefObject<any>>>({})

  const { data, loading, refetch, networkStatus } = useErpQuery({
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: {
      sprints: sprints.sprints,
    },
    onError: (error: ApolloError) => {
      showSnackbar({
        message: error.message,
        severity: 'error'
      })
    }
  })

  const selectSprint = useCallback(
    (sprintName: string | null) => {
      if (!sprintName || selectedSprintName === sprintName) {
        window.history.pushState(sprintName, "", "/erp")
        setSelectedSprintName(null)
      } else {
        window.history.pushState(sprintName, "", "/erp/" + encodeURIComponent(sprintName))
        setSelectedSprintName(sprintName)
      }
    },
    [selectedSprintName, setSelectedSprintName]
  )

  const filteredData = useMemo(() => {
    if (selectedSprintName) {
      return {
        erp: data?.erp.filter(erp => erp.sprint === selectedSprintName) ?? [],
        projects: data?.projects ?? [],
      }
    }
    return {
      erp: data?.erp ?? [],
      projects: data?.projects ?? [],
    }
  }, [data, selectedSprintName])

  const [openedSprints, _setOpenedSprints] = useState<string[]>(
    JSON.parse(store.get(OPENED_SPRINTS_STORE_KEY, "[]"))
  )
  const setOpenedSprints = useCallback(
    (value: string[]) => {
      store.set(OPENED_SPRINTS_STORE_KEY, JSON.stringify(value))
      _setOpenedSprints(value)
    },
    [_setOpenedSprints]
  )

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleDetailViewOpen = useCallback(
    (issueKey: string) => {
      setSelectedIssueKey(issueKey)
      setIsModalOpen(true)
    },
    [setSelectedIssueKey, setIsModalOpen]
  )

  const handleDetailViewClose = useCallback(() => {
    setSelectedIssueKey("")
    setIsModalOpen(false)
    setProjectRefs({})
  }, [setSelectedIssueKey, setIsModalOpen])

  const handleSprintToggle = useCallback(
    (sprintName: string, isOpen: boolean) => {
      const cSprints = !isOpen
        ? openedSprints.filter(value => value !== sprintName)
        : [...openedSprints, sprintName]
      setOpenedSprints(cSprints)
    },
    [openedSprints, setOpenedSprints]
  )

  const scrollToProject = useCallback(
    (sprint: string, projectKey: string) => {
      if (!selectedSprintName) {
        selectSprint(sprint)
      } else {
        const key = `${sprint}${projectKey}`
        if (projectRefs[key]) {
          projectRefs[key].current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      }
    },
    [selectedSprintName, projectRefs, selectSprint]
  )

  const keysArray = useMemo(() => {
    const keys: string[] = []
    if (data) {
      for (const project of data.projects) {
        if (project.dashboardName === null && project.name && !keys.includes(project.name)) {
          keys.push(project.name)
        } else if (project.dashboardName != null && !keys.includes(project.dashboardName)) {
          keys.push(project.dashboardName)
        }
      }
    }
    return keys
  }, [data])

  return (
    <ErpPageContext.Provider
      value={{
        data,
        filteredData,
        loading,
        refetch,
        networkStatus,
        selectedSprintName,
        setSelectedSprintName,
        selectedIssueKey,
        setSelectedIssueKey,
        selectSprint,
        openedSprints,
        setOpenedSprints,
        projectRefs,
        setProjectRefs,
        keysArray,

        scrollToProject,
        isModalOpen,
        setIsModalOpen,
        handleDetailViewOpen,
        handleSprintToggle,
      }}
    >
      <KeyHandler
        keyEventName={KEYDOWN}
        code="Escape"
        onKeyHandle={() => {
          if (!selectedIssueKey) {
            selectSprint(null)
          }
        }}
      />
      <IssueDetail
        onClose={handleDetailViewClose}
        issueKey={selectedIssueKey}
        isOpen={isModalOpen}
      />

      {children}
    </ErpPageContext.Provider>
  )
}

export const useErpPageContext = () => useContext(ErpPageContext)
