import { useTranslation } from "react-i18next";
import * as yup from "yup"

export const useJiraSupportValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    title: yup.string().required(t("page.sprints.support.modal.required")),
    description: yup.string().required(t("page.sprints.support.modal.required"))
  })

}
