import { useTranslation } from "react-i18next"
import { isPostalCode, isHexColor } from "class-validator"
import * as yup from "yup"

export const useCustomerMetadataValidationSchema = () => {
  const { t: initialT } = useTranslation("translation")

  const t = (key: string) => initialT(`page.customer.modal.field_messages.${key}`)

  return yup.object({
    projectName: yup.string().required(t("required")),
    companyName: yup.string().required(t("required")),
    companyType: yup.string().required(t("required")),
    salutation: yup.string().required(t("required")),
    firstname: yup.string().required(t("required")),
    lastname: yup.string().required(t("required")),
    streetName: yup.string().required(t("required")),
    streetNumber: yup.string().required(t("required")),
    postalCode: yup
      .string()
      .required(t("required"))
      .test("postalCode", t("invalid_postal_code"), value => {
        return isPostalCode(value, "any")
      }),
    city: yup.string().required(t("required")),
    country: yup.string().required(t("required")),
    vatId: yup.string().required(t("required")),
    dashboardName: yup.string().required(t("required")),
    dashboardColor: yup
      .string()
      .required(t("required"))
      .test("dashboardColorHashtag", t("invalid_color"), value => {
        return `${value}`.startsWith("#") && isHexColor(value)
      }),
    hasSupportContract: yup.boolean().required(t("required")),
  })
}
