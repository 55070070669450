import React, { FC } from "react";
import Form, { Field, FormSection, FormFooter } from "@atlaskit/form";
import Button from "@atlaskit/button";
import FieldTextArea from "@atlaskit/field-text-area";
import { IssueComment } from "../../api/graphql/query/issue";
import "./Comment.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Avatar from "@atlaskit/avatar";

interface ICommentProps {
  comment?: IssueComment;
  editMode?: boolean;
  handleSubmit: (comment: string) => Promise<any>;
}

interface IFormFields {
  comment: string;
}

export const Comment: FC<ICommentProps> = (props) => {
  const { t } = useTranslation();
  const { comment, editMode, handleSubmit } = props;

  const author = comment?.author || t("issue.comment.author");
  const date = comment?.date ? moment(comment.date).fromNow() : "";
  const delimiter = comment?.date ? "-" : "";
  const title = `${author} ${delimiter} ${date}`;

  const onSubmit = async ({ comment }: IFormFields) => {
    await handleSubmit(comment);
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ formProps, submitting }: any) => (
        <form {...formProps} style={{ flex: 1 }}>
          <FormSection>
            <Field
              label={
                <>
                  <Avatar src={comment?.avatar} />
                  {title}
                </>
              }
              name="comment"
              defaultValue={comment?.body}
            >
              {({ fieldProps }: any) => (
                <FieldTextArea
                  isLabelHidden
                  isReadOnly={!editMode}
                  required
                  shouldFitContainer
                  placeholder={t("issue.comment.add")}
                  enableResize="vertical"
                  {...fieldProps}
                />
              )}
            </Field>
          </FormSection>
          {editMode && (
            <FormFooter>
              <Button appearance="primary" type="submit" isLoading={submitting}>
                {t("issue.comment.submit")}
              </Button>
            </FormFooter>
          )}
        </form>
      )}
    </Form>
  );
};
