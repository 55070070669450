import { gql } from "apollo-boost"
import { QueryResult } from "react-apollo"
import { SprintPreview, SprintPreview_sprintPreview, SprintPreview_sprintPreview_projects, SprintPreview_sprintPreview_projects_issueAmounts } from "./types/SprintPreview"

export const SPRINT_PREVIEW_QUERY = gql`
  query SprintPreview($amountOfSprints: Float!) {
    sprintPreview(amountOfSprints: $amountOfSprints) {
      projects {
        name
        key
        avatarUrl
        storyPoints
        issueAmounts {
          bugs
          stories
          tasks
        }
      }
      name
    }
  }

`
export interface ISprintPreviewResult extends QueryResult<SprintPreview> { }
export interface ISprintPreview extends SprintPreview_sprintPreview { }
export interface ISprintPreviewProject extends SprintPreview_sprintPreview_projects { }
export interface ISprintPreviewProjectIssueAmounts extends SprintPreview_sprintPreview_projects_issueAmounts { }