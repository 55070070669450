import * as React from "react"
import __ from "lodash"
import moment from "moment"
import { Query, QueryResult } from "react-apollo"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Spinner from "@atlaskit/spinner"
import CircularProgressbar from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import IssueDetail from "../../parts/IssueDetail"
import SprintActions from "../../parts/SprintActions"
import { IBaseProps } from "../../../utils/BaseProps"
import SPRINTS_QUERY from "../../../api/graphql/query/sprints"
import { mapStatusToIcon, mapStatusToText, mapStatusToColor, getPhaseOfStatus, PHASES } from "../../../api/graphql/query/issue"

interface IGenerateRoadmapProps extends IBaseProps {
  history: any
}

interface IGenerateRoadmapState {
  isGeneratingSprintReview: boolean
  isModalOpen: boolean;
}

const Title = styled.div`
  color: rgb(52, 69, 99);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
`

const Subtitle = styled.div`
  color: #8C96A5;
  font-weight: normal;
  font-size: 14px;
`

const GenerateRoadmapHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`

const SprintListing = styled.div`

`

const Sprint = styled.div`
`

const SprintTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;

  .CircularProgressbar {
    height: 60px;
    width: 60px;
  }

  h3 {
    margin-left: 20px;
  }
`

const Spacer = styled.div`
  flex-grow: 1;
`

const SprintSum = styled.div`
  font-size: 30px;
  font-weight: 200;
  margin-left: 20px;
  padding-bottom: 10px;

  &:before {
    content: "∑";
    font-size: 1.4em;
    opacity: 0.2;
  }
`

const Issues = styled.div`
`

const Issue = styled.div`
  margin-bottom: 20px;
  min-height: 62px;
  position: relative;
  border-radius: 1px;
  background-color: white;
  padding-left: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;

  &:before {
    content: " ";
    background-color: ${props => props.color || "tranparent"};
    position: absolute;
    left: -4px;
    height: 100%;
    width: 36px;
    z-index: -1;
    border-radius: 3px;
  }
`

const IssueHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
  flex-grow: 1;
`

const IssueIdentifier = styled.div`
  flex-grow: 1;
`

const IssueState = styled.div`
`

const IssueKey = styled.div`
`

const IssueSummary = styled.div`
`

const SprintSummary = styled.div`
  border-top: 1px solid rgba(0,0,0,0.1);
  margin-bottom: 70px;
`

const Summe = styled.div`
  float: right;
  padding-top: 8px;
  color: rgba(0,0,0,0.2);
`

const IssueStatus = styled.div`
  display: inline-block;
  padding: 0 5px;
  border-radius: 3px;
  border: 1px solid ${props => props.color || "rgba(0,0,0,0.3)"};
  color: ${props => props.color || "rgb(52, 69, 99)"};
`

class GenerateRoadmap extends React.Component<IGenerateRoadmapProps, IGenerateRoadmapState> {

  constructor(props: IGenerateRoadmapProps) {
    super(props)
    this.state = {
      isGeneratingSprintReview: false,
      isModalOpen: false,
    }
  }

  handleDetailClose = () => {
    this.setState({ isModalOpen: false });
    // this.props.history.push(`/project/${__.get(this.props, "match.params.key")}/GenerateRoadmap`)
  }

  handleDetailOpen = (issueKey: string) => () => {
    this.setState({ isModalOpen: true });
    // this.props.history.push(`/project/${__.get(this.props, "match.params.key")}/GenerateRoadmap/${issueKey}`)
  }

  public render() {
    const { t } = this.props
    const projectKey = __.get(this.props, "match.params.key")
    return (
      <>
        <IssueDetail
          {...this.props}
          onClose={this.handleDetailClose}
          issueKey={__.get(this.props, "match.params.issue")}
          isOpen={this.state.isModalOpen}
        />
        <Query query={SPRINTS_QUERY} variables={{ projectKey }} fetchPolicy="cache-first">
        {({ data, loading, error, refetch }: QueryResult) => (
          <React.Fragment>
            <GenerateRoadmapHeader>
              <Title>{t("page.generate_roadmap.header.title")}</Title>
              <Subtitle>
                  {loading &&
                    <React.Fragment>
                      <span style={{ paddingRight: "5px" }}><Spinner size="small" /></span> {t("page.generate_roadmap.loading.message")}
                    </React.Fragment>
                  }
                  {!loading && data && __.get(data, "project.name")}
              </Subtitle>
            </GenerateRoadmapHeader>

            {!loading && data &&
              <React.Fragment>
                <SprintListing>
                {data.sprints ? data.sprints.map((sprint: any) => {
                  const issues = (__.get(sprint, "issues", []) || [])
                  const calculation = issues
                    .reduce((acc: { total: number, done: number }, issue: any) => {
                      return {
                          total: acc.total + __.get(issue, "estimation", 0),
                          done: acc.done + (getPhaseOfStatus(issue.status.name) >= PHASES.SHIPPING.LEVEL ? __.get(issue, "estimation", 0) : 0)
                      }
                    }, {
                      total: 0,
                      done: 0
                    })
                  const evaluation: { total: number, done: number, percentage: number, label: string } = {
                    ...calculation,
                    percentage: calculation.total > 0 ? 100.0 / calculation.total * calculation.done : 0,
                    label: calculation.total === 0 || calculation.total === calculation.done ? "✓" : `${parseInt(`${100.0 / calculation.total * calculation.done}`)}%`,
                  }
                  return (
                    <Sprint key={sprint.name}>
                      <SprintTitle>
                        <CircularProgressbar
                          percentage={evaluation.percentage}
                          text={evaluation.label}
                          styles={{
                            path: { stroke: "#253858" },
                            text: { fill: "#253858" },
                            trail: { stroke: "rgba(0,0,0,0.1)" },
                          }}
                        />
                        <h3>
                          {sprint.name}
                          <Subtitle>{sprint.start && sprint.end ? `Sprintbeginn: ${moment(sprint.start).format("D. MMMM YYYY")} - Ende: ${moment(sprint.end).format("D. MMMM YYYY")}` : "Nicht für die Entwicklung vorgesehen"}</Subtitle>
                        </h3>
                        <Spacer />
                        <SprintActions projectKey={projectKey} sprintName={sprint.name} />
                        <SprintSum>{evaluation.total} SP</SprintSum>
                      </SprintTitle>
                      <Issues>
                        {issues.map((issue: any) => (
                          <Issue key={issue.key} color={mapStatusToColor(issue.status)} onClick={this.handleDetailOpen(issue.key)}>
                            <IssueHeader>
                              <IssueIdentifier>
                                <IssueKey><b>{issue.key}</b> {issue.type.name} <IssueStatus color={mapStatusToColor(issue.status)}>{mapStatusToIcon(issue.status)} {t(`issue.issue_status.${mapStatusToText(issue.status)}`)}</IssueStatus></IssueKey>
                                <IssueSummary>{issue.summary}</IssueSummary>
                              </IssueIdentifier>
                              <IssueState>
                                {issue.estimation !== null && `${issue.estimation} SP`}
                              </IssueState>
                            </IssueHeader>
                          </Issue>
                        ))}
                      </Issues>
                      <SprintSummary>
                        <Summe>Gesamt {evaluation.total} SP</Summe>
                      </SprintSummary>
                    </Sprint>
                  )
                }) : <div>Es wurden keine Sprints gefunden.</div>}
                </SprintListing>
              </React.Fragment>
            }
          </React.Fragment>
        )}
        </Query>
      </>
    )
  }
}

export default withTranslation()(GenerateRoadmap)
