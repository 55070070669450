import {
  Box,
  Grid,
  IconButton, Typography,
  useTheme
} from "@material-ui/core"
import { AttachFile, Delete } from "@material-ui/icons"
import { useFormikContext } from "formik"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"

type IFormikFileSelectItemProps = {
  valueName: string
  onRemove?: () => void
}

export const FormikFileSelectItem: FC<IFormikFileSelectItemProps> = ({ valueName, onRemove }) => {
  const theme = useTheme()

  const { getFieldProps } = useFormikContext()
  const { value } = getFieldProps<File>(valueName)

  const isImage = useMemo(() => value.type.startsWith("image"), [value])
  const imgSrc = useMemo(() => (isImage ? URL.createObjectURL(value) : ""), [value, isImage])

  const handleRemove = useCallback(() => onRemove && onRemove(), [onRemove])

  return (
    <Grid item xs={12}>
      <Box
        width={"100%"}
        border={`1px solid ${theme.palette.primary.main}`}
        borderRadius={theme.shape.borderRadius}
        overflow="hidden"
      >
        <Grid container alignContent="center" alignItems="stretch" spacing={1}>
          <Grid item>
            {isImage && (
              <div
                style={{
                  width: 100,
                  height: "100%",
                  minHeight: "60px",
                  backgroundImage: `url(${imgSrc})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            )}
            {!isImage && (
              <div
                style={{
                  width: 100,
                  height: "100%",
                }}
              >
                <Grid
                  style={{
                    height: "100%",
                  }}
                  container
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <Grid item>
                    <AttachFile />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontWeight: 700, opacity: 0.8 }}>
                      {_.last(value.name.split("."))?.toUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
          <Grid item xs>
            <Grid container alignContent="center" style={{ height: "100%" }}>
              <Box p={1}>
                <Typography style={{ overflowWrap: "anywhere" }}>{value.name}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <Grid
              container
              style={{ height: "100%", paddingRight: theme.spacing(1) }}
              alignContent="center"
            >
              <IconButton onClick={handleRemove} size="small">
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
