import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from "react";
import { ApolloError } from "@apollo/client";
import { useSnackbar } from "../../../../context/SnackbarContext";
import { useVersionQuery, } from "../../../../api/backend/graphql/generated";

interface ILoginContextProps {
  versionLoading: boolean;
  backendVersion: string;
}

export const LoginContext = createContext<ILoginContextProps>({} as ILoginContextProps);

interface ILoginContextProviderProps extends PropsWithChildren<any> {}

export const LoginContextProvider: FC<ILoginContextProviderProps> = ({ children}) => {
  const {showSnackbar} = useSnackbar();

  const {data: versionData, loading: versionLoading} = useVersionQuery({
    fetchPolicy: 'cache-first',
    onError: (error: ApolloError) => {
      showSnackbar({
        message: error.message,
        severity: 'error'
      });
    }
  })

  const backendVersion = useMemo(() => versionData ? versionData.version.tag : "n.A.", [versionData]);

  return (
        <LoginContext.Provider
          value={{
            backendVersion,
            versionLoading,
          }}
        >
          { children }
        </LoginContext.Provider>
  )
}

export const useLogin = () => useContext(LoginContext);
