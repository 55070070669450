import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { IBurnedStoryPoints } from "../../../../../../api/graphql/query/burned-story-points"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell, { TableCellProps } from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableFooter from "@material-ui/core/TableFooter"
import { Grid, makeStyles } from "@material-ui/core"
import * as __ from "lodash"

interface ITableColumnProps extends TableCellProps {
  label: string
  labelClassName?: string
  imageUrl?: string  
}

interface IBurnedStoryPointsTableProps {
  data: IBurnedStoryPoints
}

const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor: "#fafafa",
    position: "sticky",
    left: 0,
  },
  labelText: { 
    width: "100%"
  },
  header: {
    zIndex: 1000,
  },
  footerLabel: {
    bottom: 0,
    zIndex: 1000,
  },
  footerColumn: {
    bottom: 0,
  },
  projectImg: {
    height: 25,
    paddingRight: theme.spacing(1),
  },
}))

export const BurnedStoryPointsTable: FunctionComponent<IBurnedStoryPointsTableProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { sprints } = props.data

  const headers: ITableColumnProps[] = [
    {
      label: t("page.dashboard.burned_story_points.table.headers.projects"),
      id: "projects",
      align: "left",
      className: `${classes.label} ${classes.header}`,
    },
    ...sprints.map(
      (sprint): ITableColumnProps => ({
        label: `Sprint ${sprint.name}`,
        id: `sprint-${sprint.name}`,
        align: "center",
      })
    ),
  ]
  const projects = __.uniqBy(
    __.flatMap(sprints, s => s.projects),
    project => project.key
  )

  const rows = __.orderBy(projects, p => p.key).map(project => {
    const columns: ITableColumnProps[] = [
      {
        label: `${project.key} - ${project.name}`,
        align: "left",
        className: classes.label,
        imageUrl: project.avatarUrl,
      },
    ]
    for (const sprint of sprints) {
      const projectForSprint = sprint.projects.find(
        sprintProject => sprintProject.key === project.key
      )
      columns.push({
        label:
          projectForSprint && projectForSprint.projectTotal > 0
            ? `${projectForSprint.projectTotal}  SP`
            : "-",
        align: "center",
        labelClassName: classes.labelText
      })
    }
    return columns
  })

  const footer: ITableColumnProps[] = [
    {
      label: t("page.dashboard.burned_story_points.table.footers.sum"),
      id: "footer",
      align: "left",
      className: `${classes.label} ${classes.footerLabel}`,
    },
    ...sprints.map(
      (sprint): ITableColumnProps => ({
        label: `${sprint.sprintTotal} SP`,
        id: `sprint-${sprint.name}-total`,
        align: "center",
        className: `${classes.label} ${classes.footerColumn}`,
      })
    ),
  ]

  return (
    <TableContainer style={{ maxHeight: `100%`, overflowY: "auto" }}>
      <Table stickyHeader={true} size="small" aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headers.map((column, index) => {
              const { label, ...rest } = column
              return (
                <TableCell key={index} {...rest}>
                  {label}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!rows.length && (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <Grid container justifyContent="center" alignContent="center">
                  <span>{t("page.dashboard.burned_story_points.table.empty")}</span>
                </Grid>
              </TableCell>
            </TableRow>
          )}
          {rows.length &&
            rows.map((row, i) => (
              <TableRow key={i}>
                {row.map((column, index) => {
                  const { label, imageUrl, labelClassName, ...rest } = column
                  return (
                    <TableCell key={index} {...rest}>
                      <Grid container alignItems="center">
                        {imageUrl && (
                          <img src={imageUrl} alt={label} className={classes.projectImg} />
                        )}
                        <span className={labelClassName}>{label}</span>
                      </Grid>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {footer.map((column, index) => {
              const { label, ...rest } = column
              return (
                <TableCell key={index} {...rest}>
                  {label}
                </TableCell>
              )
            })}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
