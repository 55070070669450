import { useMemo } from "react"
import { parseSprintShort } from "../../../../../utils/parse-sprint-short"

export const useSelectedSprintName = (selectedName?: string, sprintNames?: string[]) => {
  return useMemo(() => {
    if (!selectedName || !sprintNames) return null
    for (const sprintName of sprintNames) {
      if (sprintName.toLowerCase() === selectedName.toLowerCase()) {
        return sprintName
      }
      if (parseSprintShort(sprintName).replace("/", "-") === selectedName) {
        return sprintName
      }
      if (selectedName.toLowerCase() === "backlog") {
        return "Backlog"
      }
    }
    return null
  }, [selectedName, sprintNames])
}
