import Moment from 'moment';
import { ErpQuery } from '../api/backend/graphql/generated';
import { ERP_erp_absent } from '../api/graphql/query/types/ERP';

const DEFAULT_NAME = "Other";
const DEFAULT_COLOR = "#b6b6b6";

export const parseJson = (data: Readonly<ErpQuery>, inPercent: boolean) => {
    if(!data) return [];
    const sprintArray = [];

    // sprints
    for (const sprint of data.erp) {
        const element = {};

        const sprintSum = sprint.projects.reduce((acc, project) => acc + (project.planned || 0), 0);
        element["sprint"] = `${sprint.sprint}_` + (Moment(sprint.start)).format('DD.MM') + "-" + Moment(sprint.end).format('DD.MM') + `_${sprintSum}`;

        // absent
        element["absent"] = sprint.absent || [];
        element["days"] = element["absent"].reduce((acc: number, curr: ERP_erp_absent) => acc + (curr.days || 0), 0);

        // projects
        for (const project of sprint.projects) {
            // match project with projectDetail to get details
            const details = data.projects.find((projectDetail) => project.key === projectDetail.key);
            const identifier = details?.dashboardName || details?.name || DEFAULT_NAME;

            element[identifier] = inPercent ? project.planned / sprintSum * 100 : project.planned;
            element[`${identifier}Key`] = project.key;
            element[`${identifier}Color`] = details?.dashboardColor || DEFAULT_COLOR;
        }

        sprintArray.push(element);
    }

    return sprintArray;
}
