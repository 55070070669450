import moment from "moment";
import _ from "lodash";

export const getFutureSprints = (value: number): string[] => {
  // NOTE: changing this locale, changes the order of weekdays
  moment.locale("de");
  // wednesday
  const isoDayOfRetro = 3;
  // length of a sprint in weeks
  const sprintLength = 2;

  const now = moment();
  // does a new sprint begin this week?
  // sprint begin    -> odd calendar week
  // no sprint begin -> even calendar week
  const weekIncludesSprintBegin = now.isoWeek() % 2 !== 0;
  const dayIsBeforeRetro = now.isoWeekday() < isoDayOfRetro;

  // start of the current sprint
  const sprintStart = now
    .clone()
    .day(isoDayOfRetro)
    .subtract(weekIncludesSprintBegin ? 0 : 1, "week");

  const sub =
    weekIncludesSprintBegin && dayIsBeforeRetro
      ? 2 * sprintLength
      : sprintLength;
  const startDate = sprintStart.clone().subtract(sub, "week");
  const currentDate = startDate.clone().add(sprintLength, "week");

  const year = currentDate.year();
  const week = currentDate.week();
  const sprintNr = Math.ceil(week / 2);

  const sprintNames: string[] = []
  for (let i = 1; i <= value; i++) {
    const paddedSprintNr = _.padStart(`${sprintNr + i}`, 2, "0");
    sprintNames.push(`${year}/${paddedSprintNr}`);
  }

  return sprintNames;
}