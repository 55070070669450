import React, { useContext } from "react"
import jwt from "jsonwebtoken"
import __ from "lodash"
import store from "store"
import { client } from "../index"
import { ROUTE_DICTIONARY } from "../routes"

const PROVIDER = {
  UNKNOWN: undefined,
  JIRA: "jira",
  CUSTOMER: "customer",
}

const PROJECT_SIGNATION = "dcare"

export interface IAuthContextState {
  token: string | undefined
  endpoint: string | undefined
  abilities: string[]
  isAuthenticated: () => boolean
  hasAbility: (ability: string) => boolean
  isCustomer: () => boolean
  isJiraUser: () => boolean
  getAbilities: () => Array<string>
  getRestUri: (url: string) => string
  authin: (token: string) => void
  logout: () => void
  name: string
  provider?: undefined
}

const defaultValues: IAuthContextState = {
  token: undefined,
  endpoint: undefined,
  abilities: [],
  isAuthenticated: () => false,
  hasAbility: () => false,
  isCustomer: () => false,
  isJiraUser: () => false,
  getAbilities: () => [],
  getRestUri: () => "",
  authin: () => undefined,
  logout: () => undefined,
  name: "-",
  provider: undefined,
}

const AuthContext = React.createContext<IAuthContextState>(defaultValues)

export const ABILITIES = {
  ADMIN: "admin",
  PRODUCT_OWNER: "JIRA:ProductOwner",
}

interface AuthProviderProps {
  token: string
  endpoint: string
  materialOnlyIds?: string
  plantOnlyIds?: string
}

class AuthProvider extends React.Component<AuthProviderProps, IAuthContextState> {
  constructor(props: AuthProviderProps) {
    super(props)
    const payload = jwt.decode(props.token)

    if (payload && __.get(payload, "sig") !== PROJECT_SIGNATION) {
      this.logout()
      window.location.href = ROUTE_DICTIONARY.LOGIN.path
      return
    }

    this.state = {
      token: props.token,
      endpoint: props.endpoint,
      abilities: __.get(payload, "ab", []),
      isAuthenticated: this.isAuthenticated,
      hasAbility: this.hasAbility,
      isCustomer: this.isCustomer,
      isJiraUser: this.isJiraUser,
      getAbilities: this.getAbilities,
      getRestUri: this.getRestUri,
      authin: this.authin,
      logout: this.logout,
      name: __.get(payload, "inf.n", "Benutzer"),
      provider: __.get(payload, "inf.p", undefined) || undefined,
    }
  }

  private isAuthenticated = () => {
    return !!this.state.token && this.state.token !== "" && this.state.token !== "logout"
  }

  private hasAbility = (ability: string): boolean => {
    if (this.state.abilities.indexOf(ABILITIES.ADMIN) >= 0) {
      return true
    }

    return this.state.abilities.indexOf(ability) >= 0
  }

  private isCustomer = (): boolean => {
    return this.state.provider === PROVIDER.CUSTOMER
  }

  private isJiraUser = (): boolean => {
    return this.state.provider === PROVIDER.JIRA
  }

  private getAbilities = () => this.state.abilities

  private logout = () => {
    store.remove("access_token")
    store.remove("project_search")
    store.remove("sprint_search")
    store.remove("open_sprints")
    client.cache.reset()

    this.setState({
      token: undefined,
      abilities: [],
    })
  }

  private getRestUri = (url: string) => {
    return `${new URL(this.state.endpoint || window.location.href).origin}/${url}?token=${
      this.state.token
    }`
  }

  handleTokenChange(token: string) {
    store.remove("project_search")
    store.remove("sprint_search")
    store.remove("open_sprints")
    store.set("access_token", token)
    const payload = jwt.decode(token)

    this.setState({
      token: token,
      abilities: payload ? __.get(payload, "ab", []) : [],
      name: __.get(payload, "inf.n", "Benutzer"),
      provider: __.get(payload, "inf.p", undefined) || undefined,
    })
  }

  authin = (token: string) => {
    this.handleTokenChange(token)
  }

  render() {
    return (
      <AuthContext.Provider value={{ ...this.state }}>{this.props.children}</AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer

const useAuthContext = () => useContext(AuthContext)

export { AuthProvider, AuthConsumer, useAuthContext }
