import React, { FunctionComponent, useMemo } from "react";
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  GetVacationForecast_getVacationForecast
} from "../../../../../../api/graphql/query/types/GetVacationForecast";

const useStyles = makeStyles(theme => ({
  table: {
    header: "500px",
    minWidth: "300px",
    paddingTop: theme.spacing(2),
  },
  header: {
    align: "left",
    fontWeight: 700
  },
  footer: {
    fontWeight: 700
  }
}));

interface IVacationForecastTableProps {
  vacationForecast: GetVacationForecast_getVacationForecast[];
  sprints: string[]
}

interface IColumn {
  person: string,
  department: string,
  nextSprint: string,
  secondNextSprint: string,
}

export const VacationForecastTable: FunctionComponent<IVacationForecastTableProps> = (props) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {vacationForecast, sprints} = props;


  const headers = useMemo(() => {
    return [
      {
        label: t("page.dashboard.vacation_forecast.table.headers.person"),
        id: "person",
      },
      {
        label: t("page.dashboard.vacation_forecast.table.headers.department"),
        id: "department"
      },
      {
        label: sprints[0],
        id: "next_sprint",
      },
      {
        label: sprints[1],
        id: "second_next_sprint",
      }
    ];
  }, [t, sprints]);

  const dataToShow = useMemo(() => {
    const users = vacationForecast.map(datum => datum.name)
    const uniqueUsers = _.uniq(users);

    const tempDataToShow: IColumn[] = [];

    uniqueUsers.forEach(uniqUser => {
      const vacations: GetVacationForecast_getVacationForecast[] = _.filter(vacationForecast, {name: uniqUser});

      if (vacations.length > 0) {
        tempDataToShow.push({
          person: uniqUser,
          department: vacations[0].department,
          nextSprint: vacations[0].sprint === sprints[0] ?
            vacations[0].trainingDays !== 0 ? `${vacations[0].vacations} (${vacations[0].trainingDays} training day(s))` : vacations[0].vacations.toString()
            : "0",
          secondNextSprint: vacations[0].sprint === sprints[1]
            ? vacations[0].trainingDays !== 0 ? `${vacations[0].vacations} (${vacations[0].trainingDays} training day(s))` : vacations[0].vacations.toString()
            : vacations.length > 1
              ? vacations[1].trainingDays !== 0 ? `${vacations[1].vacations} (${vacations[1].trainingDays} training day(s))` :  vacations[1].vacations.toString()
              : "0",
        })
      }
    })

    return tempDataToShow;
  },[vacationForecast, sprints]);

  const sumDaysNextSprint = useMemo(() => {
    const nextSprintVacations = vacationForecast.filter(datum => datum.sprint === sprints[0]);
    return _.sumBy(nextSprintVacations, "vacations")
  }, [vacationForecast, sprints]);

  const sumDaysSecondNextSprint = useMemo(() => {
    const nextSprintVacations = vacationForecast.filter(datum => datum.sprint === sprints[1]);
    return _.sumBy(nextSprintVacations, "vacations")
  }, [vacationForecast, sprints]);

  return (
    <TableContainer style={{maxHeight: "100%", overflowY: "auto"}}>
      <Table className={classes.table} aria-label="sprint velocity table">
        <TableHead>
          <TableRow>
            {headers.map((column, idx: number) => (
              <TableCell key={`${column.id}-${idx}`} className={classes.header}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToShow.map((value: IColumn, idx: number) => {
            return (
              <TableRow key={`${idx}-${value.person}`}>
                <TableCell key={`${idx}-${value.person}-name`}>
                  {value.person}
                </TableCell>
                <TableCell key={`${idx}-department-${value.department}`}>
                  {value.department}
                </TableCell>
                <TableCell key={`${idx}-${sprints[0]}-${value.nextSprint}`}>
                  {value.nextSprint}
                </TableCell>
                <TableCell key={`${idx}-${sprints[1]}-${value.secondNextSprint}`}>
                  {value.secondNextSprint}
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow key="totals">
            <TableCell className={classes.footer} key="sum">
              {t("page.dashboard.vacation_forecast.table.footer.sum")}
            </TableCell>
            <TableCell key="empty" />
            <TableCell className={classes.footer} key="sum-next-sprint">
              {`${sumDaysNextSprint} ${t("page.dashboard.vacation_forecast.table.footer.days")}`}
            </TableCell>
            <TableCell className={classes.footer} key="sum-second-next-sprint">
              {`${sumDaysSecondNextSprint} ${t("page.dashboard.vacation_forecast.table.footer.days")}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )


}