import { gql } from "apollo-boost";

export const ADD_ISSUE_COMMENT_MUTATION = gql`
  mutation AddCommentMutation($key: String!, $comment: String!) {
    addComment(key: $key, comment: $comment) {
      id
      body
      author
      avatar
      date
    }
  }
`;
