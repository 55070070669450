import React, { FunctionComponent, useContext } from "react"
import { CustomersContext } from "../../../../../context/CustomersContext"
import { EditCustomerMetadataContextProvider } from "./form/context/EditCustomerMetadataContext"
import { EditCustomerMetadataForm } from "./form/EditCustomerMetadataForm"

export const EditCustomerMetadata: FunctionComponent = () => {
  const { customerMetadata } = useContext(CustomersContext)
  return customerMetadata ? (
    <EditCustomerMetadataContextProvider initialValues={customerMetadata}>
      <EditCustomerMetadataForm />
    </EditCustomerMetadataContextProvider>
  ) : (
    <></>
  )
}
