import React, { FC, useMemo } from "react"
import { useAuthContext } from "../../../../context/AuthContext"
import { parseJson } from "../../../../utils/parse"
import Chart from "./Chart/chart"
import { useErpPageContext } from "../context/ERPPageContext"

export const ERPChart: FC = () => {
  const { isCustomer } = useAuthContext()
  const {
    data: erpData,
    selectedSprintName,
    selectSprint,
    filteredData,
    scrollToProject,
    keysArray
  } = useErpPageContext()

  const showSprintDetailView = useMemo(() => !!selectedSprintName, [selectedSprintName])

  const shortenSprintName = (sprintName: string) => sprintName.split("_")[0]

  return erpData ? (
    <Chart
      data={showSprintDetailView ? parseJson(filteredData, true) : parseJson(erpData, false)}
      keys={keysArray.sort((a, b) => b.localeCompare(a))}
      colors={({ id, data }: { id: string; data: string[] }) => {
        return data[`${id}Color`]
      }}
      maxValue={showSprintDetailView ? 100 : undefined}
      unitAbbreviation={showSprintDetailView ? "%" : "SP"}
      unit={showSprintDetailView ? "Percent" : "Story Points"}
      showRightAxis={!showSprintDetailView}
      showLegend={!showSprintDetailView}
      margin={
        showSprintDetailView
          ? { top: 0, right: 0, bottom: 50, left: 60 }
          : {
              top: 0,
              right: 200,
              bottom: 50,
              left: 60,
            }
      }
      showTargetLines={false}
      showAbsentIndicator={!isCustomer()}
      handleSprintSelection={selectSprint}
      handleProjectClick={(e: any) =>
        scrollToProject(shortenSprintName(e.indexValue), e.data[`${e.id}Key`])
      }
    />
  ) : (
    <></>
  )
}
