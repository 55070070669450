import React, { FC } from "react";
import { ModalHeader } from "@atlaskit/modal-dialog"
import { useTranslation } from "react-i18next";
import { ModalHeaderSpacer } from "../../../../../../styled/ModalHeaderSpacer";
import Button from "@atlaskit/button";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { StoryKey } from "../../../../../../styled/StoryKey";
import { useJiraSupport } from "./context/JiraSupportContext";

export const JiraSupportFormHeader: FC = () => {
  const { t } = useTranslation();
  const { projectName, closeModal } = useJiraSupport();

  return (
    <ModalHeader>
      <StoryKey>{projectName}</StoryKey>
      <ModalHeaderSpacer />
      {t("page.sprints.support.modal.header")}
      <ModalHeaderSpacer />
      <Button
        onClick={closeModal}
        appearance="subtle"
        iconBefore={<CrossIcon label={t("page.customer.modal.close")} />}
      />
    </ModalHeader>
  )
}
