import React, { FunctionComponent } from "react"

const TAB_SELECTOR_HEIGHT = 48;

interface IDashboardPanelProps {
  index: number
  value: number
}

export const DashboardPanel: FunctionComponent<IDashboardPanelProps> = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ height: `calc(100vh - ${TAB_SELECTOR_HEIGHT}px - 2em)`, overflowY: "auto"}}
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}
