import React, { FC, useMemo } from "react";
import { Card, Section } from "../../styled/login";
import Flag from "@atlaskit/flag";
import Error from "@atlaskit/icon/glyph/error";
import { FormikFieldText } from "../../formik/formik-field-text";
import Button from "@atlaskit/button";
import Spinner from "@atlaskit/spinner";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useLoginForm } from "./context/LoginFormContext";

export const LoginForm: FC = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { loading, requestError } = useLoginForm();

  const showPassword = useMemo(() => !formik.getFieldMeta('email').error, [formik]);

  return (
    <Section>
      <Card>
        { requestError
          ? <div style={ {marginBottom: "1.5em"} }>
            <Flag
              appearance="error"
              title={ t("login.flag") }
              description={ requestError }
              icon={ <Error label="Error icon" secondaryColor="#E3001B"/> }
              isDismissAllowed
            />
          </div>
          : null }
        <FormikFieldText
          valueName="email"
          label={ t("login.email.label") }
          required
          disabled={ loading }
        />
        { showPassword ? (
          <FormikFieldText
            valueName="password"
            type="password"
            label={ t("login.password.label") }
            required
            disabled={ loading }
          />
        ) : null }

        <Button
          appearance="primary"
          type="submit"
          shouldFitContainer
          onClick={ () => formik.submitForm() }
          isDisabled={ !formik.isValid || loading }
        >
          { loading
            ? <div className="wom-button-spinner"><Spinner/> <span>{ t("login.status.progress.message") }</span></div>
            : (showPassword ? t("login.log_in") : t("login.next")) }
        </Button>
      </Card>
    </Section>
  )
}
