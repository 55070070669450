import * as React from "react";
import debounceRender from "react-debounce-render";
import { Query, QueryResult } from "react-apollo";
import { issuesForSprint as ISSUES_QUERY } from "../../api/graphql/query/issues";
import { Checkbox } from "@atlaskit/checkbox";
import { SprintsAndIssues_issues as Issue } from "../../api/graphql/query/types/SprintsAndIssues";
import Spinner from "@atlaskit/spinner";
import { Fragment, useEffect, useState } from "react";

interface IStoryListProps {
  projectKey: string;
  sprintNames: string[];
  updateSelected(issues: Issue[]): void;
}

const StoryList = (props: IStoryListProps) => {
  const [active, setActive] = useState<Issue[]>([]);
  useEffect(() => props.updateSelected(active));

  const isAllSelected = (issues: Issue[]): boolean => {
    const selectedStoryKeys = getSelectedStoryKeys();
    const storyKeys = issues.map(issue => issue.key)
    
    return storyKeys.every(key => selectedStoryKeys.includes(key))
  };

  const getSelectedStoryKeys = () => active.map((issue: Issue) => issue.key);

  const hasSelectedStory = (issues: Issue[]): boolean =>{
    const storyKeys = issues.map(issue => issue.key)
    return getSelectedStoryKeys().some(key => storyKeys.includes(key))
  }

  const handleTicketChange = (issue: Issue) => (event: any) => {
    if (event.target.checked) {
      // select issue
      setActive([...active, issue]);
    } else {
      // deselect issue
      setActive(
        active.filter(issue => issue.key !== event.target.value)
      );
    }
  };

  const handleSprintChange = (issues: Issue[]) => (event: any) => {    
    if (event.target.checked) {
      // Select all from sprint
      setActive([...active, ...issues])
    } else {
      // Deselect issues
      setActive(
        active.filter(issue => !issues.includes(issue))
      )
    }
  };

  return (
    <Fragment>
      <p>Wähle relevante Stories aus:</p>
      {props.sprintNames.map((sprintName) => (
        <Query
          key={sprintName + props.projectKey}
          query={ISSUES_QUERY}
          variables={{ projectKey: props.projectKey, sprintName }}
          fetchPolicy="cache-first"
        >
          {({ data, loading, error, refetch }: QueryResult) => {
            return (
              <>
                <Checkbox
                  key={sprintName}
                  isIndeterminate={!isAllSelected(data.issues || [])}
                  isChecked={hasSelectedStory(data.issues || [])}
                  onChange={handleSprintChange(data.issues || [])}
                  label={
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div>
                        Sprint: <b>{sprintName}</b>
                      </div>
                      {loading && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "1.3em",
                            opacity: 0.7,
                          }}
                        >
                          <Spinner size="small" />
                          <div style={{ marginLeft: "0.7em" }}>
                            Tickets werden geladen
                          </div>
                        </div>
                      )}
                    </div>
                  }
                  value={sprintName}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "24px",
                  }}
                >
                  {(data.issues || []).map((issue: Issue) => (
                    <Checkbox
                      key={issue.key}
                      isChecked={active
                        .map((issue: Issue) => issue.key)
                        .includes(issue.key)}
                      onChange={handleTicketChange(issue)}
                      label={
                        <>
                          № <b>{issue.key}</b> {issue.summary}
                        </>
                      }
                      value={issue.key}
                    />
                  ))}
                </div>
              </>
            );
          }}
        </Query>
      ))}
    </Fragment>
  );
};

export default debounceRender(StoryList, 300, { leading: false });
