import { gql } from "apollo-boost"
import { Query } from "react-apollo"
import { SprintsAndIssues } from "./types/SprintsAndIssues"

export default gql`
  query SprintsAndIssues($projectKey: String!) {
    project(key: $projectKey) {
      key
      name
      productOwner {
        name
      }
    }
    sprints(projectKey: $projectKey) {
      name
      start
      end
    }
    issues(projectKey: $projectKey) {
      id
      key
      sprint {
        name
        goal
      }
      type {
        name
      }
      summary
      status {
        name
      }
      priority {
        name
      }
      estimation
      description
      labels
      assignee {
        name
      }
    }
  }
`

export class SprintsAndIssuesQuery extends Query<SprintsAndIssues> {
}
