import { LanguageCode } from "../api/graphql/graphql-global-types"

interface LanguageProperties {
  handle: string
  name: string
  code: LanguageCode
}


export const LANGUAGES: Record<LanguageCode, LanguageProperties> = {
  DE: {
    handle: "de",
    name: "German",
    code: LanguageCode.DE
  },
  EN: {
    handle: "en",
    name: "English",
    code: LanguageCode.EN
  },
}
export const DEFAULT_LANG = LANGUAGES.DE

