import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import * as _ from "lodash"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell, { TableCellProps } from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableFooter from "@material-ui/core/TableFooter"
import { Grid, makeStyles } from "@material-ui/core"
import * as __ from "lodash"
import {
  ISprintPreview,
  ISprintPreviewProject,
} from "../../../../../../api/graphql/query/sprint-preview"

const USER_STORY_BUBBLE_COLOR = "#64BA3B";
const TASK_BUBBLE_COLOR = "#4BADE8";
const BUG_BUBBLE_COLOR = "#E5483A";

interface ITableColumnProps extends TableCellProps {
  label: string
  labelClassName?: string
  imageUrl?: string
  project?: ISprintPreviewProject
}

interface ISprintPreviewTableProps {
  data: ISprintPreview[]
}

const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor: "#fafafa",
    position: "sticky",
    left: 0,
  },
  labelText: {
    width: "100%",
  },
  header: {
    zIndex: 1000,
  },
  footerLabel: {
    bottom: 0,
    zIndex: 1000,
  },
  footerColumn: {
    bottom: 0,
  },
  projectImg: {
    height: 25,
    paddingRight: theme.spacing(1),
  },
  amountsContainer: {
    whiteSpace: "nowrap"
  },
  issueAmount: {
    borderRadius: "20px",
    padding: "2px 8px",
    margin: "0px 1px",
    color: "white"
  },
  storyAmount: { 
    background: USER_STORY_BUBBLE_COLOR,    
  },
  taskAmount: { 
    background: TASK_BUBBLE_COLOR,    
  },
  bugAmount: { 
    background: BUG_BUBBLE_COLOR,    
  }
  
}))

export const SprintPreviewTable: FunctionComponent<ISprintPreviewTableProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const sprints = props.data

  const headers: ITableColumnProps[] = [
    {
      label: t("page.dashboard.burned_story_points.table.headers.projects"),
      id: "projects",
      align: "left",
      className: `${classes.label} ${classes.header}`,
    },
    ...sprints.map(
      (sprint): ITableColumnProps => ({
        label: `Sprint ${sprint.name}`,
        id: `sprint-${sprint.name}`,
        align: "center",
      })
    ),
  ]
  const projects = _.uniqBy(
    _.flatMap(sprints, s => s.projects),
    project => project.key
  )

  const rows = __.orderBy(projects, p => p.key).map(project => {
    const columns: ITableColumnProps[] = [
      {
        label: `${project.key} - ${project.name}`,
        align: "left",
        className: classes.label,
        imageUrl: project.avatarUrl,
      },
    ]
    for (const sprint of sprints) {
      const projectForSprint = sprint.projects.find(
        sprintProject => sprintProject.key === project.key
      )
      columns.push({
        label:
          projectForSprint && projectForSprint.storyPoints > 0
            ? `${projectForSprint.storyPoints}  SP`
            : "-",
        align: "center",
        labelClassName: classes.labelText,
        project: projectForSprint,
      })
    }
    return columns
  })

  const footer: ITableColumnProps[] = [
    {
      label: t("page.dashboard.sprint_preview.table.footers.sum"),
      id: "footer",
      align: "left",
      className: `${classes.label} ${classes.footerLabel}`,
    },
    ...sprints.map(
      (sprint): ITableColumnProps => ({
        label: `${__.sumBy(sprint.projects, p => p.storyPoints)} SP`,
        id: `sprint-${sprint.name}-total`,
        align: "center",
        className: `${classes.label} ${classes.footerColumn}`,
      })
    ),
  ]

  return (
    <TableContainer style={{ maxHeight: `100%`, overflowY: "auto" }}>
      <Table stickyHeader={true} size="small" aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headers.map((column, index) => {
              const { label, ...rest } = column
              return (
                <TableCell key={index} {...rest}>
                  {label}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!rows.length && (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <Grid container justifyContent="center" alignContent="center">
                  <span>{t("page.dashboard.sprint_preview.table.empty")}</span>
                </Grid>
              </TableCell>
            </TableRow>
          )}
          {rows.length &&
            rows.map((row, i) => (
              <TableRow key={i}>
                {row.map((column, index) => {
                  const { label, imageUrl, project, labelClassName, ...rest } = column
                  return (
                    <TableCell key={index} {...rest}>
                      <Grid container alignItems="center" direction="column">
                        <Grid item container alignItems="center">
                          {imageUrl && (
                            <img src={imageUrl} alt={label} className={classes.projectImg} />
                          )}
                          <span className={labelClassName}>{label}</span>
                        </Grid>
                        {project && !!project.storyPoints && (
                          <Grid item container justifyContent="center" alignItems="center" className={classes.amountsContainer}>
                            <Grid item className={`${classes.issueAmount} ${classes.storyAmount}`}>{project.issueAmounts.stories ? project.issueAmounts.stories : "-"}</Grid>
                            <Grid item className={`${classes.issueAmount} ${classes.taskAmount}`}>{project.issueAmounts.tasks ? project.issueAmounts.tasks : "-"}</Grid>
                            <Grid item className={`${classes.issueAmount} ${classes.bugAmount}`}>{project.issueAmounts.bugs ? project.issueAmounts.bugs : "-"}</Grid>
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {footer.map((column, index) => {
              const { label, ...rest } = column
              return (
                <TableCell key={index} {...rest}>
                  {label}
                </TableCell>
              )
            })}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
