import { RouteProps } from "react-router-dom"
import WelcomePage from "./components/pages/dummy/Welcome"
import StatusPage from "./components/pages/status/Status"
import LoginPage from "./components/pages/auth/Login"
import LogoutPage from "./components/pages/auth/Logout"
import ProjectsPage from "./components/pages/project/Projects"
import RoadmapPage from "./components/pages/project/Roadmap"
import SprintsPage from "./components/pages/project/Sprints/Sprints"
import GenerateRoadmapPage from "./components/pages/project/GenerateRoadmap"
import { ERPPage } from "./components/pages/erp/ERPPage"
import AnalysisPage from "./components/pages/erp/AnalysisPage"
import { DashboardPage } from "./components/pages/dashboard/DashboardPage"
import { CustomersPage } from "./components/pages/customers/CustomersPage"

export interface IRouteDeclaration extends RouteProps {
  path: string
  handle?: string
}

interface IRouteDictionary {
  HOME: IRouteDeclaration
  STATUS: IRouteDeclaration
  LOGIN: IRouteDeclaration
  ERP: IRouteDeclaration
  RESOURCES: IRouteDeclaration
  ANALYSIS: IRouteDeclaration
  LOGOUT: IRouteDeclaration
  PROJECTS: IRouteDeclaration
  PROJECT_ROADMAP: IRouteDeclaration
  PROJECT_ROADMAP_GENERATE: IRouteDeclaration
  PROJECT_ROADMAP_ISSUE: IRouteDeclaration
  PROJECT_SPRINTS: IRouteDeclaration
  // PROJECT_SPRINTS_ISSUE: IRouteDeclaration
  DASHBOARD: IRouteDeclaration
  CUSTOMERS: IRouteDeclaration
}

export const ROUTE_DICTIONARY: IRouteDictionary = {
  HOME: {
    path: "/",
    component: WelcomePage,
    exact: true,
    handle: "home",
  },
  STATUS: {
    path: "/status",
    component: StatusPage,
    handle: "status",
  },
  LOGIN: {
    path: "/login",
    component: LoginPage,
    handle: "login",
  },
  ERP: {
    path: "/erp",
    component: ERPPage,
    handle: "erp",
  },
  RESOURCES: {
    path: "/resources",
    component: ERPPage,
    handle: "resources",
  },
  ANALYSIS: {
    path: "/analysis",
    component: AnalysisPage,
    handle: "analysis",
  },
  LOGOUT: {
    path: "/logout",
    component: LogoutPage,
    handle: "logout",
  },
  PROJECTS: {
    path: "/projects",
    component: ProjectsPage,
    exact: true,
    handle: "projects",
  },
  PROJECT_ROADMAP: {
    path: "/projects/:key/roadmap",
    exact: true,
    component: RoadmapPage,
    handle: "project_roadmap",
  },
  PROJECT_ROADMAP_GENERATE: {
    path: "/projects/:key/roadmap/generate",
    exact: true,
    component: GenerateRoadmapPage,
    handle: "project_roadmap_generate",
  },
  PROJECT_ROADMAP_ISSUE: {
    path: "/projects/:key/roadmap/:issue",
    exact: true,
    component: SprintsPage,
    handle: "project_roadmap_issue",
  },
  PROJECT_SPRINTS: {
    path: "/projects/:key/sprints/:sprintName?",
    exact: true,
    component: SprintsPage,
    handle: "project_sprints",
  },
  DASHBOARD: {
    path: "/dashboard",
    exact: true,
    component: DashboardPage,
    handle: "dashboard",
  },
  CUSTOMERS: {
    path: "/customers",
    exact: true,
    component: CustomersPage,
    handle: "customers",
  },
}

const routes: IRouteDeclaration[] = Object.values(ROUTE_DICTIONARY)

export default routes
