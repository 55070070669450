import { gql } from "apollo-boost"

export const DOWNLOAD_ISSUE_ATTACHMENT = gql`
  query DownloadAttachment($attachmentId: String!) {
    downloadAttachment(attachmentId: $attachmentId) {
      base64
      filename
      mimeType
      size
    }
  }
`
