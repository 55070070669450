import { gql } from "apollo-boost"
import { Mutation } from "react-apollo"
import { Recommission, RecommissionVariables } from "./types/Recommission"

export default gql`
mutation Recommission(
  $projectKey: String!
  $number: String!
  $version: String!
  $spEur: Float!
  $storyKeys: [String!]!
) {
  recommission(
    projectKey: $projectKey
    number: $number
    version: $version
    spEur: $spEur
    storyKeys: $storyKeys
  ) {
    base64data
    contentType
    filename
  }
}`

export class RecommissionMutation extends Mutation<Recommission, RecommissionVariables> {}
