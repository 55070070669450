import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata_customerMetadata
} from '../../../../api/graphql/query/types/GetJiraProjectsWithMetadata';
import { useTranslation } from 'react-i18next';

interface ICustomerMetadataCellProps {
  customerData: GetJiraProjectsWithMetadata_getJiraProjectsWithMetadata_customerMetadata;
}

export const CustomerMetadataCell: FunctionComponent<ICustomerMetadataCellProps> = ({ customerData }) => {
  const { t } = useTranslation();
  const {
    companyName,
    companyType,
    firstname,
    lastname,
    salutation,
    streetName,
    streetNumber,
    postalCode,
    city,
    country,
    vatId,
  } = customerData;

  return (
    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
      <Grid item xs>
        <Typography variant="body2">{`${companyName} ${companyType}`}</Typography>
      </Grid>

      <Grid item xs>
        <Typography variant="body2">{`${t('page.customer.vat')} ${vatId}`}</Typography>
      </Grid>

      <Grid item xs>

        <Typography
          variant="body2"
          style={{ textTransform: 'capitalize' }}
        >
          {`${salutation} ${firstname} ${lastname}`}
        </Typography>
      </Grid>

      <Grid item xs>
        <Typography variant="body2">{`${streetName} ${streetNumber}`}</Typography>
      </Grid>

      <Grid item xs>
        <Typography variant="body2">{`${postalCode} ${city}`}</Typography>
      </Grid>

      <Grid item xs>
        <Typography variant="body2">{country}</Typography>
      </Grid>
    </Grid>
  );
};
