import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLogin } from "./context/LoginContext";
import { Footer, Header, Wrapper } from "../../styled/login";
import Spinner from "@atlaskit/spinner";
import { IVersionResponse } from "../status/context/StatusContext";
import { useAuthContext } from "../../../context/AuthContext";
import { LoginFormProvider } from "./context/LoginFormContext";
import { LoginForm } from "./LoginForm";

interface ILoginContentProps {
  history: any;
}

export const LoginContent: FC<ILoginContentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { authin } = useAuthContext();
  const { versionLoading, backendVersion } = useLogin();
  const [versionFrontend, setVersionFrontend] = useState<string>('');


  useEffect(() => {
    fetch('/version')
      .then((response) => response.json())
      .then((data: IVersionResponse) => {
        setVersionFrontend(data.version);
      })
      .catch(e => {
        // fail silently
      })
  }, [setVersionFrontend])

  return (
    <Wrapper>
      <Header>
        <img src="./logo.png" alt={ t("global.denovo") }/>
        <h1>{ t("global.company") } <em>{ t("global.product") }</em></h1>
      </Header>

      <LoginFormProvider authin={authin} history={history}>
        <LoginForm />
      </LoginFormProvider>


      <Footer>
        <div>
          <span>Version { versionFrontend } → { versionLoading
            ? (<Spinner size="small" invertColor/>)
            : backendVersion
          }</span>
          <ul className="dc-fine-print">
            <li>
              <a href="https://www.denovo.at/agb/" target="_blank" rel="noopener noreferrer">
                <span>{ t("global.tos.title") }</span>
              </a>
            </li>
            <li>
              <a href="https://www.denovo.at/impressum/" target="_blank" rel="noopener noreferrer">
                <span>{ t("global.imprint.title") }</span>
              </a>
            </li>
          </ul>
        </div>
      </Footer>
    </Wrapper>
  );
}
