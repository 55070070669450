import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"
import { useGetSprintsQuery } from "../../../../../api/backend/graphql/generated"
import { GetSprintsQueryResults } from "../../../../../api/backend/graphql/types"
import { ApolloError } from "@apollo/client"
import __ from "lodash"
import { useSnackbar } from "../../../../../context/SnackbarContext"
import { SortType } from "../../../../../@types/sorting";


interface ISprintsContextProps {
  projectKey: string | null
  setProjectKey: React.Dispatch<React.SetStateAction<string | null>>
  sprints: GetSprintsQueryResults
  loading: boolean
  showBacklog: boolean
  projectName: string | null
  sortBy: SortType
  setSortBy: (value: SortType) => void
  searchEl: RefObject<HTMLInputElement>
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  hasSupportTicket: boolean;
  hasSupportContract: boolean;
}

export const SprintsContext = createContext<ISprintsContextProps>({} as ISprintsContextProps)

interface ISprintsContextProviderProps extends PropsWithChildren<any> {}

export const SprintsContextProvider: FC<ISprintsContextProviderProps> = ({ children }) => {
  const { showSnackbar } = useSnackbar()
  const [projectKey, setProjectKey] = useState<string | null>(null)
  const [sortBy, setSortBy] = useState<SortType>('newest')

  const searchEl = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState<string>("")

  const { data, loading } = useGetSprintsQuery({
    skip: __.isNil(projectKey),
    fetchPolicy: "cache-first",
    variables: {
      projectKey: projectKey ?? "",
    },
    onError: (error: ApolloError) => {
      showSnackbar({
        message: error.message,
        severity: "error",
      })
    },
  })
  const sprints = useMemo(() => {
    if (data && data.sprints) {
      switch (sortBy) {
        case 'newest':
          return __.orderBy(data.sprints, 'start')
        case 'oldest':
          return __.orderBy(data.sprints, 'start', 'desc')
        case 'az':
          return __.orderBy(data.sprints, 'name', 'desc')
        case 'za':
          return __.orderBy(data.sprints, 'name')
        default:
          return __.orderBy(data.sprints, 'start')
      }
    }

    return []
  }, [data, sortBy]);
  const projectName = (data && data.project.name) || null
  const showBacklog = (data && data.project.showBacklog) || false
  const hasSupportTicket = (data && data.project.hasSupportTicket) || false
  const hasSupportContract = (data && data.project.customerMetadata && data.project.customerMetadata.hasSupportContract) || false

  return (
    <SprintsContext.Provider
      value={{
        loading,
        projectKey,
        setProjectKey,
        sprints,
        projectName,
        showBacklog,
        searchEl,
        search,
        setSearch,
        sortBy,
        setSortBy,
        hasSupportTicket,
        hasSupportContract,
      }}
    >
      {children}
    </SprintsContext.Provider>
  )
}

export const useSprints = () => useContext(SprintsContext)
