export const en = {
  hello: "Hello World!",
  denovo: "Denovo",
  global: {
    company: "Customer",
    product: "Care",
    title: "Denovo Care",
    imprint: {
      title: "Imprint",
    },
    tos: {
      title: "General Terms and Conditions",
    },
    sort_by: {
      title: "Sort By",
      newest: "Newest to oldest",
      oldest: "Oldest to newest",
      az: "Alphabetically a-z",
      za: "Alphabetically z-a",
    },
  },
  system: {
    status: {
      page: {
        title: "Status",
        column: {
          description: {
            title: "Description",
          },
          value: {
            title: "Settings",
          },
        },
        version: {
          description: "Version",
          webclient: "Webclient",
          commit: "Commit",
          ahead: "Commits ahead",
          branch: "Development branch",
          environment: "Environment",
        },
        endpoints: {
          description: "Endpoints",
          api: "API",
          subs: "Subscriptions",
        },
        auth: {
          description: "Authentication",
          logged: "Logged in",
          not_logged: "Not logged in",
          user: "User",
          abilities: "Abilities",
          none: "None",
        },
      },
    },
  },
  navigation: {
    dashboard: "Dashboard",
    res: "RES",
    erp: "ERP",
    projects: "Projects",
    customers: "Customers",
    version: "Version",
    logout: "Logout",
  },
  drawer: {
    erp: "Show ERP",
    version: "Show Version",
    logout: "Logout",
  },
  login: {
    email: {
      label: "Email (Denovo Care)",
    },
    password: {
      label: "Password",
    },
    status: {
      progress: {
        message: "Logging-in...",
      },
    },
    form: {
      errors: {
        email: "Wrong email format",
        required: "Required field",
      },
    },
    flag: "Oh dear, that was our mistake!",
    log_in: "Log in",
    next: "Next",
  },
  logout: {
    title: "Log out",
    subtitle: "from Denovo Care",
    info: {
      greeting: "Hello {{name}},",
      text: "Do you want to log out from Denovo Care temporarily to protect your account from unauthorised access? You can log back in at any time.",
    },
    action: {
      logout: "Stay logged in",
      cancel: "Yes, log out",
    },
  },
  issue: {
    attachment: {
      attachments: "Attachments",
    },
    comment: {
      comments: "Comments",
      author: "I",
      add: "Add a comment...",
      submit: "Save",
    },
    no_tickets: "No tickets found",
    issue_loading: "Issues are loading...",
    issue_status: {
      in_review: "In Review",
      in_progress: "In Progress",
      unknown: "Unknown",
      done: "Done",
      waiting_for_merge: "Waiting for Merge",
      verify: "Verify",
      to_do: "To Do",
      blocked: "Blocked",
      approved: "Approved",
      requested: "Requested",
    },
    support: {
      title: "Support Management",
      support: "Create support request",
    },
  },
  page: {
    analysis: {
      header: {
        title: "Key figures & Analysis",
      },
    },
    erp: {
      warning: {
        calendar_unavailable: "Vacation calendar is not available",
      },
      update: "Update in progress",
      retrieve: "Retrieving planning",
      update_planning: "Update planning view",
      no_projects: "No projects were found.",
      no_sprints: "No sprints were found.",
      refresh: "Refresh",
    },
    projects: {
      header: {
        title: "Your Projects",
      },
      loading: {
        message: "Data loading...",
      },
      action: {
        show_roadmap: {
          title: "Show Roadmap",
        },
        generate_roadmap: {
          title: "Generate Roadmap",
        },
      },
      search: "Search in projects ...",
      filter: "Press Ⓕ to filter",
      esc: "Press ESC to reset the filter",
      enter: "Press ⮐ to jump to the top project",
    },
    sprints: {
      patience: "Please be patient for a moment",
      support_btn: "Support request",
      support: {
        modal: {
          header: "Support",
          submit_btn: "Submit",
          title: "Title",
          description: "Description",
          priority: "Priority",
          required: "Required field",
          attachments: "Attachments",
          success_message: "Your support request has been submitted."
        },
      },
      header: {
        title: "Sprints",
      },
      loading: {
        message: "Loading sprints...",
      },
      input: "Search in sprints ...",
      helperText: {
        filter: "Press Ⓕ to filter",
        quick_actions: "Press ⮐ to open quick actions for the top sprint",
        project_overview: "Press ⌥ + Backspace to return to the project overview",
      },
      list: {
        end: "Start: {{startDate}} - End: {{endDate}}",
        no_development: "Not intended for development",
        no_sprints: "No sprints were found.",
      },
      actions: {
        title: "Project management",
        review_protocol: "Review Protocol",
        offer_doc: "Offer document",
        user_story_doc: "User Stories Document",
        user_story_cards: "User-Story Cards",
        follow_up: "Follow-up order",
      },
      general_offer: {
        toast_error: "The requested quote document could not be created",
        heading: "Generate Offer",
        footer_btn: "Submit",
        enter_data: "Please enter the basic data of the offer document:",
        version: "Version",
        number: "Number",
        sp_rate: "SP rate in €",
        requirements: "Non-functional requirements",
        language: "Language",
      },
      general_sprint_review: {
        heading: "Generate Sprint Review",
        enter_data: "Please enter the basic data of the review document:",
        footer_btn: "Submit",
        language: "Language",
      },
      filters: {
        completed_sprints_label: "Completed Sprints",
        completed_sprints_tooltip: "Show/Hide completed sprints",
        future_sprints_label: "Future Sprints",
        future_sprints_tooltip: "Show/Hide future sprints",
        non_standard_sprints_label: '"Non Standard Sprints"',
        non_standard_sprints_tooltip:
          "Show/Hide sprints not following the year/sprint-nr naming convention",
        backlog_label: "Backlog",
        backlog_tooltip: "Backlog",
      },
    },
    roadmap: {
      header: {
        title: "Creating roadmap",
      },
      loading: {
        message: "Sprints loading...",
      },
      table: {
        heading: "Roadmap",
        from: "From",
        review: "Review",
        to: "To",
        select_placeholder: "Do not create a review",
        backlog: "Backlog",
        sprint_issue: "Sprint / Issue",
        description: "Description",
        estimation: "Estimation",
        no_sprints: "No backlog sprints",
      },
      button: "Create roadmap",
    },
    generate_roadmap: {
      header: {
        title: "Create Roadmap",
      },
      loading: {
        message: "Sprints loading...",
      },
    },
    dashboard: {
      tab_titles: {
        vacation_forecast: "Absences Overview",
        sprint_velocity: "Sprint-Velocity",
        burned_story_points: "Burnt Story-Points",
        sprint_preview: "Sprint Preview",
      },
      burned_story_points: {
        filter: {
          amount_of_sprints: "Number of Sprints",
        },
        loading: {
          message: "Sprints loading...",
        },
        error: {
          message: "Sprints could not be loaded due to an unknown error.",
        },
        table: {
          empty: "No projects with finished story points are found.",
          headers: {
            projects: "Projects",
          },
          footers: {
            sum: "Total",
          },
        },
      },
      sprint_preview: {
        loading: {
          message: "Sprints loading...",
        },
        error: {
          message: "Sprints could not be loaded due to an unknown error.",
        },
        table: {
          empty: "No projects are found.",
          headers: {
            projects: "Projects",
          },
          footers: {
            sum: "Total",
          },
        },
      },
      sprint_velocity: {
        table: {
          headers: {
            number_of_sprints: "Number of Previous Sprints",
            average_velocity: "Average Velocity",
          },
          error: "Sprints could not be loaded.",
        },
      },
      vacation_forecast: {
        departments: "Departments",
        countries: "Countries",
        table: {
          headers: {
            person: "Person",
            department: "Department",
          },
          footer: {
            sum: "Sum",
            days: "Days",
          },
        },
      },
    },
    customer: {
      header: "Customers",
      search: "Search in customers ...",
      filter: "Press Ⓕ to filter",
      esc: "Press ESC to reset the filter",
      enter: "Press ⮐ to jump to the top project",
      vat: "VAT Nr.:",
      table: {
        headers: {
          project_name: "Project Name",
          customer_data: "Customer Data",
          dashboard: "Dashboard",
          actions: "Actions",
        },
        button: {
          edit: "Edit Data",
        },
        error: {
          message: "An error occurred at fetching customer metadata",
        },
      },
      modal: {
        edit: "Edit",
        create: "Create",
        customer_metadata: "Customer Metadata",
        close: "Close",
        company_name: "Company Name",
        company_type: "Company Type",
        title: "Title",
        firstname: "Firstname",
        lastname: "Lastname",
        street_name: "Street Name",
        street_number: "Street Number",
        postal_code: "Postal Code",
        city: "City",
        country: "Country",
        vat_id: "VAT ID",
        dashboard_name: "Dashboard Name",
        dashboard_color: "Dashboard Color",
        dashboard_color_hint: "This should be a HTML HEX Color",
        has_support_contract: "Has Support Contract",
        success_message: "Successfully {{key}} customer metadata",
        error_message: "Could not {{key}} this customer metadata",
        field_messages: {
          required: "Required Field",
          invalid_postal_code: "Invalid Postal Code",
          invalid_color: "Invalid HEX color value",
        },
      },
    },
  },
  fields: {
    file_select: {      
      drop_area: "Click or drop files here",
      file_error: "File Error",
      max_files_error: "You can upload up to {{maxFiles}} files."
    },
  },
}
