import { Issues_issues } from '../api/graphql/query/types/Issues';

export const sPStyle = (issue: Issues_issues): string => {
  if (issue.SP && issue.SP !== '❓' && (!issue.SPEstimate || issue.SPEstimate === '❓' || issue.SPEstimate === '-')) {
    return `${issue.SP} SP`;
  } else if (issue.SPEstimate && issue.SPEstimate !== '❓' && issue.SPEstimate !== '-') {
    return `${issue.SPEstimate} SP estimate`;
  } else {
    return '';
  }
};
