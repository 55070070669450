import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from "react";
import { useLoginFormValidation } from "../hooks/use-login-form-validation";
import { SigninMutationVariables, useSigninMutation } from "../../../../api/backend/graphql/generated";
import { useGraphQL } from "../../../../api/backend/context/graphql-context";
import { ApolloError } from "@apollo/client";
import { Form, FormikProvider, useFormik } from "formik";
import { ROUTE_DICTIONARY } from "../../../../routes";

const INITIAL_FORM_VALUE: SigninMutationVariables = {
  email: '',
  password: '',
};

interface ILoginFormContextProps {
  loading: boolean;
  requestError: string | null;
}

export const LoginFormContext = createContext<ILoginFormContextProps>({} as ILoginFormContextProps);

interface ILoginFormContextProviderProps extends PropsWithChildren<any> {
  authin: (token: string) => void;
  history: any;

}

export const LoginFormProvider: FC<ILoginFormContextProviderProps> = ({ authin, history, children}) => {
  const validationSchema = useLoginFormValidation();
  const [requestError, setRequestError] = useState<string | null>(null);
  const { setAccessToken } = useGraphQL();

  const [signInMutation, {loading}] = useSigninMutation({
    onCompleted: ({authenticate}) => {
      const {token} = authenticate
      setAccessToken(token);
      authin(token);
      setRequestError(null);
      history.push(ROUTE_DICTIONARY.PROJECTS.path)
    },
    onError: (error: ApolloError) => {
      setRequestError(error.message);
    }
  });

  const signIn = useCallback((email: string, password: string) => {
    signInMutation({
      variables: {
        email,
        password
      }
    })
  }, [signInMutation]);

  const formik = useFormik<SigninMutationVariables>({
    initialValues: INITIAL_FORM_VALUE,
    validationSchema,
    validateOnMount: true,
    onSubmit: (data) => {
      const {email, password} = data;
      return signIn(email, password);
    }
  });


  return (
    <FormikProvider value={ formik }>
      <Form onSubmit={ formik.handleSubmit }>
        <LoginFormContext.Provider
          value={{
            requestError,
            loading
          }}
        >
          {children}
        </LoginFormContext.Provider>
      </Form>
    </FormikProvider>
      );
}

export const useLoginForm = () => useContext(LoginFormContext);
