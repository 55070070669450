import * as React from "react"
import moment from "moment-timezone"
import { Mutation } from "react-apollo"
import ModalDialog, { ModalFooter } from "@atlaskit/modal-dialog"
import Textfield from "@atlaskit/textfield"
import Button from "@atlaskit/button"
import Form, { Field } from "@atlaskit/form"
import { toast } from "react-toastify"

import USERSTORY_MUTATION from "../../api/graphql/mutation/user-story"
import { UserStory } from '../../api/graphql/mutation/types/UserStory';

interface ContainerProps {
    children: React.ReactNode;
    className?: string;
}

interface FormProps {
    onSubmit: (e: React.FormEvent | any) => void;
    ref: React.RefObject<HTMLFormElement>;
    onKeyDown: (e: React.KeyboardEvent) => void;
}

interface FieldProps {
    id: string;
    isRequired: boolean;
    isDisabled: boolean;
    isInvalid: boolean;
    onChange: (e: any) => any;
    onBlur: () => any;
    onFocus: () => any;
    value: any;
    'aria-invalid': 'true' | 'false';
    'aria-labelledby': string;
}

interface IGenerateUserStoryState {
    isGeneratingUserStory: boolean
}

interface IGenerateUserStoryProps {
    isOpen: boolean
    projectKey: string
    sprintName: string
    done: () => void
    close: () => void
}

export default class GenerateUserStory extends React.Component<IGenerateUserStoryProps, IGenerateUserStoryState> {
    constructor(props: IGenerateUserStoryProps) {
        super(props)
        this.state = {
            isGeneratingUserStory: false
        }
    }

    handleUserStory = (mutation: Function, projectKey: string, sprintName: string, number: Number, version: string) => {
        this.setState({ isGeneratingUserStory: true })
        mutation({ variables: { projectKey, sprintName, number, version } })
    }

    generateUserStoryHandler = (mutation: Function, projectKey: string, sprintName: string, number: Number, version: string) => {
        return this.handleUserStory(mutation, projectKey, sprintName, number, version)
    }

    handleUserStoryComplete = (response: UserStory) => {
        setTimeout(
            async () => {
                if (response.userStory) {
                    const url = `data:${response.userStory.contentType};base64,${response.userStory.base64data}`
                    const internal = await fetch(url)
                    const blob = await internal.blob()

                    const a = document.createElement('a')
                    a.href = URL.createObjectURL(blob)
                    a.download = response.userStory.filename || `User-Stories-${moment().format("YYYYMMDD")}.docx`
                    a.click()
                    URL.revokeObjectURL(a.href)
                }

                this.props.done()
            },
            300
        )
    }

    handleUserStoryError = () => {
        toast.error("Das angeforderte User-Stories Dokument konnte nicht erstellt werden.")
        this.props.done()
    }

    public render() {
        return (
            <Mutation mutation={USERSTORY_MUTATION} onCompleted={this.handleUserStoryComplete} onError={this.handleUserStoryError}>
                {(userStories: Function) => (
                    <>
                        {this.props.isOpen && <ModalDialog heading="User-Stories generieren" onClose={() => { this.props.close(); this.props.done() }} components={{
                            Container: ({ children, className }: ContainerProps) => (
                                <Form onSubmit={(data: any) => {
                                    this.generateUserStoryHandler(userStories, this.props.projectKey, this.props.sprintName, data.number, data.version);
                                    this.props.close()
                                }}>
                                    {({ formProps }: { formProps: FormProps }) => (
                                        <form {...formProps} className={className}>
                                            {children}
                                        </form>
                                    )}
                                </Form>
                            ), Footer: () =>
                                (<ModalFooter>
                                    <span />
                                    <Button appearance="primary" type="submit">Übernehmen</Button>
                                </ModalFooter>)
                        }}
                        >
                            <p>Bitte gib die Basisdaten des User-Stories Dokument ein:</p>

                            <Field label="Version" name="version" defaultValue="1.0" isRequired>
                                {({ fieldProps }: { fieldProps: FieldProps }) => (
                                    <Textfield placeholder="1.0"
                                        {...fieldProps} />
                                )}
                            </Field>

                            <Field label="Nummer" name="number" defaultValue="999" isRequired>
                                {({ fieldProps }: { fieldProps: FieldProps }) => (
                                    <Textfield
                                        placeholder="999"
                                        {...fieldProps}
                                    />
                                )}
                            </Field>
                        </ModalDialog>}
                    </>
                )}
            </Mutation>
        )
    }
}