export const de = {
  hello: "Hello World!",
  denovo: "Denovo",
  global: {
    company: "Customer",
    product: "Care",
    title: "Denovo Care",
    imprint: {
      title: "Impressum",
    },
    tos: {
      title: "Allgemeine Geschäftsbedingungen",
    },
    sort_by: {
      title: "Sortieren nach",
      newest: "Aktuellster Sprint zuerst",
      oldest: "Ältester Sprint zuerst",
      az: "Alphabetisch a-z",
      za: "Alphabetisch z-a",
    },
  },
  system: {
    status: {
      page: {
        title: "Status",
        column: {
          description: {
            title: "Beschreibung",
          },
          value: {
            title: "Einstellung",
          },
        },
        version: {
          description: "Version",
          webclient: "Webclient",
          commit: "Commit",
          ahead: "Nachträge",
          branch: "Entwicklungszweig",
          environment: "Environment",
        },
        endpoints: {
          description: "Endpunkte",
          api: "API",
          subs: "Subscriptions",
        },
        auth: {
          description: "Authentifizierung",
          logged: "Angemeldet",
          not_logged: "Nicht angemeldet",
          user: "Benutzer",
          abilities: "Fähigkeiten",
          none: "Keine",
        },
      },
    },
  },
  navigation: {
    dashboard: "Dashboard",
    res: "RES",
    erp: "ERP",
    projects: "Projekte",
    customers: "Customers",
    version: "Version",
    logout: "Abmelden",
  },
  drawer: {
    erp: "ERP anzeigen",
    version: "Version anzeigen",
    logout: "Abmelden",
  },
  login: {
    email: {
      label: "Ihre Denovo Care E-Mail Adresse",
    },
    password: {
      label: "Ihr Passwort",
    },
    status: {
      progress: {
        message: "Sie werden angemeldet ...",
      },
    },
    form: {
      errors: {
        email: "Falsches E-Mail-Format",
        required: "Erforderliches Feld",
      },
    },
    flag: "Ohje, das war unser Fehler!",
    log_in: "Einloggen",
    next: "Weiter",
  },
  logout: {
    title: "Abmelden",
    subtitle: "von Denovo Care",
    info: {
      greeting: "Hallo {{name}},",
      text: "möchtest du dich vorübergehend aus denovo care abmelden um deinen Account vor unbefugtem Zugriff zu schützen? Du kannst dich jederzeit wieder anmelden.",
    },
    action: {
      logout: "Angemeldet bleiben",
      cancel: "Ja, abmelden",
    },
  },
  issue: {
    attachment: {
      attachments: "Anhänge",
    },
    comment: {
      comments: "Kommentare",
      author: "Ich",
      add: "Einen Kommentar hinzufügen...",
      submit: "Speichern",
    },
    no_tickets: "Keine Tickets gefunden",
    issue_loading: "Tickets werden geladen ...",
    issue_status: {
      in_review: "In Review",
      in_progress: "In Arbeit",
      unknown: "Unbekannt",
      done: "Fertig",
      waiting_for_merge: "Version wird erstellt",
      verify: "In Qualitätssicherung",
      to_do: "Wartet auf Entwicklung",
      blocked: "Blockiert",
      approved: "Geplant",
      requested: "Nicht beauftragt",
    },
    support: {
      title: "Create support request",
      support: "Create support request",
    },
  },
  page: {
    analysis: {
      header: {
        title: "Kennzahlen & Analyse",
      },
    },
    erp: {
      warning: {
        calendar_unavailable: "Urlaubskalender nicht verfügbar",
      },
      update: "Aktualisierung läuft",
      retrieve: "Planung wird abgerufen",
      update_planning: "Planungsansicht aktualisieren",
      no_projects: "Es wurden keine Projekte gefunden.",
      no_sprints: "Es wurden keine Sprints gefunden.",
      refresh: "Aktualisieren",
    },
    projects: {
      header: {
        title: "Ihre Projekte",
      },
      loading: {
        message: "Daten werden geladen ...",
      },
      action: {
        show_roadmap: {
          title: "Roadmap anzeigen",
        },
        generate_roadmap: {
          title: "Roadmap generieren",
        },
      },
      search: "Suche in Projekten ...",
      filter: "Drücke Ⓕ um zu filterne",
      esc: "Drücke ESC um den Filter zurückzusetzen",
      enter: "Drücke ⮐ um ins oberste Projekt zu springen",
    },
    sprints: {
      patience: "Bitte um einen Moment Geduld",
      support_btn: "Support request",
      support: {
        modal: {
          header: "Support",
          submit_btn: "Speichern",
          title: "Title",
          description: "Beschreibung",
          priority: "Priorität",
          required: "Erforderliches Feld",
          attachments: "Anhänge",
          success_message: "Ihre Support-Anfrage wurde erfolgreich übermittelt."
        },
      },
      header: {
        title: "Sprints",
      },
      loading: {
        message: "Sprints werden geladen ...",
      },
      input: "Suche in Sprints ...",
      helperText: {
        filter: "Drücke Ⓕ um zu filtern",
        quick_actions: "Drücke ⮐ um Schnellaktionen für den obersten Sprint zu öffnen",
        project_overview: "Drücke ⌥ + Backspace um zur Projektübersicht zurückzukehren",
      },
      list: {
        end: "Start: {{startDate}} - Ende: {{endDate}}",
        no_development: "Nicht für die Entwicklung vorgesehen",
        no_sprints: "Es wurden keine Sprints gefunden.",
      },
      actions: {
        title: "Projektmanagement",
        review_protocol: "Review Protokoll",
        offer_doc: "Angebotdokument",
        user_story_doc: "User-Stories Dokument",
        user_story_cards: "User-Story Cards",
        follow_up: "Nachbeauftragung",
      },
      general_offer: {
        toast_error: "Das angeforderte Angebotdokument konnte nicht erstellt werden.",
        heading: "Angebot generieren",
        footer_btn: "Übernehmen",
        enter_data: "Bitte gib die Basisdaten des Angebotsdokuments ein:",
        version: "Version",
        number: "Nummer",
        sp_rate: "SP Satz in €",
        requirements: "Nicht-funktionale Anforderungen",
        language: "Sprache",
      },
      general_sprint_review: {
        heading: "Sprint Review generieren",
        enter_data: "Bitte füllen Sie die Basisdaten des Reviewdokuments aus:",
        footer_btn: "Absenden",
        language: "Sprache",
      },
      filters: {
        completed_sprints_label: "Abgeschlossene Sprints",
        completed_sprints_tooltip: "Abgeschlossene Sprints anzeigen/ausblenden",
        future_sprints_label: "Geplante Sprints",
        future_sprints_tooltip: "Geplante Sprints anzeigen/ausblenden",
        non_standard_sprints_label: '"Nicht-Standard Sprints"',
        non_standard_sprints_tooltip: "Sprints ausserhalb der Namenskonvention anzeigen/ausblenden",
        backlog_label: "Backlog",
        backlog_tooltip: "Backlog",
      },
    },
    roadmap: {
      header: {
        title: "Roadmap erstellen",
      },
      loading: {
        message: "Sprints werden geladen ...",
      },
      table: {
        heading: "Roadmap",
        from: "Von",
        review: "Review",
        to: "Bis",
        select_placeholder: "Kein Review erstellen",
        backlog: "Backlog",
        sprint_issue: "Sprint / Issue",
        description: "Beschreibung",
        estimation: "Estimation",
        no_sprints: "Keine Backlog Sprints",
      },
      button: "Roadmap erstellen",
    },
    generate_roadmap: {
      header: {
        title: "Roadmap erstellen",
      },
      loading: {
        message: "Sprints werden geladen ...",
      },
    },
    dashboard: {
      tab_titles: {
        vacation_forecast: "Urlaubsübersicht",
        sprint_velocity: "Sprint-Velocity",
        burned_story_points: "Burned Story-Points",
        sprint_preview: "Sprint-Vorschau",
      },
      burned_story_points: {
        filter: {
          amount_of_sprints: "Anzahl der Sprints",
        },
        loading: {
          message: "Sprints werden geladen...",
        },
        error: {
          message: "Sprints konnten durch einen unbekannten Fehler nicht geladen werden.",
        },
        table: {
          empty: "Es konnten keine Projekte mit fertigen Story-Points gefunden werden.",
          headers: {
            projects: "Projekte",
          },
          footers: {
            sum: "Summe",
          },
        },
      },
      sprint_preview: {
        loading: {
          message: "Sprints werden geladen...",
        },
        error: {
          message: "Sprints konnten durch einen unbekannten Fehler nicht geladen werden.",
        },
        table: {
          empty: "Es konnten keine Projekte gefunden werden.",
          headers: {
            projects: "Projekte",
          },
          footers: {
            sum: "Summe",
          },
        },
      },
      sprint_velocity: {
        table: {
          headers: {
            number_of_sprints: "Anzahl vergangener Sprints",
            average_velocity: "Durchschnittliche Velocity",
          },
          error: "Sprints konnten nicht geladen werden.",
        },
      },
      vacation_forecast: {
        departments: "Departments",
        countries: "Countries",
        table: {
          headers: {
            person: "Person",
            department: "Department",
          },
          footer: {
            sum: "Sum",
            days: "Days",
          },
        },
      },
    },
    customer: {
      header: "Kunden",
      search: "Suche in Kunden ...",
      filter: "Drücke Ⓕ um zu filterne",
      esc: "Drücke ESC um den Filter zurückzusetzen",
      enter: "Drücke ⮐ um ins oberste Projekt zu springen",
      vat: "VAT Nr.:",
      table: {
        headers: {
          project_name: "Project Name",
          customer_data: "Customer Data",
          dashboard: "Dashboard",
          actions: "Actions",
        },
        button: {
          edit: "Edit Data",
        },
        error: {
          message: "An error occurred at fetching customer metadata",
        },
      },
      modal: {
        edit: "Edit",
        create: "Create",
        customer_metadata: "Customer Metadata",
        close: "Close",
        company_name: "Company Name",
        company_type: "Company Type",
        title: "Title",
        firstname: "Firstname",
        lastname: "Lastname",
        street_name: "Street Name",
        street_number: "Street Number",
        postal_code: "Postal Code",
        city: "City",
        country: "Country",
        vat_id: "VAT ID",
        dashboard_name: "Dashboard Name",
        dashboard_color: "Dashboard Color",
        dashboard_color_hint: "This should be a HTML HEX Color",
        has_support_contract: "Has Support Contract",
        success_message: "Successfully {{key}} customer metadata",
        error_message: "Could not {{key}} this customer metadata",
        field_messages: {
          required: "Erforderliches Feld",
          invalid_postal_code: "Invalid Postal Code",
          invalid_color: "Invalid HEX color value",
        },
      },
    },
  },
  fields: {
    file_select: {
      file_error: "File Error",
      drop_area: "Click or drop files here",
      max_files_error: "You can upload up to {{maxFiles}} files."
    },
  },
}
