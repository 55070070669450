import { gql } from "apollo-boost";
import { Query, QueryResult } from "react-apollo";
import { GetVacationForecast } from "./types/GetVacationForecast";

export default gql`
    query GetVacationForecast($departments: [DepartmentEnum!]!, $countries: [Country!]!, $sprints: [String!]) {
        getVacationForecast(departments: $departments, countries: $countries, sprints: $sprints) {
            name
            vacations
            trainingDays
            sprint
            department
        }
    } 
`

export interface IVacationForecastResult extends QueryResult<GetVacationForecast> {}
export class VacationForecastQuery extends Query<GetVacationForecast> {}