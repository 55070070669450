import * as React from "react"
import moment from "moment-timezone"
import { Mutation } from "react-apollo"
import ModalDialog, { ModalFooter } from "@atlaskit/modal-dialog"
import Button, { ButtonGroup } from "@atlaskit/button"
import Form from "@atlaskit/form"
import { toast } from "react-toastify"

import STORYCARDS_MUTATION from "../../api/graphql/mutation/story-cards"
import { StoryCards } from "../../api/graphql/mutation/types/StoryCards"
import { SprintsAndIssues_issues as Issue } from "../../api/graphql/query/types/SprintsAndIssues"
import StoryList from "./StoryList"

interface ContainerProps {
    children: React.ReactNode;
    className?: string;
}

interface FormProps {
    onSubmit: (e: React.FormEvent | any) => void;
    ref: React.RefObject<HTMLFormElement>;
    onKeyDown: (e: React.KeyboardEvent) => void;
}

interface IGenerateUserStoryState {
    isManuallyModified: boolean
    isGeneratingUserStory: boolean
}

interface IGenerateUserStoryProps {
    isOpen: boolean
    projectKey: string
    sprintNames: Array<string>
    done: () => void
    close: () => void
}

export default class GenerateUserStory extends React.Component<IGenerateUserStoryProps, IGenerateUserStoryState> {
    private selectedStories: Issue[] = []

    constructor(props: IGenerateUserStoryProps) {
        super(props)
        this.state = {
            isManuallyModified: false,
            isGeneratingUserStory: false,
        }
    }

    handleUserStory = (mutation: Function, issueKeys: Array<string>) => {
        this.setState({ isGeneratingUserStory: true })
        mutation({ variables: { issueKeys } })
    }

    generateUserStoryHandler = (mutation: Function, issueKeys: Array<string>) => {
        return this.handleUserStory(mutation, issueKeys)
    }

    handleUserStoryComplete = (response: StoryCards) => {
        setTimeout(
            async () => {
                if (response.storyCards) {
                    const url = `data:${response.storyCards.contentType};base64,${response.storyCards.base64data}`
                    const internal = await fetch(url)
                    const blob = await internal.blob()

                    const a = document.createElement('a')
                    a.href = URL.createObjectURL(blob)
                    a.download = response.storyCards.filename || `Story-Cards-${moment().format("YYYYMMDD")}.docx`
                    a.click()
                    URL.revokeObjectURL(a.href)
                }

                this.props.done()
            },
            300
        )
    }

    handleUserStoryError = () => {
        toast.error("Die Story-Cards konnten nicht erstellt werden.")
        this.props.done()
    }

    public render() {
        return (
            <Mutation mutation={STORYCARDS_MUTATION} onCompleted={this.handleUserStoryComplete} onError={this.handleUserStoryError}>
                {(storyCards: Function) => (
                    <>
                        {this.props.isOpen && <ModalDialog heading="Story-Cards erstellen" onClose={() => { this.props.close(); this.props.done() }} components={{
                            Container: ({ children, className }: ContainerProps) => (
                                <Form onSubmit={(data: any) => {
                                    this.generateUserStoryHandler(storyCards, this.selectedStories.map(story => story.key))
                                    this.props.close()
                                }}>
                                    {({ formProps }: { formProps: FormProps }) => (
                                        <form {...formProps} className={className}>
                                            {children}
                                        </form>
                                    )}
                                </Form>
                            ), Footer: () =>
                                (<ModalFooter>
                                    <span />
                                    <ButtonGroup>
                                        <Button onClick={() => { this.props.done(); this.props.close(); }}>Zurück</Button>
                                        <Button appearance="primary" type="submit">Erstellen</Button>
                                    </ButtonGroup>
                                </ModalFooter>)
                        }}
                        >
                        <StoryList
                            projectKey={this.props.projectKey}
                            sprintNames={this.props.sprintNames}
                            updateSelected={(stories: Issue[]) => {this.selectedStories=stories}}/>
                        </ModalDialog>}
                    </>
                )}
            </Mutation>
        )
    }
}
