import * as React from "react"
import i18next from "i18next"
import { Mutation } from "react-apollo"
import Button from "@atlaskit/button"
import Form, { Field } from "@atlaskit/form"
import ModalDialog, { ModalFooter } from "@atlaskit/modal-dialog"
import Select from "@atlaskit/select"
import { toast } from "react-toastify"
import { LanguageCode } from "../../api/graphql/graphql-global-types"
import SPRINT_REVIEW_MUTATION from "../../api/graphql/mutation/sprint-review"
import { DEFAULT_LANG, LANGUAGES } from "../../languages/language-manager"
import { SprintReview } from "../../api/graphql/mutation/types/SprintReview"

interface ContainerProps {
  children: React.ReactNode
  className?: string
}

interface FormProps {
  onSubmit: (e: React.FormEvent | any) => void
  ref: React.RefObject<HTMLFormElement>
  onKeyDown: (e: React.KeyboardEvent) => void
}

interface FieldProps {
  id: string
  isRequired: boolean
  isDisabled: boolean
  isInvalid: boolean
  onChange: (e: any) => any
  onBlur: () => any
  onFocus: () => any
  value: any
  "aria-invalid": "true" | "false"
  "aria-labelledby": string
}

interface IGenerateReviewState {
  isGeneratingReview: boolean
}

interface IGenerateReviewProps {
  isOpen: boolean
  projectKey: string
  sprintName: string
  done: () => void
  close: () => void
  t: i18next.TFunction
}

export default class GenerateReview extends React.Component<
  IGenerateReviewProps,
  IGenerateReviewState
> {
  constructor(props: IGenerateReviewProps) {
    super(props)
    this.state = {
      isGeneratingReview: false,
    }
  }

  handleReview = (
    mutation: Function,
    projectKey: string,
    sprintName: string,
    lang: LanguageCode
  ) => {
    this.setState({ isGeneratingReview: true })
    mutation({
      variables: {
        projectKey,
        sprintName,
        lang,
      },
    })
  }

  generateReviewHandler = (
    mutation: Function,
    projectKey: string,
    sprintName: string,
    lang: LanguageCode
  ) => {
    return this.handleReview(mutation, projectKey, sprintName, lang)
  }

  handleSprintReviewComplete = (response: SprintReview) => {
    console.log("handleSprintReviewComplete")
    setTimeout(async () => {
      if (response.sprintReview) {
        const url = `data:${response.sprintReview.contentType};base64,${response.sprintReview.base64data}`
        const internal = await fetch(url)
        const blob = await internal.blob()

        const a = document.createElement("a")
        a.href = URL.createObjectURL(blob)
        a.download = response.sprintReview.filename || "download.docx"
        a.click()
        URL.revokeObjectURL(a.href)
      }

      this.props.done();
      this.setState({ isGeneratingReview: false })
    }, 300)
  }

  handleSprintReviewError = () => {
    toast.error("Das angeforderte Abnahmedokument konnte nicht erstellt werden.")
    this.props.done();
    this.setState({ isGeneratingReview: false })
  }

  public render() {
    const { t } = this.props

    return (
      <Mutation
        mutation={SPRINT_REVIEW_MUTATION}
        onCompleted={this.handleSprintReviewComplete}
        onError={this.handleSprintReviewError}
      >
        {(review: Function) => (
          <>
            {this.props.isOpen && (
              <ModalDialog
                heading={t("page.sprints.general_sprint_review.heading")}
                onClose={() => {
                  this.props.close()
                  this.props.done()
                }}
                components={{
                  Container: ({ children, className }: ContainerProps) => (
                    <Form
                      onSubmit={(data: any) => {
                        this.generateReviewHandler(
                          review,
                          this.props.projectKey,
                          this.props.sprintName,
                          data.lang.value
                        )
                        this.props.close()
                      }}
                    >
                      {({ formProps }: { formProps: FormProps }) => (
                        <form {...formProps} className={className}>
                          {children}
                        </form>
                      )}
                    </Form>
                  ),
                  Footer: () => (
                    <ModalFooter>
                      <span />
                      <Button appearance="primary" type="submit">
                        {t("page.sprints.general_sprint_review.footer_btn")}
                      </Button>
                    </ModalFooter>
                  ),
                }}
              >
                <p>{t("page.sprints.general_sprint_review.enter_data")}</p>
                <Field
                  label={t("page.sprints.general_sprint_review.language")}
                  name="lang"
                  isRequired
                  defaultValue={{
                    label: DEFAULT_LANG.name,
                    value: DEFAULT_LANG.code,
                  }}
                >
                  {({ fieldProps }: { fieldProps: FieldProps }) => (
                    <>
                      <Select
                        {...fieldProps}
                        placeholder={DEFAULT_LANG.name}
                        options={Object.values(LANGUAGES).map(lang => ({
                          label: lang.name,
                          value: lang.code,
                        }))}
                      ></Select>
                      <div style={{ height: 100 }}></div>
                      {/* Space for select options */}
                    </>
                  )}
                </Field>
              </ModalDialog>
            )}
          </>
        )}
      </Mutation>
    )
  }
}
