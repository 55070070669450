import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog"
import React, { FC, useContext } from "react"
import { CustomersContext } from "../../../../../context/CustomersContext"
import { EditCustomerMetadata } from "./EditCustomerMetadata"

export const EditCustomerMetadataModal: FC = () => {
  const { showModal, closeModal } = useContext(CustomersContext)

  return (
    <ModalTransition>
      {showModal && (
        <ModalDialog width="x-large" onClose={closeModal}>
          <EditCustomerMetadata />
        </ModalDialog>
      )}
    </ModalTransition>
  )
}
