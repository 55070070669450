import React, { FC, useCallback, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { ABILITIES, useAuthContext } from "../../context/AuthContext"
import Dropdown, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu"
import Spinner from "@atlaskit/spinner"
import KeyHandler, { KEYPRESS } from "react-key-handler"
import IssuesIcon from "@atlaskit/icon/glyph/issues"
import AddItemIcon from "@atlaskit/icon/glyph/add-item"
import BookIcon from "@atlaskit/icon/glyph/book"
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle'
import MoreVerticalIcon from "@atlaskit/icon/glyph/more-vertical"
import EditorMediaWrapRightIcon from "@atlaskit/icon/glyph/editor/media-wrap-right"
import GenerateReview from "./GenerateReview";
import GenerateOffer from "./GenerateOffer";
import GenerateUserStory from "./GenerateUserStory";
import GenerateUserStoryCards from "./GenerateUserStoryCards"
import GenerateRecommission from "./GenerateRecommission";

const KeyboardButton = styled.div`
  display: inline;
  margin-left: 1em;
  padding: 0.1em 0.3em;
  font-size: 0.7em;
  border: 1px solid rgba(0,0,0,0.5);
  border-radius: 5px;
  opacity: 0.5;
  float: right;
`

interface ISprintActionsProps {
  projectKey: string
  sprintName: string
  isOpen?: boolean
  onClose?: () => void
}

const SprintActions: FC<ISprintActionsProps> = (props) => {
  const { isOpen, sprintName, onClose, projectKey} = props
  const { t } = useTranslation()
  const { hasAbility } = useAuthContext();

  const [isGeneratingSprintReview, setIsGeneratingSprintReview] = useState<boolean>(false)
  const [isGeneratingOffer, setIsGeneratingOffer] = useState<boolean>(false)
  const [isGeneratingUserStory, setIsGeneratingUserStory] = useState<boolean>(false)
  const [isGeneratingUserStoryCards, setIsGeneratingUserStoryCards] = useState<boolean>(false)
  const [isGeneratingRecommission, setIsGeneratingRecommission] = useState<boolean>(false)
  const [isSprintReviewOpen, setIsSprintReviewOpen] = useState<boolean>(false)
  const [isOfferOpen, setIsOfferOpen] = useState<boolean>(false)
  const [isUserStoryOpen, setIsUserStoryOpen] = useState<boolean>(false)
  const [isUserStoryCardsOpen, setIsUserStoryCardsOpen] = useState<boolean>(false)
  const [isRecommissionOpen, setIsRecommissionOpen] = useState<boolean>(false)

  const openSprintReviewDialog = useCallback(() => {
    setIsGeneratingSprintReview(true)
    setIsSprintReviewOpen(true)
  }, [setIsGeneratingSprintReview, setIsSprintReviewOpen])

  const openOfferDialog = useCallback(() => {
    setIsGeneratingOffer(true)
    setIsOfferOpen(true)
  }, [setIsGeneratingOffer, setIsOfferOpen])

  const openUserStoryDialog = useCallback(() => {
    setIsGeneratingUserStory(true)
    setIsUserStoryOpen(true)
  }, [setIsGeneratingUserStory, setIsUserStoryOpen])

  const openUserStoryCards = useCallback(() => {
    setIsGeneratingUserStoryCards(true)
    setIsUserStoryCardsOpen(true)
  }, [setIsGeneratingUserStoryCards, setIsUserStoryCardsOpen])

  const openRecommissionDialog = useCallback(() => {
    setIsGeneratingRecommission(true)
    setIsRecommissionOpen(true)
  }, [setIsGeneratingRecommission, setIsRecommissionOpen])

  const wrapCloseHandler = useCallback((handler: Function) => {
    return () => {
      setTimeout(() => {
        onClose && onClose()
      }, 150)
    }
  }, [onClose])


  return (
    <>
      {hasAbility && hasAbility(ABILITIES.PRODUCT_OWNER) && (
        <>
          <Dropdown
            isOpen={isOpen}
            position="bottom right"
            triggerType="button"
            trigger={isGeneratingSprintReview || isGeneratingOffer || isGeneratingUserStory || isGeneratingUserStoryCards || isGeneratingRecommission ? <Spinner size="small" /> : <MoreVerticalIcon size="small" label="Mehr ..." />}
            onOpenChange={(change: { isOpen: boolean }) => {
              !change.isOpen && onClose && onClose()
            }}
          >

            <DropdownItemGroup title={t("page.sprints.actions.title")}>

              <KeyHandler
                keyEventName={KEYPRESS}
                keyValue="r"
                onKeyHandle={wrapCloseHandler(openSprintReviewDialog)}
              />
              <DropdownItem isDisabled={isGeneratingSprintReview} onClick={openSprintReviewDialog} elemBefore={<IssuesIcon label="Review Protokoll" size="small" />}>
                {t("page.sprints.actions.review_protocol")}
                <KeyboardButton>R</KeyboardButton>
              </DropdownItem>

              <KeyHandler
                keyEventName={KEYPRESS}
                keyValue="a"
                onKeyHandle={wrapCloseHandler(openOfferDialog)}
              />
              <DropdownItem isDisabled={isGeneratingOffer} onClick={openOfferDialog} elemBefore={<AddItemIcon label="Angebotdokument" size="small" />}>
                {t("page.sprints.actions.offer_doc")}
                <KeyboardButton>A</KeyboardButton>
              </DropdownItem>

              <KeyHandler
                keyEventName={KEYPRESS}
                keyValue="s"
                onKeyHandle={wrapCloseHandler(openUserStoryDialog)}
              />
              <DropdownItem isDisabled={isGeneratingUserStory} onClick={openUserStoryDialog} elemBefore={<BookIcon label="User Stories" size="small" />}>
                {t("page.sprints.actions.user_story_doc")}
                <KeyboardButton>S</KeyboardButton>
              </DropdownItem>

              <KeyHandler
                keyEventName={KEYPRESS}
                keyValue="c"
                onKeyHandle={wrapCloseHandler(openUserStoryCards)}
              />
              <DropdownItem isDisabled={isGeneratingUserStoryCards} onClick={openUserStoryCards} elemBefore={<EditorMediaWrapRightIcon label="User Stories" size="small" />}>
                {t("page.sprints.actions.user_story_cards")}
                <KeyboardButton>C</KeyboardButton>
              </DropdownItem>

              <KeyHandler
                keyEventName={KEYPRESS}
                keyValue="n"
                onKeyHandle={wrapCloseHandler(openRecommissionDialog)}
              />
              <DropdownItem isDisabled={isGeneratingRecommission} onClick={openRecommissionDialog} elemBefore={<AddCircleIcon label="Nachbeauftragung" size="small" />}>
                {t("page.sprints.actions.follow_up")}
                <KeyboardButton>N</KeyboardButton>
              </DropdownItem>
            </DropdownItemGroup>
          </Dropdown>

          <GenerateReview
            isOpen={isSprintReviewOpen}
            close={() => setIsSprintReviewOpen(false)}
            done={() => setIsGeneratingSprintReview(false)}
            projectKey={projectKey}
            sprintName={sprintName}
            t={t}
          />

          <GenerateOffer
            isOpen={isOfferOpen}
            close={() => setIsOfferOpen(false)}
            done={() => setIsGeneratingOffer(false)}
            projectKey={projectKey}
            sprintName={sprintName}
            t={t}
          />

          <GenerateUserStory
            isOpen={isUserStoryOpen}
            close={() => setIsUserStoryOpen(false)}
            done={() => setIsUserStoryCardsOpen(false)}
            projectKey={projectKey}
            sprintName={sprintName}
          />

          <GenerateUserStoryCards
            isOpen={isUserStoryCardsOpen}
            close={() => setIsUserStoryCardsOpen(false)}
            done={() => setIsGeneratingUserStoryCards(false)}
            projectKey={projectKey}
            sprintNames={[sprintName]}
          />

          <GenerateRecommission
            isOpen={isRecommissionOpen}
            close={() => setIsRecommissionOpen(false)}
            done={() => setIsGeneratingRecommission(false)}
            projectKey={projectKey}
            sprintName={sprintName}
          />
        </>
      )}
    </>
  )
}

export default SprintActions
