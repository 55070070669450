import { gql } from "apollo-boost";
import { Query, QueryResult } from "react-apollo";
import { AverageVelocity } from "./types/AverageVelocity";

export default gql`
    query AverageVelocity($sprints: [String!]!) {
        averageVelocity(sprints: $sprints)
    } 
`

export interface IAverageVelocityResult extends QueryResult<AverageVelocity> {}
export class AverageVelocityQuery extends Query<Number> {}