import { gql } from "apollo-boost"
import { Query } from "react-apollo"
import { Projects } from "./types/Projects"

export default gql`
  query Projects {
    projects {
      key
      name
      productOwner {
        name
      }
      dashboardColor
      dashboardName
    }
  }
`

export class ProjectsQuery extends Query<Projects> {
}
