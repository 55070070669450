import { match } from "fuzzyjs"
import _ from "lodash"
import { DateTime } from "luxon"
import { useMemo } from "react"
import { parseSprintShort } from "../../../../../utils/parse-sprint-short"
import { useSprints } from "../context/SprintsContext"

interface SprintFilterOptions {
  backlog: boolean
  futureSprints: boolean
  nonStandardSprints: boolean
  completedSprints: boolean
}

export const useSprintFilter = ({
  backlog = true,
  futureSprints = true,
  nonStandardSprints = true,
  completedSprints = true,
}: SprintFilterOptions) => {
  const { sprints, showBacklog, search } = useSprints()

  return useMemo(() => {
    let fuzzy = sprints
      .map(sprint => {
        const searchString = `${sprint.name}`
        const result = match(search, searchString)
        return { ...sprint, ...result, isBacklog: false }
      })
      .filter(sprint => sprint.match)

    if (!completedSprints) {
      fuzzy = fuzzy.filter(sprint => {
        if (sprint.end === null) return sprint
        return Math.round(DateTime.fromISO(sprint.end).diffNow("days").days) >= 0
      })
    }
    if (!futureSprints) {
      fuzzy = fuzzy.filter(sprint => {
        if (sprint.start === null) return sprint
        return Math.round(DateTime.fromISO(sprint.start).diffNow("days").days) <= 0
      })
    }
    if (!nonStandardSprints) {
      fuzzy = fuzzy.filter(sprint => {
        return parseSprintShort(sprint.name) !== ""
      })
    }

    const backlogItem = []
    if (backlog && showBacklog) {
      backlogItem.push({
        id: "-1",
        match: false,
        name: "Backlog",
        isBacklog: true,
        score: Number.MIN_SAFE_INTEGER,
      })
    }

    return [..._.sortBy(fuzzy, ["score"]).reverse(), ...backlogItem]
  }, [sprints, search, completedSprints, futureSprints, nonStandardSprints, backlog, showBacklog])
}
