import React from "react"
import { gql } from "apollo-boost"
import { Query } from "react-apollo"
import { Issue, Issue_issue, Issue_issue_comments } from "./types/Issue"
import Tooltip from "@atlaskit/tooltip"
import Question24Icon from "@atlaskit/icon-object/glyph/question/24"
import Story24Icon from "@atlaskit/icon-object/glyph/story/24"
import Bug24Icon from "@atlaskit/icon-object/glyph/bug/24"
import Task24Icon from "@atlaskit/icon-object/glyph/task/24"
import Epic24Icon from "@atlaskit/icon-object/glyph/epic/24"
import Sketch24Icon from "@atlaskit/icon-file-type/glyph/sketch/24"
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle"
import CheckIcon from "@atlaskit/icon/glyph/check"
import AddItemIcon from "@atlaskit/icon/glyph/add-item"
import AppAccessIcon from "@atlaskit/icon/glyph/app-access"
import BacklogIcon from "@atlaskit/icon/glyph/backlog"
import CodeIcon from "@atlaskit/icon/glyph/code"
import BitbucketPullrequestsIcon from "@atlaskit/icon/glyph/bitbucket/pullrequests"
import JiraLabsIcon from "@atlaskit/icon/glyph/jira/labs"
import LikeIcon from "@atlaskit/icon/glyph/like"
import EmojiFlagsIcon from "@atlaskit/icon/glyph/emoji/flags"

export const ISSUE_QUERY = gql`
  query Issue($key: String!) {
    issue(key: $key) {
      id
      type {
        name
      }
      summary
      status {
        name
      }
      priority {
        name
      }
      estimation
      description
      labels
      assignee {
        name
      }
      comments {
        id
        body
        author
        avatar
        date
      }
      attachments {
        id
        filename
        size
        date
      }
    }
  }
`;

export type IssueComment = Issue_issue_comments

export class IssueQuery extends Query<Issue> {
}

export const PHASES = {
  UNKNOWN: {
    LEVEL: 0,
    TITEL: "Unbekannt"
  },
  REQUESTED: {
    LEVEL: 1,
    TITEL: "Beauftragung"
  },
  PLANNING: {
    LEVEL: 2,
    TITEL: "Planung"
  },
  DEVELOPMENT: {
    LEVEL: 3,
    TITEL: "Umsetzung"
  },
  SHIPPING: {
    LEVEL: 4,
    TITEL: "Lieferung"
  },
  BLOCKED: {
    LEVEL: 5,
    TITEL: "Blockiert"
  },
}

export const TYPE = {
  UNKNOWN: {
    ICON: <Tooltip content="Unbekannt" position="right"><Question24Icon label="Unbekannt" /></Tooltip>,
    COLOR: "#FF8B00"
  },
  STORY: {
    ICON: <Tooltip content="Story" position="right"><Story24Icon label="Story" /></Tooltip>,
    COLOR: "#35B37E"
  },
  BUG: {
    ICON: <Tooltip content="Bug" position="right"><Bug24Icon label="Bug" /></Tooltip>,
    COLOR: "#FF8B00"
  },
  TASK: {
    ICON: <Tooltip content="Task" position="right"><Task24Icon label="Task" /></Tooltip>,
    COLOR: "#FF8B00"
  },
  EPIC: {
    ICON: <Tooltip content="Epic" position="right"><Epic24Icon label="Epic" /></Tooltip>,
    COLOR: "#FF8B00"
  },
  DESIGN_DELIVERABLE: {
    ICON: <Tooltip content="Design Deliverable" position="right"><Sketch24Icon label="Design Deliverable" /></Tooltip>,
    COLOR: "#FF8B00"
  },
}

export const getTypeOfIssue = (issue: Issue_issue): { ICON: React.ReactNode, COLOR: string } => {
  const compare = `${issue.type}`.toUpperCase().trim()
  switch (compare) {
    case "STORY":
      return TYPE.STORY
    case "BUG":
      return TYPE.BUG
    case "TASK":
      return TYPE.TASK
    case "EPIC":
      return TYPE.EPIC
    case "DESIGN DELIVERABLE":
      return TYPE.DESIGN_DELIVERABLE
  }

  return TYPE.UNKNOWN
}

export const getPhaseOfStatus = (status: string) => {
  let phase = 0
  const compare = `${status}`.toUpperCase().trim()

  switch (compare) {
    case "REQUESTED":
      phase = PHASES.REQUESTED.LEVEL
      break
    // --- --- --- --- ---

    case "APPROVED":
    case "TO DO":
    case "AUFGABEN":
      phase = PHASES.PLANNING.LEVEL
      break
    // --- --- --- --- ---

    case "IN PROGRESS":
    case "IN ARBEIT":
    case "WAITING FOR MERGE":
    case "VERIFY":
      phase = PHASES.DEVELOPMENT.LEVEL
      break
    // --- --- --- --- ---

    case "IN REVIEW":
    case "FERTIG":
    case "DONE":
      phase = PHASES.SHIPPING.LEVEL
      break
    // --- --- --- --- ---

    case "BLOCKED":
      phase = PHASES.BLOCKED.LEVEL
      break
  }

  return phase
}

export const mapStatusToColor = (status: string) => {
  let color = "rgb(223,225,230)"
  const compare = `${status}`.toUpperCase().trim()
  switch (compare) {
    case "REQUESTED":
      color = "rgb(223, 225, 230)"
      break
    // --- --- --- --- ---

    case "APPROVED":
    case "TO DO":
    case "AUFGABEN":
      color = "rgb(222, 235, 255)"
      break
    // --- --- --- --- ---

    case "IN PROGRESS":
    case "IN ARBEIT":
    case "WAITING FOR MERGE":
    case "VERIFY":
      color = "rgb(0, 82, 204)"
      break
    // --- --- --- --- ---

    case "IN REVIEW":
    case "FERTIG":
    case "DONE":
      color = "rgb(0, 135, 90)"
      break
    // --- --- --- --- ---

    case "BLOCKED":
      color = "rgb(255, 139, 0)"
      break
  }
  return color
}

export const mapStatusToText = (status: string) => {
  let text = "unknown"
  const compare = `${status}`.toUpperCase().trim()
  switch (compare) {
    case "REQUESTED":
      text = "requested"
      break
    // --- --- --- --- ---

    case "APPROVED":
      text = "approved"
      break
    case "TO DO":
    case "AUFGABEN":
      text = "to_do"
      break
    // --- --- --- --- ---

    case "IN PROGRESS":
    case "IN ARBEIT":
      text = "in_progress"
      break
    case "WAITING FOR MERGE":
      text = "waiting_for_merge"
      break
    case "VERIFY":
      text = "verify"
      break
    // --- --- --- --- ---

    case "IN REVIEW":
      text = 'in_review'
      break
    case "FERTIG":
    case "DONE":
      text = "done"
      break
    // --- --- --- --- ---

    case "BLOCKED":
      text = "blocked"
      break
  }
  return text
}

export const mapStatusToIcon = (status: string) => {
  let Icon = QuestionCircleIcon
  const compare = `${status}`.toUpperCase().trim()
  switch (compare) {
    case "REQUESTED":
      Icon = AddItemIcon
      break
    // --- --- --- --- ---

    case "APPROVED":
      Icon = AppAccessIcon
      break
    case "TO DO":
    case "AUFGABEN":
      Icon = BacklogIcon
      break
    // --- --- --- --- ---

    case "IN PROGRESS":
    case "IN ARBEIT":
      Icon = CodeIcon
      break
    case "WAITING FOR MERGE":
      Icon = BitbucketPullrequestsIcon
      break
    case "VERIFY":
      Icon = JiraLabsIcon
      break
    // --- --- --- --- ---

    case "IN REVIEW":
      Icon = LikeIcon
      break
    case "FERTIG":
    case "DONE":
      Icon = CheckIcon
      break
    // --- --- --- --- ---

    case "BLOCKED":
      Icon = EmojiFlagsIcon
      break
  }
  return <Icon label={status} size="small" />
}
