import { gql } from "apollo-boost"
import { Mutation } from "react-apollo"
import { SprintReview, SprintReviewVariables } from "./types/SprintReview"

export default gql`
mutation SprintReview($projectKey: String!, $sprintName: String!, $lang: LanguageCode!) {
  sprintReview(projectKey: $projectKey, sprintName: $sprintName, lang: $lang) {
    sprint {
      number
    }
    base64data
    contentType
    filename
  }
}
`

export class SprintReviewMutation extends Mutation<SprintReview, SprintReviewVariables> {}
