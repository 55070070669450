import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AbsenceVacation = {
  __typename?: 'AbsenceVacation';
  department: Scalars['String'];
  name: Scalars['String'];
  sprint: Scalars['String'];
  trainingDays: Scalars['Float'];
  vacations: Scalars['Float'];
};

export type Attachment = {
  __typename?: 'Attachment';
  date: Scalars['DateTime'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  size: Scalars['Float'];
};

export type BurnedStoryPoints = {
  __typename?: 'BurnedStoryPoints';
  sprints: Array<BurnedStoryPointsPerSprint>;
  total: Scalars['Float'];
};

export type BurnedStoryPointsPerProject = {
  __typename?: 'BurnedStoryPointsPerProject';
  avatarUrl: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  projectTotal: Scalars['Float'];
};

export type BurnedStoryPointsPerSprint = {
  __typename?: 'BurnedStoryPointsPerSprint';
  name: Scalars['String'];
  projects: Array<BurnedStoryPointsPerProject>;
  sprintTotal: Scalars['Float'];
};

export type Comment = {
  __typename?: 'Comment';
  author: Scalars['String'];
  avatar: Scalars['String'];
  body: Scalars['String'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  self: Scalars['String'];
};

export enum Country {
  AUSTRIA = 'AUSTRIA',
  HELLAS = 'HELLAS'
}

export type CreateCustomerMetadata = {
  city: Scalars['String'];
  companyName: Scalars['String'];
  companyType: Scalars['String'];
  country: Scalars['String'];
  dashboardColor: Scalars['String'];
  dashboardName: Scalars['String'];
  firstname: Scalars['String'];
  hasSupportContract: Scalars['Boolean'];
  jiraProjectKey: Scalars['String'];
  lastname: Scalars['String'];
  postalCode: Scalars['String'];
  projectName: Scalars['String'];
  salutation: Scalars['String'];
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  vatId: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  city: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  firstName: Scalars['String'];
  house: Scalars['String'];
  lastName: Scalars['String'];
  postal: Scalars['String'];
  salutation: Scalars['String'];
  street: Scalars['String'];
  uid: Scalars['String'];
};

export type CustomerMetadata = {
  __typename?: 'CustomerMetadata';
  city: Scalars['String'];
  companyName: Scalars['String'];
  companyType: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dashboardColor: Scalars['String'];
  dashboardName: Scalars['String'];
  firstname: Scalars['String'];
  hasSupportContract?: Maybe<Scalars['Boolean']>;
  jiraProjectKey: Scalars['String'];
  lastname: Scalars['String'];
  postalCode: Scalars['String'];
  projectName: Scalars['String'];
  salutation: Scalars['String'];
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vatId: Scalars['String'];
};

export type CustomerMetadataObject = {
  __typename?: 'CustomerMetadataObject';
  _id: Scalars['ID'];
  city: Scalars['String'];
  companyName: Scalars['String'];
  companyType: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dashboardColor: Scalars['String'];
  dashboardName: Scalars['String'];
  firstname: Scalars['String'];
  hasSupportContract?: Maybe<Scalars['Boolean']>;
  jiraProjectKey: Scalars['String'];
  lastname: Scalars['String'];
  postalCode: Scalars['String'];
  projectName: Scalars['String'];
  salutation: Scalars['String'];
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vatId: Scalars['String'];
};

export enum DepartmentEnum {
  BACKOFFICE = 'BACKOFFICE',
  DESIGN = 'DESIGN',
  DEVELOPMENT = 'DEVELOPMENT',
  DEVOPS = 'DEVOPS',
  MARKETING_BUSINESS = 'MARKETING_BUSINESS',
  MARKETING_BUSINESS_2 = 'MARKETING_BUSINESS_2',
  PRODUCT_OWNERS = 'PRODUCT_OWNERS'
}

export type Description = {
  __typename?: 'Description';
  ac: Array<DescriptionLine>;
  sections: Array<DescriptionSection>;
  story: Scalars['String'];
};

export type DescriptionLine = {
  __typename?: 'DescriptionLine';
  indent: Scalars['Float'];
  text: Scalars['String'];
};

export type DescriptionSection = {
  __typename?: 'DescriptionSection';
  lines: Array<DescriptionLine>;
  title: Scalars['String'];
};

export type Erp = {
  __typename?: 'ERP';
  absent?: Maybe<Array<HolidayStats>>;
  completed?: Maybe<Scalars['Int']>;
  end: Scalars['DateTime'];
  planned: Scalars['Int'];
  projects: Array<ErpStats>;
  sprint: Scalars['String'];
  start: Scalars['DateTime'];
};

export type ErpStats = {
  __typename?: 'ErpStats';
  bugs: Scalars['Int'];
  completed?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  planned: Scalars['Int'];
  stories: Scalars['Int'];
  tasks: Scalars['Int'];
};

export type File = {
  __typename?: 'File';
  base64: Scalars['String'];
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  size: Scalars['Float'];
};

export type HolidayStats = {
  __typename?: 'HolidayStats';
  days: Scalars['Float'];
  name: Scalars['String'];
};

export type Issue = {
  __typename?: 'Issue';
  SP: Scalars['String'];
  SPEstimate: Scalars['String'];
  assignee: Person;
  attachments: Array<Attachment>;
  comments: Array<Comment>;
  decomposed: Description;
  /** If it is false, then hide it */
  denovo_care_visibility?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  estimation?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  key: Scalars['String'];
  labels: Array<Scalars['String']>;
  priority: IssuePriority;
  sprint?: Maybe<Sprint>;
  status: IssueStatus;
  summary: Scalars['String'];
  type: IssueType;
};

export type IssuePriority = {
  __typename?: 'IssuePriority';
  iconUrl: Scalars['String'];
  name: Scalars['String'];
};

export type IssueStatus = {
  __typename?: 'IssueStatus';
  category: Scalars['String'];
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IssueType = {
  __typename?: 'IssueType';
  iconUrl: Scalars['String'];
  name: Scalars['String'];
};

export enum JiraPriorityType {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export type JiraProjectWithCustomerMetadata = {
  __typename?: 'JiraProjectWithCustomerMetadata';
  customerMetadata?: Maybe<CustomerMetadataObject>;
  key: Scalars['String'];
  name: Scalars['String'];
};

export enum LanguageCode {
  DE = 'DE',
  EN = 'EN'
}

export type Mutation = {
  __typename?: 'Mutation';
  addComment: Comment;
  authenticate: Session;
  createJiraSupportTicket: Scalars['Boolean'];
  createOrEditCustomerMetadata: CustomerMetadataObject;
  offer: Offer;
  recommission: Recommission;
  roadmap: Roadmap;
  sprintReview: SprintReview;
  storyCards: StoryCards;
  userStory: UserStory;
};


export type MutationAddCommentArgs = {
  comment: Scalars['String'];
  key: Scalars['String'];
};


export type MutationAuthenticateArgs = {
  domain: Scalars['String'];
  password: Scalars['String'];
  provider?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};


export type MutationCreateJiraSupportTicketArgs = {
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  description: Scalars['String'];
  parentIssueKey?: InputMaybe<Scalars['String']>;
  priority: JiraPriorityType;
  projectKey: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateOrEditCustomerMetadataArgs = {
  createCustomerMetadata: CreateCustomerMetadata;
};


export type MutationOfferArgs = {
  lang?: InputMaybe<LanguageCode>;
  nonFunctionalRequirements?: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
  projectKey: Scalars['String'];
  spEur: Scalars['Float'];
  sprintName: Scalars['String'];
  version: Scalars['String'];
};


export type MutationRecommissionArgs = {
  number: Scalars['String'];
  projectKey: Scalars['String'];
  spEur: Scalars['Float'];
  storyKeys: Array<Scalars['String']>;
  version: Scalars['String'];
};


export type MutationRoadmapArgs = {
  backlogSprints?: InputMaybe<Array<Scalars['String']>>;
  from: Scalars['String'];
  projectKey: Scalars['String'];
  reviewSprints?: InputMaybe<Array<Scalars['String']>>;
  to: Scalars['String'];
};


export type MutationSprintReviewArgs = {
  lang?: InputMaybe<LanguageCode>;
  projectKey: Scalars['String'];
  sprintName: Scalars['String'];
};


export type MutationStoryCardsArgs = {
  issueKeys: Array<Scalars['String']>;
};


export type MutationUserStoryArgs = {
  projectKey: Scalars['String'];
  sprintName: Scalars['String'];
  version: Scalars['String'];
};

export type Offer = {
  __typename?: 'Offer';
  base64data: Scalars['String'];
  contentType: Scalars['String'];
  filename: Scalars['String'];
  project: Project;
  sprint: Sprint;
};

export type Person = {
  __typename?: 'Person';
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type Project = {
  __typename?: 'Project';
  customer?: Maybe<Customer>;
  customerMetadata?: Maybe<CustomerMetadata>;
  dashboardColor?: Maybe<Scalars['String']>;
  dashboardName?: Maybe<Scalars['String']>;
  hasSupportTicket: Scalars['Boolean'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  productOwner?: Maybe<Person>;
  showBacklog: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  averageVelocity: Scalars['Float'];
  burnedStoryPoints: BurnedStoryPoints;
  downloadAttachment: File;
  erp: Array<Erp>;
  getAllCustomerMetadata: Array<CustomerMetadataObject>;
  getJiraProjectsWithMetadata: Array<JiraProjectWithCustomerMetadata>;
  getVacationForecast: Array<AbsenceVacation>;
  issue: Issue;
  issues: Array<Issue>;
  project: Project;
  projects: Array<Project>;
  sprintPreview: Array<SprintPreview>;
  sprints: Array<Sprint>;
  version: Version;
};


export type QueryAverageVelocityArgs = {
  sprints?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBurnedStoryPointsArgs = {
  amountOfSprints: Scalars['Float'];
};


export type QueryDownloadAttachmentArgs = {
  attachmentId: Scalars['String'];
};


export type QueryErpArgs = {
  sprints?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetVacationForecastArgs = {
  countries: Array<Country>;
  departments: Array<DepartmentEnum>;
  sprints?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryIssueArgs = {
  key: Scalars['String'];
};


export type QueryIssuesArgs = {
  isBacklog?: InputMaybe<Scalars['Boolean']>;
  projectKey: Scalars['String'];
  sprintName?: InputMaybe<Scalars['String']>;
};


export type QueryProjectArgs = {
  key: Scalars['String'];
};


export type QuerySprintPreviewArgs = {
  amountOfSprints: Scalars['Float'];
};


export type QuerySprintsArgs = {
  projectKey: Scalars['String'];
};

export type Recommission = {
  __typename?: 'Recommission';
  base64data: Scalars['String'];
  contentType: Scalars['String'];
  filename: Scalars['String'];
  project: Project;
};

export type Roadmap = {
  __typename?: 'Roadmap';
  base64data: Scalars['String'];
  contentType: Scalars['String'];
  filename: Scalars['String'];
};

export type Session = {
  __typename?: 'Session';
  token: Scalars['String'];
};

export type Sprint = {
  __typename?: 'Sprint';
  completeDate?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  goal?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  issues: Array<Issue>;
  name: Scalars['String'];
  number: Scalars['String'];
  projectKey: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
};

export type SprintPreview = {
  __typename?: 'SprintPreview';
  name: Scalars['String'];
  projects: Array<SprintPreviewProject>;
};

export type SprintPreviewAmountsOfIssues = {
  __typename?: 'SprintPreviewAmountsOfIssues';
  bugs: Scalars['Float'];
  stories: Scalars['Float'];
  tasks: Scalars['Float'];
};

export type SprintPreviewProject = {
  __typename?: 'SprintPreviewProject';
  avatarUrl: Scalars['String'];
  id: Scalars['String'];
  issueAmounts: SprintPreviewAmountsOfIssues;
  key: Scalars['String'];
  name: Scalars['String'];
  storyPoints: Scalars['Float'];
};

export type SprintReview = {
  __typename?: 'SprintReview';
  base64data: Scalars['String'];
  contentType: Scalars['String'];
  filename: Scalars['String'];
  project: Project;
  sprint: Sprint;
};

export type StoryCards = {
  __typename?: 'StoryCards';
  base64data: Scalars['String'];
  contentType: Scalars['String'];
  filename: Scalars['String'];
};

export type UserStory = {
  __typename?: 'UserStory';
  base64data: Scalars['String'];
  contentType: Scalars['String'];
  filename: Scalars['String'];
  project: Project;
  sprint: Sprint;
};

export type Version = {
  __typename?: 'Version';
  ahead: Scalars['Int'];
  branch: Scalars['String'];
  commit: Scalars['String'];
  env: Scalars['String'];
  tag: Scalars['String'];
};

export type SigninMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SigninMutation = { __typename?: 'Mutation', authenticate: { __typename?: 'Session', token: string } };

export type CreateJiraSupportTicketMutationVariables = Exact<{
  projectKey: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  priority: JiraPriorityType;
  parentIssueKey?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CreateJiraSupportTicketMutation = { __typename?: 'Mutation', createJiraSupportTicket: boolean };

export type ErpQueryVariables = Exact<{
  sprints: Array<Scalars['String']> | Scalars['String'];
}>;


export type ErpQuery = { __typename?: 'Query', erp: Array<{ __typename?: 'ERP', sprint: string, start: any, end: any, projects: Array<{ __typename?: 'ErpStats', key: string, planned: number }>, absent?: Array<{ __typename?: 'HolidayStats', name: string, days: number }> | null }>, projects: Array<{ __typename?: 'Project', name?: string | null, key: string, dashboardName?: string | null, dashboardColor?: string | null }> };

export type GetSprintsQueryVariables = Exact<{
  projectKey: Scalars['String'];
}>;


export type GetSprintsQuery = { __typename?: 'Query', project: { __typename?: 'Project', key: string, name?: string | null, showBacklog: boolean, hasSupportTicket: boolean, productOwner?: { __typename?: 'Person', name?: string | null } | null, customerMetadata?: { __typename?: 'CustomerMetadata', hasSupportContract?: boolean | null } | null }, sprints: Array<{ __typename?: 'Sprint', id: string, name: string, start?: any | null, end?: any | null, goal?: string | null }> };

export type VersionQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionQuery = { __typename?: 'Query', version: { __typename?: 'Version', tag: string, commit: string, branch: string, ahead: number, env: string } };


export const SigninDocument = gql`
    mutation Signin($email: String!, $password: String!) {
  authenticate(
    username: $email
    password: $password
    domain: "denovo-gmbh.atlassian.net"
  ) {
    token
  }
}
    `;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const CreateJiraSupportTicketDocument = gql`
    mutation CreateJiraSupportTicket($projectKey: String!, $title: String!, $description: String!, $priority: JiraPriorityType!, $parentIssueKey: String, $attachments: [Upload!]) {
  createJiraSupportTicket(
    projectKey: $projectKey
    title: $title
    description: $description
    priority: $priority
    parentIssueKey: $parentIssueKey
    attachments: $attachments
  )
}
    `;
export type CreateJiraSupportTicketMutationFn = Apollo.MutationFunction<CreateJiraSupportTicketMutation, CreateJiraSupportTicketMutationVariables>;

/**
 * __useCreateJiraSupportTicketMutation__
 *
 * To run a mutation, you first call `useCreateJiraSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJiraSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJiraSupportTicketMutation, { data, loading, error }] = useCreateJiraSupportTicketMutation({
 *   variables: {
 *      projectKey: // value for 'projectKey'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      parentIssueKey: // value for 'parentIssueKey'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreateJiraSupportTicketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJiraSupportTicketMutation, CreateJiraSupportTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateJiraSupportTicketMutation, CreateJiraSupportTicketMutationVariables>(CreateJiraSupportTicketDocument, options);
      }
export type CreateJiraSupportTicketMutationHookResult = ReturnType<typeof useCreateJiraSupportTicketMutation>;
export type CreateJiraSupportTicketMutationResult = Apollo.MutationResult<CreateJiraSupportTicketMutation>;
export type CreateJiraSupportTicketMutationOptions = Apollo.BaseMutationOptions<CreateJiraSupportTicketMutation, CreateJiraSupportTicketMutationVariables>;
export const ErpDocument = gql`
    query ERP($sprints: [String!]!) {
  erp(sprints: $sprints) {
    sprint
    start
    end
    projects {
      key
      planned
    }
    absent {
      name
      days
    }
  }
  projects {
    name
    key
    dashboardName
    dashboardColor
  }
}
    `;

/**
 * __useErpQuery__
 *
 * To run a query within a React component, call `useErpQuery` and pass it any options that fit your needs.
 * When your component renders, `useErpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useErpQuery({
 *   variables: {
 *      sprints: // value for 'sprints'
 *   },
 * });
 */
export function useErpQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ErpQuery, ErpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ErpQuery, ErpQueryVariables>(ErpDocument, options);
      }
export function useErpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ErpQuery, ErpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ErpQuery, ErpQueryVariables>(ErpDocument, options);
        }
export type ErpQueryHookResult = ReturnType<typeof useErpQuery>;
export type ErpLazyQueryHookResult = ReturnType<typeof useErpLazyQuery>;
export type ErpQueryResult = Apollo.QueryResult<ErpQuery, ErpQueryVariables>;
export const GetSprintsDocument = gql`
    query GetSprints($projectKey: String!) {
  project(key: $projectKey) {
    key
    name
    showBacklog
    productOwner {
      name
    }
    customerMetadata {
      hasSupportContract
    }
    hasSupportTicket
  }
  sprints(projectKey: $projectKey) {
    id
    name
    start
    end
    goal
  }
}
    `;

/**
 * __useGetSprintsQuery__
 *
 * To run a query within a React component, call `useGetSprintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSprintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSprintsQuery({
 *   variables: {
 *      projectKey: // value for 'projectKey'
 *   },
 * });
 */
export function useGetSprintsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSprintsQuery, GetSprintsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSprintsQuery, GetSprintsQueryVariables>(GetSprintsDocument, options);
      }
export function useGetSprintsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSprintsQuery, GetSprintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSprintsQuery, GetSprintsQueryVariables>(GetSprintsDocument, options);
        }
export type GetSprintsQueryHookResult = ReturnType<typeof useGetSprintsQuery>;
export type GetSprintsLazyQueryHookResult = ReturnType<typeof useGetSprintsLazyQuery>;
export type GetSprintsQueryResult = Apollo.QueryResult<GetSprintsQuery, GetSprintsQueryVariables>;
export const VersionDocument = gql`
    query Version {
  version {
    tag
    commit
    branch
    ahead
    env
  }
}
    `;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
      }
export function useVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
        }
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionQueryResult = Apollo.QueryResult<VersionQuery, VersionQueryVariables>;