import { createTheme } from "@material-ui/core";

export const PRIMARY_COLOR = "#344563";
export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
  },
  typography: {
    body2: {
      fontSize: 12
    }
  },
  overrides: {
    MuiInputLabel: {
      outlined: {
        // For future reference: For some reason TextField label was not working properly
        //  & this is a quick fix
        transform: "translate(14px, 12px) scale(1)",
      }
    }
  }
});


