import * as React from "react"
import __ from "lodash"
import { match } from "fuzzyjs"
import store from "store"
import KeyHandler, { KEYPRESS, KEYUP } from "react-key-handler"
import { Query, QueryResult } from "react-apollo"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Spinner from "@atlaskit/spinner"
import Dropdown, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu"
import { IBaseProps } from "../../../utils/BaseProps"
import PROJECTS_QUERY from "../../../api/graphql/query/projects"
import { AuthConsumer, ABILITIES } from "../../../context/AuthContext"
import { Projects_projects } from "../../../api/graphql/query/types/Projects"
import { HelperText } from "../../styled/HelperText";

interface IProjectsProps extends IBaseProps {
  history: any
}

interface IProjectsState {
  search: string
}

const ProjectListing = styled.div`
`

const ProjectCard = styled.div`
  background-color: #f4f5f7;
  margin-bottom: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;

  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
`

const ProjectHeading = styled.div`
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
`

const ProjectActions = styled.div`
  display: flex;
  padding-right: 30px;
  flex-direction: column;
  justify-content: space-evenly;
`

const ProjectKey = styled.div`
  font-weight: bold;
`

const ProjectName = styled.div`
  font-size: 1.6em;
`

const OwnerName = styled.div`
  opacity: 0.6;
`

class Projects extends React.Component<IProjectsProps, IProjectsState> {

  private searchInput: HTMLInputElement | null

  constructor(props: IProjectsProps) {
    super(props)
    this.searchInput = null
    this.state = {
      search: store.get("project_search") || ""
    }
  }

  setSearch = (search: string = "") => {
    store.set("project_search", search)
    this.setState({ search })
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setSearch(event.target.value || "")
  }

  handleSearchKey = (project: Projects_projects | undefined) => (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && project) {
      if (event.altKey) {
        this.props.history.push(`/projects/${project.key}/roadmap`)
      }
      else {
        this.props.history.push(`/projects/${project.key}/sprints`)
      }
    }

    console.log(event.key)
    if (event.key === "Escape") {
      this.setSearch()
    }
  }

  searchProjects = (projects: Array<Projects_projects>) => {
    const fuzzy = (projects || [])
      .map(project => {
        const result = match(this.state.search, `${project.key} ${project.name || ""}`)
        return { ...project, ...result }
      })
      .filter(project => project.match)

    return __.sortBy(fuzzy, ["score"]).reverse()
  }

  componentDidMount = () => {
    this.searchInput && this.searchInput.focus()
  }

  componentDidUpdate = () => {
    console.log("componentDidUpdate", this.searchInput)
    this.searchInput && this.searchInput.focus()
  }

  public render() {
    const { t } = this.props
    return (
      <AuthConsumer>
      {({ hasAbility, getRestUri }) => (
        <Query query={PROJECTS_QUERY} fetchPolicy="cache-first">
        {({ data, loading, error, refetch }: QueryResult) => {
          const searchProjects = this.searchProjects(data ? data.projects : [])
          return (
            <React.Fragment>
              <h1>{t("page.projects.header.title")}</h1>
              {hasAbility(ABILITIES.PRODUCT_OWNER) && <div className="search-input">
                <input
                  value={this.state.search}
                  onChange={this.handleSearchChange}
                  placeholder={t("page.projects.search")}
                  onKeyUp={this.handleSearchKey(searchProjects && searchProjects.length > 0 ? searchProjects[0] : undefined)}
                  ref={c => (this.searchInput = c)}
                  style={{
                    width: "calc(100% - 30px)",
                    padding: "10px 15px",
                    backgroundColor: "#253858",
                    border: "none",
                    borderRadius: "6px",
                    marginBottom: "15px",
                    color: "#fff",
                    fontSize: "1.5em",
                    fontWeight: "lighter",
                  }}
                />
                <span>{t("page.projects.filter")}</span>
                <KeyHandler
                  keyEventName={KEYPRESS}
                  keyValue="f"
                  onKeyHandle={(event: KeyboardEvent) => {
                    event.stopPropagation()
                    event.preventDefault()
                    this.searchInput && this.searchInput.focus()
                  }}
                />
                <KeyHandler
                  keyEventName={KEYUP}
                  code="Escape"
                  onKeyHandle={() => {
                    this.setSearch()
                  }}
                />
                <HelperText className="helper-text">
                  {t("page.projects.esc")}<br/>
                  <div className="only-active">{t("page.projects.enter")}</div>
                </HelperText>
              </div>}
              {loading &&
                <React.Fragment>
                  <Spinner /> <span style={{ marginLeft: "1em" }}>{t("page.projects.loading.message")}</span>
                </React.Fragment>
              }
              {!loading && data &&
                  <ProjectListing>
                    {searchProjects.map((project: any) => (
                      <ProjectCard key={project.key}>
                        <img
                          alt={project.name}
                          width="128" height="128"
                          src={getRestUri(`rest/projects/${project.key}/avatar`)}
                          onClick={() => this.props.history.push(`/projects/${project.key}/sprints`)}
                        />
                        <ProjectHeading onClick={() => this.props.history.push(`/projects/${project.key}/sprints`)}>
                          <ProjectKey>{project.key}</ProjectKey>
                          <ProjectName>{project.name}</ProjectName>
                          <OwnerName>{__.get(project, "productOwner.name")}</OwnerName>
                        </ProjectHeading>
                        {hasAbility(ABILITIES.PRODUCT_OWNER) && (
                          <ProjectActions>
                            <Dropdown triggerType="button" trigger="···">
                              {hasAbility(ABILITIES.PRODUCT_OWNER) && (
                                <DropdownItemGroup title="Product Owner">
                                  <DropdownItem onClick={() => this.props.history.push(`/projects/${project.key}/roadmap`)}>Roadmap</DropdownItem>
                                </DropdownItemGroup>
                              )}
                            </Dropdown>
                          </ProjectActions>
                        )}
                      </ProjectCard>
                    ))}
                  </ProjectListing>
              }
            </React.Fragment>
          )}}
        </Query>
      )}
      </AuthConsumer>
    )
  }
}

export default withTranslation()(Projects)
