import * as React from "react"
// import { Query, QueryResult } from "react-apollo"
import { IBaseProps } from "../../../utils/BaseProps"
import { withTranslation } from "react-i18next"
// import Spinner from "@atlaskit/spinner"
// import KPI_QUERY from "../../../api/graphql/query/kpi"
import '../../../index'
// import styled from "styled-components"
// import { ResponsiveBar, BarDatum } from "@nivo/bar"
// import { parseJson } from '../../../utils/parse'
// import Chart from './components/Chart/chart'
// import sprints from '../../../utils/next-sprints';
// import { currentSprint, addSprints } from "../../../utils/sprint-math"
import { AuthConsumer } from "../../../context/AuthContext"
// import RefreshIcon from "@atlaskit/icon/glyph/refresh"
// import Button from "@atlaskit/button"

// import Levels from "react-activity/lib/Levels"
import "react-activity/lib/Levels/Levels.css"

// const Header = styled.div`
//   margin-top: 20px;
//   margin-bottom: 40px;
// `

// const Title = styled.div`
//   color: rgb(52, 69, 99);
//   font-size: 28px;
//   font-style: normal;
//   font-weight: 700;
// `

// const Subtitle = styled.div`
//   color: #8C96A5;
//   font-weight: normal;
//   font-size: 14px;
// `

interface IERPProps extends IBaseProps {

}

interface IERPSate {

}


// const Listing = styled.div``
// const Project = styled.div``
// const Sprint = styled.div``

class ERP extends React.Component<IERPProps, IERPSate> {
  constructor(props: IERPProps) {
    super(props);
    this.state = {}
  }

  public render() {
    // const { t } = this.props
    // const keysArray: String[] = []
    // const sprints = Array
    //   .from(Array(12).keys())
    //   .map((sprintIndex): string => addSprints(currentSprint(), sprintIndex * -1))
    // const variables = { sprints }

    return (
      <AuthConsumer>
      {({ isCustomer }) => (
        <div>Not implemented yet</div>
        // <Query query={KPI_QUERY} variables={variables} fetchPolicy="network-only" notifyOnNetworkStatusChange={true} pollInterval={300000}>
        // {({ data, loading, error, refetch, networkStatus }: QueryResult) => {
        //   var averageVelocity: Array<number> = []
        //   const calculatedSprints = ((data && data.kpi) || [])
        //     .reverse()
        //     .map((sprint: any) => {
        //       if (averageVelocity.length >=3) {
        //         averageVelocity.shift()
        //       }

        //       if (averageVelocity.length < 3) {
        //         averageVelocity.push(sprint.burned)
        //         averageVelocity = averageVelocity.filter((burned: number) => Number.isInteger(burned))
        //       }

        //       sprint.velocity = averageVelocity.length === 3
        //         ? Math.round(averageVelocity.reduce((acc: number, burned: number) => acc + burned, 0) / averageVelocity.length)
        //         : null

        //       sprint.workload = sprint.burned / sprint.fted

        //       return sprint
        //     })

        //   console.log(calculatedSprints)
        //   return (
        //     <React.Fragment>
        //       <Header>
        //         <Title>{t("page.analysis.header.title")}</Title>
        //         <Subtitle>Sprints</Subtitle>
        //       </Header>
              
        //       <div style={{ height: "400px" }}>
        //         <ResponsiveBar
        //           data={calculatedSprints}
        //           indexBy="sprintKey"
        //           keys={["burned", "velocity"]}
        //           groupMode="grouped"
        //           animate={true}
        //           margin={{ top: 50, right: 130, bottom: 10, left: 60 }}
        //           padding={0.3}
        //           enableGridX={true}
        //           enableGridY={false}
        //           label={(d: BarDatum) => `${d.value} SP`}
        //           axisLeft={{
        //             tickSize: 5,
        //             tickPadding: 5,
        //             tickRotation: 0,
        //             legend: 'Story Points',
        //             legendPosition: 'middle',
        //             legendOffset: -40
        //           }}
        //           axisBottom={null}
        //           colors={['#61cdbb', '#97e3d5', '#f47560', '#e25c3b']}
        //           innerPadding={1}
        //         />
        //       </div>
        //       <div style={{ height: "200px" }}>
        //         <ResponsiveBar
        //           data={calculatedSprints}
        //           indexBy="sprintKey"
        //           keys={["workload"]}
        //           groupMode="grouped"
        //           animate={true}
        //           margin={{ top: 0, right: 130, bottom: 70, left: 60 }}
        //           padding={0.3}
        //           enableGridX={true}
        //           enableGridY={false}
        //           label={(d: BarDatum) => `${parseFloat(`${d.value}`).toFixed(2)}`}
        //           axisLeft={{
        //             tickSize: 5,
        //             tickPadding: 5,
        //             tickRotation: 0,
        //             legend: 'SP/FTEd',
        //             legendPosition: 'middle',
        //             legendOffset: -40
        //           }}
        //           axisBottom={{
        //             legend: 'Sprints',
        //             legendPosition: 'middle',
        //             legendOffset: 60,
        //             tickSize: 0,
        //             tickPadding: 12,
        //             tickRotation: 45,
        //           }}
        //           colors={['#61cdbb', '#97e3d5', '#f47560', '#e25c3b']}
        //           innerPadding={1}
        //         />
        //       </div>
        //     </React.Fragment>
        //   )
        // }}
        // </Query>
      )}
      </AuthConsumer>
    )
  }
}

export default withTranslation()(ERP)