import { FormControlLabel, FormControlLabelProps, Switch } from "@material-ui/core"
import { useFormikContext } from "formik"
import { Omit } from "lodash"
import React, { ChangeEvent, FC } from "react"

type IFormikSwitchProps = Omit<
  FormControlLabelProps & {
    valueName: string
    clearable?: boolean
    onChange?: (e: ChangeEvent<any>, checked: boolean) => void
    hide?: boolean
  },
  "control"
>

export const FormikSwitch: FC<IFormikSwitchProps> = ({
  valueName,
  clearable,
  onChange,
  hide,
  ...rest
}) => {
  const { getFieldProps, setFieldValue, handleBlur } = useFormikContext()
  const field = getFieldProps(valueName)
  const handleChangeProxy = (e: ChangeEvent<any>, checked: boolean) => {
    setFieldValue(valueName, checked)
    onChange && onChange(e, checked)
  }

  return (
    <>
      <FormControlLabel
        {...rest}
        control={
          <Switch
            color="primary"
            defaultChecked={field.value}
            value={field.value}
            onChange={handleChangeProxy}
            onBlur={handleBlur}
          />
        }
      />
    </>
  )
}
